import { Text16 } from "../../../components/textTitles";

interface TimeSlotProps {
  slot: string;
  icon: any;
  onClick: () => void;
}

const TimeSlot = ({ slot, icon, onClick }: TimeSlotProps) => {
  return (
    <div
      className="relative flex items-center justify-center w-full  py-3 rounded-3xl shadow-primary hover:shadow-md hover:cursor-pointer duration-300 transition-all"
      onClick={onClick}
    >
      <div className="absolute left-[16px]">{icon}</div>
      <Text16 title={slot} color="text-bluePrimary" fontWeight="font-semibold"/>
    </div>
  );
};

export default TimeSlot;
