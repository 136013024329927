import React, { useState, useEffect } from 'react';
import { MCQTypeQuestionBody } from '../../../data/models/assignmentModel';
import { Text12 } from '../../../../../components/textTitles';
import QuestionBody from './QuestionBody';

const Mcq = ({
      question,
      savedAnswer,
      onSaveAnswer,
}: {
      question: MCQTypeQuestionBody;
      savedAnswer: { selectedOption: number; isCorrect: boolean };
      onSaveAnswer: (answer: { selectedOption: number; isCorrect: boolean }) => void;
}) => {
      const [selectedOption, setSelectedOption] = useState<number | null>(null);
      const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
      const [showHint, setShowHint] = useState<boolean>(false);

      useEffect(() => {
            if (savedAnswer) {
                  setSelectedOption(savedAnswer.selectedOption);
                  setIsCorrect(savedAnswer.isCorrect);
            }
      }, [savedAnswer]);

      const handleOptionClick = (index: number) => {
            setSelectedOption(index);
            const correctAnswerIndex = parseInt(question.answer?.option_index[0] ?? '', 10) - 1;
            const correct = index === correctAnswerIndex;
            setIsCorrect(correct);
            onSaveAnswer({ selectedOption: index, isCorrect: correct });

            if (!correct) {
                  setShowHint(true);
            }
      };

      return (
            <div className='flex flex-col w-full gap-6'>
                  <QuestionBody question={question.question_body} />
                  <div className='flex flex-col gap-3 w-full items-center'>
                        {question.options?.map((option, index) => {
                              const isSelected = selectedOption === index;
                              const bgColor = isSelected ? (isCorrect ? 'bg-green-500' : 'bg-red-500') : 'bg-white';
                              return (
                                    <button
                                          key={index}
                                          className={`w-full p-[0.4rem] border-2 rounded-md shadow-md ${bgColor}`}
                                          onClick={() => handleOptionClick(index)}
                                    >
                                          <Text12
                                                title={option}
                                                fontWeight='font-medium'
                                          />
                                    </button>
                              );
                        })}
                  </div>
                  {showHint && question.hints && question.hints.length > 0 && (
                        <div className='mt-4 p-2 bg-yellow-100 border border-yellow-300 rounded-md'>
                              <Text12
                                    title={`Hint: ${question.hints[0]}`}
                                    fontWeight='font-medium'
                              />
                        </div>
                  )}
            </div>
      );
};

export default Mcq;
