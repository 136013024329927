import { useEffect, useReducer, useState } from 'react';
import { Header } from '../../../../components/Header';
import ReactCardFlip from 'react-card-flip';
import RevisionFlashFront from '../components/revisionFlashFront';
import RevisionFlashBack from '../components/revisionFlashBack/revisionFlashBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubBundleModel } from '../../../admin/bundle/data/models/subBundleModel';
import fetchRevisionFlash from '../../data/repository/fetchRevisionFlash';
import revisionReducer, { initialRevisionReducerState } from '../reducer/revisionReducer';
import { useAppSelector } from '../../../../core/store/store';
import { BeatLoader } from 'react-spinners';
import { FlashDetailsModel } from '../../../admin/bundle/data/models/flashDetailsModel';
import ConfirmationCard from '../../../admin/bundle/presentation/components/subBundle/subBundleFlashCards/confirmationCard';
import { SecondaryText } from '../../../../components/textTitles';

const Revise = () => {
      const location = useLocation();
      const navigate = useNavigate();
      const [cardCount, setCardCount] = useState(0);
      const [cardFlip, setCardFlip] = useState(false);
      const [card, setCard] = useState<FlashDetailsModel>();
      const subBundleData: SubBundleModel = location.state;
      const { data } = useAppSelector((state) => state.user);
      const [state, dispatch] = useReducer(revisionReducer, initialRevisionReducerState);
      const [showCompletionDialog, setShowCompletionDialog] = useState(false);

      useEffect(() => {
            if (subBundleData) {
                  fetchRevisionFlash(dispatch, subBundleData.id, data?.userId);
            } else {
                  navigate('/bundles');
            }
      }, []);

      useEffect(() => {
            if (state.revisionData) setCard(state.revisionData[0]);
      }, [state.revisionData]);

      return (
            <div className='w-full h-full flex flex-col gap-6'>
                  <Header
                        titleOne='Revision'
                        titleTwo=''
                  ></Header>
                  <section className='w-full h-full flex items-center justify-center shadow-primary rounded-3xl py-6'>
                        {state.loading && <BeatLoader />}
                        {state.error && <SecondaryText title='No cards to revise' />}
                        {card && (
                              <ReactCardFlip
                                    containerClassName='h-full'
                                    isFlipped={cardFlip}
                                    flipDirection='horizontal'
                              >
                                    <RevisionFlashFront
                                          setCardFlip={setCardFlip}
                                          cardData={card}
                                    ></RevisionFlashFront>
                                    <RevisionFlashBack
                                          setCardFlip={setCardFlip}
                                          cardData={card}
                                          cards={state.revisionData}
                                          setCard={setCard}
                                          cardCount={cardCount}
                                          setCardCount={setCardCount}
                                          setShowCompletionDialog={setShowCompletionDialog}
                                    />
                              </ReactCardFlip>
                        )}
                  </section>
                  {showCompletionDialog && (
                        <ConfirmationCard
                              text='Do you want to revise again?'
                              confirmText='Yes'
                              onNoClick={() => {
                                    setShowCompletionDialog(false);
                                    navigate(-1);
                              }}
                              onYesClick={() => {
                                    setShowCompletionDialog(false);
                                    setCardFlip(false);
                                    if (state.revisionData) setCard(state.revisionData[0]);
                                    setCardCount(0);
                              }}
                        />
                  )}
            </div>
      );
};

export default Revise;
