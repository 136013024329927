import { useMutation } from "@tanstack/react-query"
import { DAxios } from "../../../../core/api/axiosConfig"
import { endpoints } from "../../../../core/api/apisPath"
import { DToast } from "../../../../core/utils/toasts"

export const useCompleteUnit = () => {
    return useMutation({
        mutationFn: async ({ courseId, unitId }: { courseId: string, unitId: string }) => {
            const response = await DAxios.post({
                endpoint: endpoints.completeUnit,
                body: {
                    courseId: courseId,
                    unitId: unitId
                }

            })
            return response
        },
        onSuccess: () => {
            new DToast().showSuccessToast('You have successfully completed this unit')
        },
        onError: (error: Error) => {
            new DToast().showErrorToast(error.message)
        }

    })
}