import { useEffect, useState } from 'react';
import TertiaryButton from '../../../components/buttons/tertiaryButton';
import CustomInputField from '../../../components/customInputField';
import { useUpdatePassword } from '../data/service/updatePassword.service';
import { DToast } from '../../../core/utils/toasts';

const ResetPasswordModal = ({ onClose }: { onClose: () => void }) => {
      const [currentPassword, setCurrentPassword] = useState<string>();
      const [newPassword, setNewPassword] = useState<string>();
      const [confirmPassword, setConfirmPassword] = useState<string>();
      const { mutate: resetPassword, isPending, isSuccess } = useUpdatePassword();

      useEffect(() => {
            if (isSuccess) {
                  onClose();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isSuccess]);

      return (
            <div className='w-screen h-screen bg-black bg-opacity-50 absolute top-0 left-0  flex flex-col justify-center items-center gap-8'>
                  <div className='bg-white w-96 relative px-5 pt-16 pb-8 flex flex-col items-center gap-6 rounded-3xl'>
                        <form
                              className='flex flex-col gap-3 w-full'
                              onSubmit={(e) => {
                                    e.preventDefault();
                                    if (newPassword !== confirmPassword) {
                                          new DToast().showErrorToast('Passwords do not match');
                                          return;
                                    }
                                    resetPassword({
                                          currentPassword: currentPassword!,
                                          newPassword: newPassword!,
                                    });
                              }}
                        >
                              <CustomInputField
                                    helperText={'Enter current password'}
                                    required
                                    onChanged={(e) => {
                                          setCurrentPassword(e.target.value.trim());
                                    }}
                              />
                              <CustomInputField
                                    helperText={'Enter new password'}
                                    required
                                    onChanged={(e) => {
                                          setNewPassword(e.target.value.trim());
                                    }}
                              />
                              <CustomInputField
                                    helperText={'Re-enter new password'}
                                    required
                                    onChanged={(e) => {
                                          setConfirmPassword(e.target.value.trim());
                                    }}
                              />
                              <div className='h-1'></div>
                              <TertiaryButton
                                    text='Reset'
                                    loading={isPending}
                                    onClick={() => {}}
                              />
                        </form>
                        <button
                              onClick={onClose}
                              className='absolute top-[-12px] right-[-12px] w-[50px] h-[50px] bg-white rounded-[100px] flex justify-center items-center shadow-primary'
                        >
                              &#x2715;
                        </button>
                  </div>
            </div>
      );
};

export default ResetPasswordModal;
