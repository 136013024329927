import { FlashDetailsModel } from "./flashDetailsModel"

export interface FlashFormModel {
    image?: string | null,
    nativeText?: string | null,
    nativeExample?: string | null,
    norwegianText?: string | null,
    norwegianExample?: string | null,
    tags?: string[],
    note?: string | null,
    audio?: string
}

export const initialFlashFormState: FlashFormModel = {
    image: null,
    nativeText: null,
    nativeExample: null,
    norwegianText: null,
    norwegianExample: null,
    tags: [],
    note: null,
    audio: undefined
}

export const convertToFlashFormModel = (response: FlashDetailsModel): FlashFormModel => {
    return {
        image: response.image,
        nativeText: response.front.text,
        nativeExample: response.front.example,
        norwegianText: response.back.text,
        norwegianExample: response.back.example,
        tags: response.tags,
        note: response.note,
        audio: response.audio
    }
}