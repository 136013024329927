import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import UserModel from '../../../features/auth/data/models/admin.user.model';
export interface AdminSignup {
      data: UserModel | null;
      loading: boolean;
      error: string | null;
}

const initialState: AdminSignup = {
      data: null,
      loading: false,
      error: null,
};

export const resetPassword = createAsyncThunk<void, string, { rejectValue: string }>(
      'auth/reset-password',
      async (email, { rejectWithValue }) => {
            try {
                  if (!email || email == '') throw new Error('Please enter email');

                  await axios.post(`${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/forgot-password`, {
                        email: email,
                  });

                  return;
            } catch (error: any) {
                  if (error?.response?.data?.error) return rejectWithValue(error.response.data.error);
                  if (error instanceof Error) {
                        return rejectWithValue(error.message);
                  }
                  return rejectWithValue('An error occured');
            }
      }
);

const adminAuthSlice = createSlice({
      name: 'adminAuth',
      initialState,
      reducers: {
            setUser: (state, action: PayloadAction<UserModel>) => {
                  state.data = action.payload;
            },
            clearUser: (state) => {
                  state.data = null;
            },
      },
      extraReducers: (builder) => {
            builder
                  .addCase(resetPassword.pending, (state) => {
                        state.loading = true;
                        state.error = null;
                  })
                  .addCase(resetPassword.fulfilled, (state) => {
                        state.loading = false;
                        state.error = null;
                  })
                  .addCase(resetPassword.rejected, (state, action) => {
                        state.loading = false;
                        state.error = action.payload ?? 'An error occured';
                  });
      },
});

export const { setUser, clearUser } = adminAuthSlice.actions;
export default adminAuthSlice.reducer;
