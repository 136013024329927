import React from 'react';
import { successLottie } from '../../../../svgs/manager/svgManager';
import Lottie from 'react-lottie';
import { SecondaryText } from '../../../../components/textTitles';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import { useNavigate } from 'react-router-dom';
import { DRoutes } from '../../../../core/routes';

const PaymentSuccess = () => {

     const navigate = useNavigate()

      const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: successLottie,
            rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
            },
      };

      return (
            <div className='h-full flex flex-col gap-5 items-center justify-center'>
                  <Lottie
                        options={defaultOptions}
                        height={300}
                        width={300}
                  />
                  <SecondaryText
                        title='Congratuations! Your payment is successful'
                        fontWeight='font-bold'
                  />
                  <div>
                        <PrimaryButton
                              text='Go to your courses'
                              onClick={() => {
                                navigate(DRoutes.enrolledCourse, {replace:true})
                              }
                            }
                        />
                  </div>
            </div>
      );
};

export default PaymentSuccess;
