import { useState } from 'react';
import { ChevronIconLeft, ChevronIconRight, UserPlaceholder } from '../../svgs/manager/svgManager';
import { SecondaryText, Text14 } from '../textTitles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../core/store/store';
import { clearUser } from '../../core/store/slice/auth.slice';
import { sidebarTile } from '../../core/constants/sidebarTilesList';
import { Prefs } from '../../core/utils/prefs';

const AdminSidebar = ({ userRole }: { userRole: string }) => {
      const navigate = useNavigate();
      const dispatch = useAppDispatch();
      const location = useLocation();
      const url = location.pathname.split('/')[1];
      const { data } = useAppSelector((state) => state.user);
      const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

      return (
            <div
                  className={`relative h-[95%] flex flex-col gap-2 pt-14 pb-6 rounded-3xl shadow-primary transition-all duration-300 ${isSidebarExpanded ? 'xl:w-[380px] w-[350px] px-7' : 'xl:w-[120px] w-[100px]  px-0'}`}
            >
                  <section
                        className='absolute top-3 right-[-10px]'
                        onClick={() => {
                              setIsSidebarExpanded((prev) => !prev);
                        }}
                  >
                        {isSidebarExpanded ? <ChevronIconLeft /> : <ChevronIconRight />}
                  </section>
                  <section
                        className={`flex items-center cursor-pointer ${isSidebarExpanded ? 'justify-between' : 'justify-center'}`}
                        onClick={() => {
                              if (data?.name) {
                                    navigate('/profile');
                              }
                        }}
                  >
                        {isSidebarExpanded && (
                              <SecondaryText title={data?.name?.split(' ')[0] ?? 'user name'}></SecondaryText>
                        )}
                        {data?.profilePicture != null ? (
                              <img
                                    src={data.profilePicture}
                                    alt={data.name}
                                    className='rounded-full w-[61px] h-[61px] object-cover'
                              />
                        ) : (
                              <UserPlaceholder />
                        )}
                  </section>
                  <section className={`h-full overflow-y-scroll no-scrollbar ${isSidebarExpanded ? 'px-0' : 'px-4'}`}>
                        {sidebarTile(userRole, url).map((element, index) => (
                              <div className='w-full flex justify-center'>
                                    <button
                                          key={index}
                                          onClick={() => handleClick(element)}
                                          className={`flex items-center xl:mt-3 lg:mt-3 mt-2
                                ${
                                      element.name === 'Logout'
                                            ? 'bg-whitePrimary'
                                            : url === element.navigate.split('/')[1]
                                              ? 'bg-bluePrimary'
                                              : ' bg-whitePrimary'
                                }
                                ${isSidebarExpanded ? 'w-full justify-between px-2 rounded-[28px]' : 'justify-center px-0 rounded-full'}`}
                                    >
                                          {isSidebarExpanded && (
                                                <section className='px-2'>
                                                      <Text14
                                                            title={element.name}
                                                            color={
                                                                  element.name === 'Logout'
                                                                        ? 'text-blackPrimary'
                                                                        : url === element.navigate.split('/')[1]
                                                                          ? 'text-whitePrimary'
                                                                          : 'text-blackPrimary'
                                                            }
                                                            fontWeight='font-semibold'
                                                      />
                                                </section>
                                          )}
                                          {element.icon}
                                    </button>
                              </div>
                        ))}
                  </section>
            </div>
      );

      function handleClick(element: any) {
            if (element.name === 'Logout') {
                  dispatch(clearUser());
                  Prefs.removeToken();
            }
            navigate(element.navigate);
      }
};

export default AdminSidebar;
