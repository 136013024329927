import React, { useRef, useEffect } from 'react';
import { CourseSectionUnitModel } from '../../data/models/courseSectionUnitModel';
import video from '../../../../svgs/audio_visualizer.mp4';

const CourseUnitTypeAudio: React.FC<{ content: CourseSectionUnitModel }> = ({ content }) => {
      const videoRef = useRef<HTMLVideoElement>(null);
      const audioRef = useRef<HTMLAudioElement>(null);

      useEffect(() => {
            const videoElement = videoRef.current;
            const audioElement = audioRef.current;

            if (videoElement && audioElement) {
                  const handlePlay = () => {
                        videoElement.play();
                  };

                  const handlePause = () => {
                        videoElement.pause();
                  };

                  const handleEnded = () => {
                        videoElement.pause();
                        videoElement.currentTime = 0;
                  };

                  const handleVideoEnded = () => {
                        videoElement.currentTime = 0;
                        videoElement.play();
                  };

                  audioElement.addEventListener('play', handlePlay);
                  audioElement.addEventListener('pause', handlePause);
                  audioElement.addEventListener('ended', handleEnded);
                  videoElement.addEventListener('ended', handleVideoEnded);

                  return () => {
                        audioElement.removeEventListener('play', handlePlay);
                        audioElement.removeEventListener('pause', handlePause);
                        audioElement.removeEventListener('ended', handleEnded);
                        videoElement.removeEventListener('ended', handleVideoEnded);
                  };
            }
      }, []);

      return (
            <div className='flex flex-col rounded-md h-[80%] w-[80%]'>
                  <video
                        ref={videoRef}
                        style={{ display: 'block', width: '90%' }}
                  >
                        <source
                              src={video}
                              type='video/mp4'
                        />
                  </video>
                  <audio
                        ref={audioRef}
                        controls
                        style={{ display: 'block', width: '90%' }}
                  >
                        <source
                              src={content.unitDetails[0].media}
                              type='audio/mpeg'
                        />
                  </audio>
            </div>
      );
};

export default CourseUnitTypeAudio;
