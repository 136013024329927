import { Text12 } from '../../../../components/textTitles';

const CustomProgressBar = ({ value, total }: { value: number; total: number }) => {
      const percentage = (value / total) * 100;

      return (
            <div className='flex flex-col items-end w-full'>
                  <div className='w-full mt-3 bg-gray-200 rounded-lg h-2 relative'>
                        <div
                              className='absolute top-0 left-0 h-full rounded-lg bg-bluePrimary'
                              style={{ width: `${percentage}%` }}
                        />
                  </div>
                  <div className='flex items-center gap-2 mt-1'>
                        <Text12
                              title={`${((value / total) * 100).toFixed(0)}%`}
                              fontWeight='font-semibold'
                        />
                        <Text12 title='completed' />
                  </div>
            </div>
      );
};

export default CustomProgressBar;
