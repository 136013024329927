export const FormIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0934 7.6812L20.5975 0.185334C20.4788 0.066662 20.3179 0 20.1501 0H5.79588C4.10045 0 2.72119 1.37938 2.72119 3.07481V27.9253C2.72119 29.6206 4.10051 31 5.79594 31H25.204C26.8994 31 28.2788 29.6207 28.2788 27.9253V8.12858C28.2787 7.96081 28.212 7.79988 28.0934 7.6812ZM20.783 2.16025L26.1188 7.49599H22.5924C22.109 7.49599 21.6545 7.30775 21.3128 6.96603C20.971 6.62436 20.7829 6.16996 20.783 5.68661V2.16025ZM27.0134 27.9252C27.0134 28.9229 26.2017 29.7346 25.204 29.7346H5.79588C4.79813 29.7346 3.98644 28.9229 3.98644 27.9252V3.07481C3.9865 2.07706 4.79819 1.26531 5.79588 1.26531H19.5176V5.68649C19.5175 6.50781 19.8373 7.27996 20.418 7.86066C20.9988 8.44143 21.771 8.76124 22.5923 8.76124H27.0133L27.0134 27.9252Z"
        fill="#7573FF"
      />
      <path
        d="M8.94812 18.3906H8.66488C8.31552 18.3906 8.03223 18.6739 8.03223 19.0233C8.03223 19.3727 8.31552 19.6559 8.66488 19.6559H8.94812C9.29747 19.6559 9.58077 19.3727 9.58077 19.0233C9.58077 18.6739 9.29747 18.3906 8.94812 18.3906Z"
        fill="#7573FF"
      />
      <path
        d="M22.3351 18.3906H10.995C10.6456 18.3906 10.3623 18.6739 10.3623 19.0233C10.3623 19.3727 10.6456 19.6559 10.995 19.6559H22.3351C22.6845 19.6559 22.9678 19.3727 22.9678 19.0233C22.9678 18.6739 22.6845 18.3906 22.3351 18.3906Z"
        fill="#7573FF"
      />
      <path
        d="M8.94812 15.5H8.66488C8.31552 15.5 8.03223 15.7832 8.03223 16.1327C8.03223 16.4821 8.31552 16.7653 8.66488 16.7653H8.94812C9.29747 16.7653 9.58077 16.4821 9.58077 16.1327C9.58077 15.7832 9.29747 15.5 8.94812 15.5Z"
        fill="#7573FF"
      />
      <path
        d="M22.3351 15.5H10.995C10.6456 15.5 10.3623 15.7832 10.3623 16.1327C10.3623 16.4821 10.6456 16.7653 10.995 16.7653H22.3351C22.6845 16.7653 22.9678 16.4821 22.9678 16.1327C22.9678 15.7832 22.6845 15.5 22.3351 15.5Z"
        fill="#7573FF"
      />
      <path
        d="M8.94812 12.6094H8.66488C8.31552 12.6094 8.03223 12.8926 8.03223 13.242C8.03223 13.5914 8.31552 13.8747 8.66488 13.8747H8.94812C9.29747 13.8747 9.58077 13.5914 9.58077 13.242C9.58077 12.8926 9.29747 12.6094 8.94812 12.6094Z"
        fill="#7573FF"
      />
      <path
        d="M22.3351 12.6094H10.995C10.6456 12.6094 10.3623 12.8926 10.3623 13.242C10.3623 13.5914 10.6456 13.8747 10.995 13.8747H22.3351C22.6845 13.8747 22.9678 13.5914 22.9678 13.242C22.9678 12.8926 22.6845 12.6094 22.3351 12.6094Z"
        fill="#7573FF"
      />
    </svg>
  );
};
