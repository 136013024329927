import CustomInputField from '../../../components/customInputField';
import { TextIcon } from '../../../svgs/textIcon';
import { GridIcon } from '../../../svgs/gridIcon';
import { useTranslation } from 'react-i18next';
import CustomTextArea from '../../../components/customTextArea';
import { FlashFormModel } from '../../admin/bundle/data/models/flashFormModel';
import { SetStateAction, useEffect, useState } from 'react';
import { TagsIcon } from '../../../svgs/tagsIcon';
import { useAppSelector } from '../../../core/store/store';
import { PrimaryText, Text16 } from '../../../components/textTitles';

const BackSideForm = ({
      formData,
      setFormData,
}: {
      formData: FlashFormModel;
      setFormData: React.Dispatch<SetStateAction<FlashFormModel>>;
}) => {
      const { t } = useTranslation();
      const { data } = useAppSelector((state) => state.user);
      const [tags, setTags] = useState<string[]>([]);
      const [customTag, setCustomTag] = useState<string>('');

      useEffect(() => {
            setTags(data?.tags ?? []);
      }, [data]);

      return (
            <div className='w-[50%] h-full bg-[#F9F9F9] rounded-[4rem] shadow-[1px_4px_14.5px_0px_rgba(0,_0,_0,_0.25)] p-8 flex flex-col gap-10 overflow-y-scroll'>
                  <div className='flex items-center justify-center'>
                        <PrimaryText
                              title={t('Back Side')}
                              color='text-bluePrimary'
                        />
                  </div>
                  <section className='flex flex-col gap-5'>
                        <CustomInputField
                              value={formData.norwegianText ?? ''}
                              prefixIcon={<TextIcon />}
                              helperText={t('Enter text in Norwegian Language')}
                              onChanged={(e) => {
                                    setFormData((prev) => ({ ...prev, norwegianText: e.target.value }));
                              }}
                        ></CustomInputField>
                        <CustomInputField
                              prefixIcon={<GridIcon />}
                              value={formData.norwegianExample ?? ''}
                              helperText={t('Example in Norwegian Language')}
                              onChanged={(e) => {
                                    setFormData((prev) => ({
                                          ...prev,
                                          norwegianExample: e.target.value,
                                    }));
                              }}
                        ></CustomInputField>
                        <CustomTextArea
                              rows={5}
                              placeholder={t('Notes')}
                              value={formData.note ?? ''}
                              onChange={(e) => {
                                    setFormData((prev) => ({ ...prev, note: e.target.value }));
                              }}
                        ></CustomTextArea>
                        <section className='flex gap-3'>
                              <div>
                                    <TagsIcon />
                              </div>
                              {
                                    <section className='flex flex-wrap gap-2'>
                                          {tags?.map((tag, index) => (
                                                <button
                                                      key={index}
                                                      className={`${
                                                            formData?.tags?.includes(tag)
                                                                  ? 'bg-bluePrimary'
                                                                  : 'bg-whitePrimary'
                                                      } flex items-center justify-center h-8 px-2 rounded-lg border-[1.5px] border-bluePrimary shadow-primary`}
                                                      onClick={() => filterTags(tag, false)}
                                                >
                                                      <Text16
                                                            title={tag}
                                                            fontWeight='font-bold'
                                                            color={
                                                                  formData?.tags?.includes(tag)
                                                                        ? 'text-whitePrimary'
                                                                        : 'text-bluePrimary'
                                                            }
                                                      />
                                                </button>
                                          ))}
                                          <input
                                                type='text'
                                                value={customTag}
                                                placeholder='enter tag'
                                                className='h-8 rounded-lg w-24 outline-none border-[1.5px] border-bluePrimary text-center'
                                                onChange={(e) => {
                                                      setCustomTag(e.target.value.trim());
                                                }}
                                                onKeyDown={(e) => {
                                                      if (e.key === 'Enter' && customTag != '') {
                                                            filterTags(customTag, true);
                                                      }
                                                }}
                                          />
                                    </section>
                              }
                        </section>
                  </section>
            </div>
      );

      function filterTags(tag: string, isCustom: boolean) {
            if (!formData?.tags?.includes(tag)) {
                  setFormData((prev) => ({
                        ...prev,
                        tags: [...(formData?.tags ?? []), tag],
                  }));
                  if (isCustom) {
                        setTags([...tags, tag]);
                        setCustomTag('');
                  }
            } else {
                  setFormData((prev) => ({
                        ...prev,
                        tags: formData?.tags != null ? formData.tags.filter((element) => element !== tag) : [],
                  }));
            }
      }
};

export default BackSideForm;
