import PrimaryButton from '../../../../../../../components/buttons/primaryButton';
import { Text16 } from '../../../../../../../components/textTitles';

const ConfirmationCard = ({
      text,
      confirmText,
      onYesClick,
      onNoClick,
      loading,
}: {
      text?: string;
      confirmText: string;
      loading?: boolean;
      onYesClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
      onNoClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
      return (
            <div className=' bg-black bg-opacity-25 fixed top-0 left-0 w-screen h-screen'>
                  <div className='bg-white flex flex-col items-center gap-5 rounded-3xl p-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <Text16 title={text ?? 'Are you sure you want to delete this card?'}></Text16>
                        <section className='w-full flex justify-around gap-3'>
                              <PrimaryButton
                                    text={confirmText}
                                    loading={loading}
                                    onClick={onYesClick}
                              ></PrimaryButton>
                              <PrimaryButton
                                    text='No'
                                    onClick={loading ? () => {} : onNoClick}
                              ></PrimaryButton>
                        </section>
                  </div>
            </div>
      );
};

export default ConfirmationCard;
