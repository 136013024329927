import { useMutation } from "@tanstack/react-query"
import { endpoints } from "../../../../core/api/apisPath"
import { DAxios } from "../../../../core/api/axiosConfig"
import { DToast } from "../../../../core/utils/toasts"

export const useResetPassword = () => {
    return useMutation({
        mutationFn: async (email: string) => {
            await DAxios.post({
                endpoint: endpoints.resetPassword,
                body: {
                    email
                }
            })
        },
        onError: (error) => {
            new DToast().showErrorToast(error.message)
        }
    })
}