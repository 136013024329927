import { Suspense } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { PrivateRoute } from './contexts/privateRoute';
import { DRoutes } from './core/routes';
import Login from './features/auth/presentation/screens/login';
import ResetPassword from './features/auth/presentation/screens/resetPassword';
import Signup from './features/auth/presentation/screens/signup';
import Bundle from './features/bundle/presentation/screens/Bundle';
import SubBundle from './features/bundle/presentation/screens/SubBundle';
import CourseContent from './features/course/presentation/screens/CourseContent';
import CourseDetail from './features/course/presentation/screens/CourseDetail';
import Courses from './features/course/presentation/screens/Courses';
import CourseUnit from './features/course/presentation/screens/CourseUnit';
import UserCourses from './features/course/presentation/screens/UserCourses';
import Create from './features/createUserFlash/create';
import Dashboard from './features/dashboard/dashboard';
import Edit from './features/library/presentation/screens/edit';
import Library from './features/library/presentation/screens/library';
import Payment from './features/payment/presentation/screens/Payment';
import PaymentSuccess from './features/payment/presentation/screens/PaymentSuccess';
import Profile from './features/profile/profile';
import Register from './features/register/register';
import Revise from './features/revise/presentation/screens/revise';
import Revision from './features/revision/revision';

/*==========================USER============================*/
// const Login = lazy(() => import('./features/auth/presentation/screens/login'));
// const Signup = lazy(() => import('./features/auth/presentation/screens/signup'));
// const ResetPassword = lazy(() => import('./features/auth/presentation/screens/resetPassword'));
// const Register = lazy(() => import('./features/register/register'));
// const Dashboard = lazy(() => import('./features/dashboard/dashboard'));
// const Create = lazy(() => import('./features/createUserFlash/create'));
// const Edit = lazy(() => import('./features/library/presentation/screens/edit'));
// const Library = lazy(() => import('./features/library/presentation/screens/library'));
// const Revision = lazy(() => import('./features/revision/revision'));
// const Profile = lazy(() => import('./features/profile/profile'));
// const Bundle = lazy(() => import('./features/bundle/presentation/screens/Bundle'));
// const SubBundle = lazy(() => import('./features/bundle/presentation/screens/SubBundle'));
// const Revise = lazy(() => import('./features/revise/presentation/screens/revise'));
// const Courses = lazy(() => import('./features/course/presentation/screens/Courses'));
// const UserCourses = lazy(() => import('./features/course/presentation/screens/UserCourses'));
// const CourseDetail = lazy(() => import('./features/course/presentation/screens/CourseDetail'));
// const CourseContent = lazy(() => import('./features/course/presentation/screens/CourseContent'));
// const CourseUnit = lazy(() => import('./features/course/presentation/screens/CourseUnit'));
// const Payment = lazy(() => import('./features/payment/presentation/screens/Payment'));
// const PaymentSuccess = lazy(() => import('./features/payment/presentation/screens/PaymentSuccess'));


const AppRoutes = () => (
      <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                  <Route
                        path={DRoutes.root}
                        element={<Navigate to={DRoutes.dashboard} />}
                  />
                  <Route
                        path={DRoutes.login}
                        element={<Login />}
                  />
                  <Route
                        path='/register'
                        element={<Register />}
                  />
                  <Route element={<PrivateRoute />}>
                        <Route
                              path={DRoutes.dashboard}
                              element={<Dashboard />}
                        />
                        <Route
                              path='/profile'
                              element={<Profile />}
                        />
                        <Route
                              path='/create'
                              element={<Create />}
                        />
                        <Route
                              path='/library'
                              element={<Library />}
                        />
                        <Route
                              path='/revision'
                              element={<Revision />}
                        />
                        <Route
                              path='/edit'
                              element={<Edit />}
                        />
                        <Route
                              path={DRoutes.bundle}
                              element={<Outlet />}
                        >
                              <Route
                                    index
                                    element={<Bundle />}
                              />
                              <Route
                                    path=':bundle'
                                    element={<Outlet />}
                              >
                                    <Route
                                          index
                                          element={<SubBundle />}
                                    />
                                    <Route
                                          path=':subBundle'
                                          element={<Outlet />}
                                    >
                                          <Route
                                                index
                                                element={<Revise />}
                                          />
                                    </Route>
                              </Route>
                        </Route>
                        <Route
                              path={DRoutes.payment}
                              element={<Outlet />}
                        >
                              <Route
                                    index
                                    element={<Payment />}
                              />
                              <Route
                                    path={DRoutes.paymentSuccess}
                                    element={<PaymentSuccess />}
                              />
                        </Route>

                        <Route path={DRoutes.course}>
                              <Route
                                    index
                                    element={<Courses />}
                              />
                              <Route
                                    path=':courseDetail'
                                    element={<Outlet />}
                              >
                                    <Route
                                          index
                                          element={<CourseDetail />}
                                    />
                                    <Route
                                          path={DRoutes.courseContent}
                                          element={<Outlet />}
                                    >
                                          <Route
                                                index
                                                element={<CourseContent />}
                                          />
                                          <Route
                                                path={DRoutes.unit}
                                                element={<CourseUnit />}
                                          />
                                    </Route>
                              </Route>
                        </Route>
                        <Route path={DRoutes.enrolledCourse}>
                              <Route
                                    index
                                    element={<UserCourses />}
                              />
                              <Route
                                    path=':courseDetail'
                                    element={<Outlet />}
                              >
                                    <Route
                                          index
                                          element={<CourseDetail />}
                                    />
                                    <Route
                                          path={DRoutes.courseContent}
                                          element={<Outlet />}
                                    >
                                          <Route
                                                index
                                                element={<CourseContent />}
                                          />
                                          <Route
                                                path={DRoutes.unit}
                                                element={<CourseUnit />}
                                          />
                                    </Route>
                              </Route>
                        </Route>
                  </Route>
                  <Route
                        path='/reset-password'
                        element={<ResetPassword />}
                  />
                  <Route
                        path='/signup'
                        element={<Signup />}
                  />
                  
            </Routes>
      </Suspense>
);

export default AppRoutes;
