import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../../core/constants/enums';
import { DAxios } from '../../../../core/api/axiosConfig';
import { endpoints } from '../../../../core/api/apisPath';
import { convertToFlashDetailsModel } from '../../../admin/bundle/data/models/flashDetailsModel';
import { useAppSelector } from '../../../../core/store/store';

export const useGetCardsRevision = ({ category, tag }: { category?: string, tag?: string }) => {
    const { data } = useAppSelector((state) => state.user);
    return useQuery({
        queryKey: [QueryKey.REVISE],
        queryFn: async () => {
            const response = await DAxios.get({
                endpoint: endpoints.getCardsForRevision,
                params: {
                    userId: data?.userId
                },
            });
            const cards = (response.cards as []).map((element) => convertToFlashDetailsModel(element));

            if (category && category !== "all") {
                const today = new Date();

                if (category === "today") {
                    return cards.filter(card => {
                        const createdAt = new Date(card.createdAt);
                        return createdAt.toDateString() === today.toDateString();
                    });
                } else if (category === "last-week") {
                    const lastWeek = new Date();
                    lastWeek.setDate(today.getDate() - 7);
                    return cards.filter(card => {
                        const createdAt = new Date(card.createdAt);
                        return createdAt >= lastWeek && createdAt <= today;
                    });
                } else if (category === "last-15-days") {
                    const last15Days = new Date();
                    last15Days.setDate(today.getDate() - 15);
                    return cards.filter(card => {
                        const createdAt = new Date(card.createdAt);
                        return createdAt >= last15Days && createdAt <= today;
                    });
                } else if (category === "last-30-days") {
                    const last30Days = new Date();
                    last30Days.setDate(today.getDate() - 30);
                    return cards.filter(card => {
                        const createdAt = new Date(card.createdAt);
                        return createdAt >= last30Days && createdAt <= today;
                    });
                } else if (category === "tag" && tag) {
                    return cards.filter(card => card.tags.includes(tag));
                } else {
                    return cards;
                }
            } else {
                return cards;
            }


        },
        refetchInterval: false,
    });
};
