import { Text14 } from './textTitles';

export const InfoModal = ({ message, handleClose }: { message: string; handleClose: any }) => {
      return (
            <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
                  <div className='bg-white p-8 gap-6 rounded-xl w-[450px] h-[200px] flex flex-col items-center justify-center text-center'>
                        <Text14
                              title={message}
                              fontWeight='font-semibold'
                        />
                        <button
                              onClick={() => handleClose()}
                              className='px-5 py-2 bg-bluePrimary text-white font-semibold rounded-lg mt-4'
                        >
                              Close
                        </button>
                  </div>
            </div>
      );
};
