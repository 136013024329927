import axios from "axios";
import {
  BundleActions,
  BundleActionTypes,
} from "../../presentation/reducer/bundleReducer";
import { FlashFormModel } from "../models/flashFormModel";
import { createFlashApi, uploadFiles } from "../../../../../core/api/apisPath";
import { getToken } from "../../../../../core/utils/functions";
import { upload } from "@testing-library/user-event/dist/upload";

interface CreateFlashProps {
  formData: FlashFormModel;
  bundleId?: string;
  subBundleId?: string;
  userId?: string;
  dispatch: React.Dispatch<BundleActions>;
  audioFile?: File
  imageFile?: File
}

export const createFlash = async ({
  formData,
  bundleId,
  subBundleId,
  dispatch,
  userId,
  audioFile,
  imageFile
}: CreateFlashProps) => {
  try {
    dispatch({ type: BundleActionTypes.BUNDLE_LOADING });

    let { image, nativeText: englishText, nativeExample: englishExample, norwegianText, norwegianExample, note, tags } = formData;

    if (bundleId && (!imageFile || !image))
      throw new Error("Please select an image");
    if (!englishText || englishText.trim() === "")
      throw new Error("Please enter native text");
    if (!englishExample || englishExample.trim() === "")
      throw new Error("Please enter native example");
    if (!norwegianText || norwegianText.trim() === "")
      throw new Error("Please enter norwegian text");
    if (!norwegianExample || norwegianExample.trim() === "")
      throw new Error("Please enter norwegian example");
    if (!note || note.trim() === "") throw new Error("Please enter note");

    const token = getToken();
    let audio;
    let imageTemp;
    if (audioFile) {
      const formData = new FormData();
      formData.append('file', audioFile);
      const audioResponse = await axios.post(
        uploadFiles,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token
          }
        }
      )
      audio = audioResponse.data.response.url ?? null
    }

    if (imageFile) {
      const formData = new FormData();
      formData.append('file', imageFile);
      const imageResponse = await axios.post(
        uploadFiles,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token
          }
        }
      )
      image = imageResponse.data.response.url ?? null
    }

    const response = await axios.post(
      createFlashApi,
      {
        bundleId: bundleId,
        subBundleId: subBundleId,
        image: image,
        audio: audio,
        frontText: englishText,
        frontExample: englishExample,
        backText: norwegianText,
        backExample: norwegianExample,
        note: note,
        tags: tags,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    dispatch({ type: BundleActionTypes.BUNDLE_CUD_SUCCESS, cudPayload: true });
  } catch (error: any) {
    dispatch({
      type: BundleActionTypes.BUNDLE_DATA_FAILURE,
      error: error.message,
    });
  }
};
