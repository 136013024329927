import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DAxios } from "../../../../core/api/axiosConfig";
import { endpoints } from "../../../../core/api/apisPath";
import { CourseSectionUnitModel } from "../models/courseSectionUnitModel";
import { QueryKey } from "../../../../core/constants/enums";

export const useGetUnit = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [QueryKey.UNIT],
        mutationFn: async (unitId: string) => {
            const response = await DAxios.get({ endpoint: endpoints.getUnit + unitId });
            const unit = CourseSectionUnitModel.convertToCourseSectionUnitModel(response);
            return unit;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKey.UNIT] });
        }
    });
};
