import { useEffect, useState } from 'react'
import ErrorImageIcon from '../svgs/errorImageIcon';
import { BeatLoader } from 'react-spinners';

interface CustomImageProps {
    image: string,
    alt: string,
    fit?: string,
    className?: string
}

const CustomImage: React.FC<CustomImageProps> = ({ className, image, alt, fit }) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(false);
    }, [image]);

    const handleLoad = () => {
        setLoading(false);
    };

    const handleError = () => {
        setLoading(false);
        setError(true);
    };

    return (
        <div className={`${className} overflow-hidden`}>
            {
                !error && loading &&
                <div className={`h-full w-full flex justify-center items-center rotate-3`}>
                    <BeatLoader />
                </div>
            }
            {
                error && <div className={`h-full w-full flex justify-center items-center rotate-3`}>
                    <ErrorImageIcon />
                </div>
            }
            <img
                className={`h-full w-full ${fit ?? "object-cover"}`}
                src={image} alt={alt}
                onLoad={handleLoad}
                onError={() => { handleError() }} />
        </div>
    )
}

export default CustomImage