import { useTranslation } from 'react-i18next';
import ChartComponent from './ChartComponent';
import { Text14 } from '../../../components/textTitles';

export const RevisionGraph = () => {
      const { t } = useTranslation();
      const graphData = [
            {
                  label: t('Revised Today'),
                  color: 'bg-[#F1FF4C]',
            },
            {
                  label: t('Revised Last Week'),
                  color: 'bg-[#0DB3FB]',
            },
            {
                  label: t('Revised Last 15 Days'),
                  color: 'bg-[#001376]',
            },
            {
                  label: t('Revised Last 30 Days'),
                  color: 'bg-[#9000D4]',
            },
            {
                  label: t('Revised Last 60 Days'),
                  color: 'bg-[#DC005C]',
            },
      ];

      return (
            <div className='w-full flex bg-white shadow-[0px_4px_31.100000381469727px_0px_#00000040] rounded-[2rem] justify-around p-10 mb-4'>
                  <div className='flex flex-col xl:gap-8 gap-5'>
                        {graphData.map((data, index) => (
                              <div
                                    key={index}
                                    className='flex gap-3 items-center'
                              >
                                    <div
                                          className={`flex items-center justify-center xl:w-6 xl:h-6 w-4 h-4 ${data.color} text-white font-bold`}
                                    ></div>
                                    <Text14
                                          title={data.label}
                                          fontWeight='font-semibold'
                                    />
                              </div>
                        ))}
                  </div>
                  {/* Graph */}
                  <div>
                        <ChartComponent />
                  </div>
            </div>
      );
};
