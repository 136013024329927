import CustomInputField from '../../../components/customInputField';
import { TextIcon } from '../../../svgs/textIcon';
import { GridIcon } from '../../../svgs/gridIcon';
import { useTranslation } from 'react-i18next';
import { FlashFormModel } from '../../admin/bundle/data/models/flashFormModel';
import { SetStateAction } from 'react';
import { PrimaryText } from '../../../components/textTitles';

const FrontSideForm = ({
      formData,
      setFormData,
}: {
      formData: FlashFormModel;
      setFormData: React.Dispatch<SetStateAction<FlashFormModel>>;
}) => {
      const { t } = useTranslation();

      return (
            <div className='w-[50%] h-full bg-[#F9F9F9] rounded-[4rem] shadow-[1px_4px_14.5px_0px_rgba(0,_0,_0,_0.25)] p-8 flex flex-col gap-10'>
                  <div className='flex items-center justify-center'>
                        <PrimaryText
                              title={t('Front Side')}
                              color='text-bluePrimary'
                        />
                  </div>
                  <section className='flex flex-col gap-5'>
                        <CustomInputField
                              prefixIcon={<TextIcon />}
                              value={formData.nativeText ?? ''}
                              helperText={t('Enter text in your Native Language')}
                              onChanged={(e) => {
                                    setFormData((prev) => ({
                                          ...prev,
                                          nativeText: e.target.value,
                                    }));
                              }}
                        ></CustomInputField>
                        <CustomInputField
                              prefixIcon={<GridIcon />}
                              value={formData.nativeExample ?? ''}
                              helperText={t('Example in your Native Language')}
                              onChanged={(e) => {
                                    setFormData((prev) => ({
                                          ...prev,
                                          nativeExample: e.target.value,
                                    }));
                              }}
                        ></CustomInputField>
                  </section>
            </div>
      );
};

export default FrontSideForm;
