export interface ICourseModel {
    _id?: string;
    name?: string;
    desc?: string;
    image?: string;
    price: number;
    isComplete: boolean,
    createdAt?: string;
    updatedAt?: string;
}

export interface ICourseResponseModel {
    courses?: CourseModel[];
    totalCount: number;
}

export class CourseResponseModel implements ICourseResponseModel {
    courses: CourseModel[];
    totalCount: number;

    constructor(data: Partial<ICourseResponseModel> = {}) {
        this.courses = data.courses?.map((course) => new CourseModel(course)) || [];
        this.totalCount = data.totalCount ?? 0;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static convertToCourseResponseModel(obj: any): CourseResponseModel {
        return new CourseResponseModel({
            courses:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                obj?.course?.map((course: any) =>
                    CourseModel.convertToCourseModel(course)
                ) ?? [],
            totalCount: obj.totalCount ?? 0,
        });
    }

    static convertToEnrolledCourseResponseModel(obj: any): CourseResponseModel {
        return new CourseResponseModel({
            courses:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                obj?.courses?.map((course: any) =>
                    CourseModel.convertToCourseModel(course)
                ) ?? [],
            totalCount: obj.totalCount ?? 0,
        });
    }
}

export class CourseModel implements ICourseModel {
    _id?: string;
    name?: string;
    desc?: string;
    image?: string;
    price: number;
    isComplete: boolean;
    createdAt?: string;
    updatedAt?: string;

    constructor(data: Partial<ICourseModel> = {}) {
        this._id = data._id;
        this.name = data.name;
        this.desc = data.desc;
        this.image = data.image;
        this.price = data.price ?? 0;
        this.isComplete = data.isComplete ?? false;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static convertToCourseModel(obj: any): CourseModel {
        return new CourseModel({
            _id: obj._id,
            name: obj.name,
            desc: obj.desc,
            image: obj.image,
            price: obj.price,
            isComplete: obj.isCompleted,
            createdAt: obj.createdAt,
            updatedAt: obj.updatedAt,
        });
    }
}
