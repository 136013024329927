import { useNavigate } from 'react-router-dom';
import { CourseModel } from '../../data/models/courseModel';
import { Text16 } from '../../../../components/textTitles';
import { DRoutes } from '../../../../core/routes';

interface CourseHeaderProps {
      tOne?: CourseModel;
      tTwo?: string;
      tThree?: string;
}

const CourseHeader: React.FC<CourseHeaderProps> = ({ tOne, tTwo, tThree }) => {
      const navigate = useNavigate();

      return (
            <div className='flex gap-1 items-center'>
                  <button
                        onClick={() => {
                              if (tOne && tOne._id && tTwo) {
                                    navigate(DRoutes.courseDetails + tOne._id);
                              }
                        }}
                  >
                        <Text16
                              fontWeight='font-semiBold'
                              title={tOne?.name ?? 'Course'}
                        />
                  </button>
                  {tTwo && (
                        <Text16
                              fontWeight='font-semiBold'
                              title='>'
                        />
                  )}
                  {tTwo && (
                        <button
                              onClick={() => {
                                    if (tOne) {
                                          navigate(
                                                DRoutes.courseDetails +
                                                      tOne._id +
                                                      '/' +
                                                      DRoutes.courseContent +
                                                      `?courseId=${tOne._id}`
                                          );
                                    }
                              }}
                        >
                              <Text16
                                    fontWeight='font-semiBold'
                                    title='Chapters'
                                    color={tThree ? undefined : 'text-bluePrimary'}
                              />
                        </button>
                  )}
                  {tThree && (
                        <Text16
                              fontWeight='font-semiBold'
                              title='>'
                        />
                  )}
                  {tThree && (
                        <Text16
                              fontWeight='font-semiBold'
                              title={tThree ?? 'Unit'}
                              color='text-bluePrimary'
                        />
                  )}
            </div>
      );
};

export default CourseHeader;
