import { useState } from 'react';
import { Text14 } from '../../../../components/textTitles';
import CustomInputField from '../../../../components/customInputField';
import { SuccessModal } from '../../../../components/SuccessModal';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import { useResetPassword } from '../../data/service/resetPassword.service';

const ForgotPassword = () => {
      const [email, setEmail] = useState<string>('');
      const [successModal, setSuccessModal] = useState(false);
      const { mutate: resetPassword, isPending } = useResetPassword();
      const [showForgotPassSection, setShowForgotPassSection] = useState(false);

      return (
            <section className='w-full flex justify-end mt-1'>
                  <button
                        onClick={() => {
                              setShowForgotPassSection(true);
                        }}
                  >
                        {' '}
                        <Text14
                              title='Forgot Password?'
                              color='text-bluePrimary'
                              fontWeight='font-semibold'
                        ></Text14>
                  </button>
                  {showForgotPassSection && (
                        <div className='w-screen h-screen bg-black bg-opacity-50 absolute top-0 left-0  flex flex-col justify-center items-center gap-8'>
                              <div className='bg-white relative px-5 py-16 flex flex-col items-center gap-3 rounded-3xl'>
                                    <CustomInputField
                                          helperText={'Enter you email'}
                                          onChanged={(e) => {
                                                setEmail(e.target.value.trim());
                                          }}
                                    ></CustomInputField>
                                    <PrimaryButton
                                          text='Reset'
                                          loading={isPending}
                                          onClick={() => {
                                                resetPassword(email);
                                          }}
                                    ></PrimaryButton>
                                    <button
                                          onClick={() => {
                                                setShowForgotPassSection(false);
                                          }}
                                          className='absolute top-[-12px] right-[-12px] w-[50px] h-[50px] bg-white rounded-[100px] flex justify-center items-center shadow-primary'
                                    >
                                          &#x2715;
                                    </button>
                              </div>
                        </div>
                  )}
                  {successModal && (
                        <SuccessModal
                              handleClose={() => setSuccessModal(false)}
                              message={'A password reset link has been sent to your email.'}
                        />
                  )}
            </section>
      );
};

export default ForgotPassword;
