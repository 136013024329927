import React, { useEffect, useState } from 'react';
import { useGetUnit } from '../../data/services/getUnit.service';
import { HelperFunction } from '../../../../core/utils/functions';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import { CourseUnitTypeEnums } from '../../../../core/constants/enums';
import CourseUnitTypeContent from '../components/CourseUnitTypeContent';
import CourseUnitTypeVideo from '../components/CourseUnitTypeVideo';
import CourseUnitTypeAssignment from '../components/CourseUnitTypeAssignment';
import CourseUnitTypeAudio from '../components/CourseUnitTypeAudio';
import { DToast } from '../../../../core/utils/toasts';
import NextUnitModal from '../components/NextUnitModal';
import TertiaryButton from '../../../../components/buttons/tertiaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseHeader from '../components/CourseHeader';
import { useGetCourse } from '../../data/services/getCourse.service';
import { DRoutes } from '../../../../core/routes';

const CourseUnit = () => {
      const navigate = useNavigate();
      const location = useLocation();
      const unitIdTemp = HelperFunction.getUrlQueryParameter('unitId');
      const { mutate: getUnit, data, isPending } = useGetUnit();
      const [unitId, setUnitId] = useState(unitIdTemp);
      const [isAssignmentDone, setIsAssignmentDone] = useState(false);
      const [openModal, setOpenModal] = useState(false);
      const { data: course } = useGetCourse(data?.courseId);

      useEffect(() => {
            getUnit(unitId ?? '');
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [unitId]);

      return (
            <LoadingEmptyContainer
                  loading={isPending}
                  empty={!data && !isPending}
                  className='flex flex-col gap-3'
            >
                  <div className='flex items-center justify-between'>
                        <CourseHeader
                              tOne={course?.courses[0]}
                              tTwo={'Chapters'}
                              tThree={data?.name}
                        />

                        <TertiaryButton
                              text='Next Unit'
                              onClick={() => {
                                    if (data?.type === CourseUnitTypeEnums.ASSIGNMENT) {
                                          if (!isAssignmentDone) {
                                                new DToast().showErrorToast('Please complete all assignments');
                                          } else {
                                                setOpenModal(true);
                                          }
                                    } else {
                                          setOpenModal(true);
                                    }
                              }}
                        />
                  </div>
                  {openModal && (
                        <NextUnitModal
                              data={data}
                              onSucess={() => {
                                    if (data?.nextUnitId) {
                                          const searchParams = new URLSearchParams(location.search);
                                          searchParams.set('unitId', data.nextUnitId);
                                          const newUrl = `${location.pathname}?${searchParams.toString()}`;
                                          window.history.pushState(null, '', newUrl);
                                          setUnitId(data.nextUnitId);
                                          setOpenModal(false);
                                          setIsAssignmentDone(false);
                                    } else {
                                          navigate(
                                                DRoutes.courseDetails +
                                                      data?.courseId +
                                                      '/' +
                                                      DRoutes.courseContent +
                                                      `?courseId=${data?.courseId}`
                                          );
                                    }
                              }}
                              closeModal={() => setOpenModal(false)}
                        />
                  )}
                  {data?.type === CourseUnitTypeEnums.CONTENT && <CourseUnitTypeContent content={data} />}
                  {data?.type === CourseUnitTypeEnums.VIDEO && <CourseUnitTypeVideo content={data} />}
                  {data?.type === CourseUnitTypeEnums.ASSIGNMENT && (
                        <CourseUnitTypeAssignment
                              content={data.assignments}
                              isAnswerDone={(isDone) => {
                                    setIsAssignmentDone(isDone);
                              }}
                        ></CourseUnitTypeAssignment>
                  )}
                  {data?.type === CourseUnitTypeEnums.AUDIO && <CourseUnitTypeAudio content={data} />}
            </LoadingEmptyContainer>
      );
};

export default CourseUnit;
