import { ProfileIcon } from '../profileIcon';
import { EmailIcon } from '../emailIcon';
import { KeyIcon } from '../keyIcon';
import { GroupIcon } from '../groupIcon';
import { GoogleIcon } from '../googleIcon';
import { CreateIcon } from '../createIcon';
import ProfileRoleIcon from '../profileRoleIcon';
import { LibraryIcon } from '../libraryIcon';
import { LogoutIcon } from '../logoutIcon';
import { UserPlaceholder } from '../userPlaceholder';
import { ChevronIconLeft } from '../chevronIconLeft';
import { ChevronIconRight } from '../chevronIconRight';
import { DashboardIcon } from '../dashboardIcon';
import { TextIcon } from '../textIcon';
import { CameraIcon } from '../cameraIcon';
import { AddIcon } from '../addIcon';
import { FilterIcon } from '../filterIcon';
import { EditIcon } from '../editIcon';
import { RectangularAddIcon } from '../rectangularAddIcon';
import ErrorImageIcon from '../errorImageIcon';
import { TrashIcon } from '../trashIcon';
import { EyeCloseIcon } from '../eyeCloseIcon';
import { EyeIcon } from '../eyeIcon';
import { RevisionIconSidebar } from '../revisionIconSidebar';
import { GridIcon } from '../gridIcon';
import { DotsIcon } from '../dotsIcon';
import MicrophoneIcon from '../microphone';
import EmptyStateIcon from '../EmptyStateIcon';
import DollarIcon from '../DollarIcon';
import ContentIcon from '../ContentIcon';
import Arrow from '../Arrow';
import ContentIconSecondary from '../ContentIconSecondary';
import AssignmentIcon from '../AssignmentIcon';
import PresentationIcon from '../PresentationIcon';
import VideoIcon from '../VideoIcon';
import VolumeIcon from '../VolumeIcon';
import InfoIcon from '../InfoIcon';
import ComingSoon from '../ComingSoon';
import successLottie from '../success.json'

export {
      ProfileIcon,
      EmailIcon,
      KeyIcon,
      GoogleIcon,
      ProfileRoleIcon,
      CreateIcon,
      LibraryIcon,
      GroupIcon,
      LogoutIcon,
      UserPlaceholder,
      ChevronIconLeft,
      ChevronIconRight,
      DashboardIcon,
      TextIcon,
      CameraIcon,
      AddIcon,
      FilterIcon,
      EditIcon,
      RectangularAddIcon,
      ErrorImageIcon,
      TrashIcon,
      EyeIcon,
      EyeCloseIcon,
      RevisionIconSidebar,
      GridIcon,
      DotsIcon,
      MicrophoneIcon,
      EmptyStateIcon,
      DollarIcon,
      ContentIcon,
      Arrow,
      ContentIconSecondary,
      AssignmentIcon,
      VolumeIcon,
      VideoIcon,
      PresentationIcon,
      InfoIcon,
      ComingSoon,
      successLottie
};
