import { useNavigate } from 'react-router-dom';
import { useGetBundle } from '../../data/service/getBundles.service';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import BundleCard from './BundleCard';
import { Fragment } from 'react';
import TertiaryButton from '../../../../components/buttons/tertiaryButton';

const BundlesList = () => {
      const navigate = useNavigate();
      const { isFetching, data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetBundle();

      return (
            <LoadingEmptyContainer
                  loading={isFetching && !isFetchingNextPage}
                  empty={false}
                  className='flex flex-col gap-3 mb-4'
            >
                  <section className='w-full py-2 pl-2 pr-5 flex flex-wrap xl:gap-8 lg:gap-6 gap-4 mb-6'>
                        {data?.pages.map((elem, index) => {
                              return (
                                    <Fragment key={index}>
                                          {elem?.bundles.map((element, i) => {
                                                return (
                                                      <BundleCard
                                                            key={i}
                                                            bundleData={element}
                                                            onClick={() => {
                                                                  navigate(`/bundle/${element.name}`, {
                                                                        state: element,
                                                                  });
                                                            }}
                                                      />
                                                );
                                          })}
                                    </Fragment>
                              );
                        })}
                  </section>
                  {hasNextPage && (
                        <center className='mb-8'>
                              <TertiaryButton
                                    text='Load More'
                                    loading={isFetchingNextPage}
                                    onClick={() => {
                                          fetchNextPage();
                                    }}
                              />
                        </center>
                  )}
                  <div className='h-3'></div>
            </LoadingEmptyContainer>
      );
};

export default BundlesList;
