import { userCardsTableHeaderItems } from '../../../../core/constants/tableHeadersList';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../core/store/store';
import { Text16 } from '../../../../components/textTitles';

const LibraryTableHead = ({ onChange }: { onChange: (val: string) => void }) => {
      const { t } = useTranslation();
      const { data } = useAppSelector((state) => state.user);

      return (
            <thead>
                  <th className='w-8'></th>
                  {userCardsTableHeaderItems.map((element, index) => (
                        <th key={index}>
                              {element.type === 'dropdown' ? (
                                    <div className='flex items-center justify-left gap-1 h-16'>
                                          {element.icon}
                                          <select
                                                className='bg-whitePrimary appearance-none'
                                                onChange={(e) => {
                                                      onChange(e.target.value);
                                                }}
                                          >
                                                <option value='Tags'>Tags</option>
                                                {data?.tags?.map((element, index) => (
                                                      <option
                                                            key={index}
                                                            value={element}
                                                      >
                                                            {element}
                                                      </option>
                                                ))}
                                          </select>
                                    </div>
                              ) : (
                                    <div className='flex items-center justify-left gap-1 h-16'>
                                          {element.icon}
                                          <Text16
                                                title={t(element.name)}
                                                fontWeight='font-bold'
                                          />
                                    </div>
                              )}
                        </th>
                  ))}
            </thead>
      );
};

export default LibraryTableHead;
