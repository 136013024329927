interface AddIconProps {
    size?: number
}

export const AddIcon = ({ size }: AddIconProps) => {
    return (
        <div>
            <svg width={size ?? 40} height={size ?? 40} viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.8408 62.3729C48.9857 62.3729 62.8844 48.4794 62.8844 31.3409C62.8844 14.2024 48.9857 0.308838 31.8408 0.308838C14.6958 0.308838 0.797119 14.2024 0.797119 31.3409C0.797119 48.4794 14.6958 62.3729 31.8408 62.3729Z" fill="#4285F4" />
                <path d="M31.8394 45.0518C31.2651 45.0517 30.7143 44.8235 30.3081 44.4175C29.902 44.0115 29.6737 43.461 29.6736 42.8868V19.7932C29.6736 19.219 29.9018 18.6683 30.3079 18.2623C30.7141 17.8563 31.265 17.6282 31.8394 17.6282C32.4138 17.6282 32.9647 17.8563 33.3709 18.2623C33.7771 18.6683 34.0053 19.219 34.0053 19.7932V42.8868C34.0051 43.461 33.7769 44.0115 33.3707 44.4175C32.9646 44.8235 32.4138 45.0517 31.8394 45.0518Z" fill="white" />
                <path d="M43.391 33.5046H20.2888C19.7143 33.5046 19.1635 33.2765 18.7573 32.8705C18.3511 32.4645 18.1229 31.9138 18.1229 31.3396C18.1229 30.7654 18.3511 30.2147 18.7573 29.8087C19.1635 29.4027 19.7143 29.1746 20.2888 29.1746H43.391C43.9654 29.1746 44.5163 29.4027 44.9225 29.8087C45.3286 30.2147 45.5568 30.7654 45.5568 31.3396C45.5568 31.9138 45.3286 32.4645 44.9225 32.8705C44.5163 33.2765 43.9654 33.5046 43.391 33.5046Z" fill="white" />
            </svg>
        </div>
    )
}
