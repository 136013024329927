import { BundleModel } from '../../data/models/bundle.model';
import CustomImage from '../../../../components/customImage';
import { SecondaryText, Text12 } from '../../../../components/textTitles';

interface BundleCardProps {
      bundleData: BundleModel;
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const BundleCard = ({ bundleData, onClick }: BundleCardProps) => {
      return (
            <div
                  className={`xl:w-[300px] lg:w-[240px] xl:h-[300px] h-[280px] relative rounded-3xl overflow-hidden 
            cursor-pointer shadow-primary hover:shadow-2xl`}
            >
                  <section onClick={onClick}>
                        <CustomImage
                              image={bundleData.image ?? ''}
                              alt={bundleData.name ?? ''}
                              className='h-[150px] w-full'
                        />
                        <div className='px-[16px] py-[14px] flex flex-col gap-2'>
                              <section className='line-clamp-1'>
                                    <SecondaryText title={bundleData.name ?? ''}></SecondaryText>
                              </section>
                              <section className='xl:line-clamp-5 line-clamp-3'>
                                    <Text12 title={bundleData.desc ?? ''}></Text12>
                              </section>
                        </div>
                  </section>
            </div>
      );
};

export default BundleCard;
