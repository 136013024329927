import { useNavigate } from "react-router-dom";
import { CreateIconWhite } from "../../../svgs/createIconWhite";
import { FormIcon } from "../../../svgs/formIcon";
import { GroupWhiteIcon } from "../../../svgs/groupWhiteIcon";
import { NoteIcon } from "../../../svgs/noteIcon";
import GetStartedCard from "./getStartedCard";
import { useTranslation } from "react-i18next";
import { SecondaryText } from "../../../components/textTitles";

const GetStarted = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Items =
    [
      {
        title: t("Create a Flash Card"),
        icon: <CreateIconWhite />,
        navigate: "/create",
      },
      {
        title: t("Your Library"),
        icon: <GroupWhiteIcon />,
        navigate: "/library",
      },
      {
        title: t("Bundles"),
        icon: <NoteIcon />,
        navigate: "/bundle",
      },
      {
        title: t("Fill Forms"),
        icon: <FormIcon />,
        navigate: "/forms",
      },
    ];

  return (
    <div className="flex flex-col gap-2 w-full rounded-3xl shadow-primary xl:px-10 xl:py-8 px-6 py-6 ">
      <SecondaryText title={t("Dashboard") } color="text-bluePrimary" />
      <div className="flex flex-wrap justify-center">
        {Items.map((item) => {
          return (
            <button
              className="w-1/2 p-3"
              onClick={() => navigate(item.navigate)}
            >
              <GetStartedCard title={item.title} icon={item.icon} />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GetStarted;
