import QuestionBody from './QuestionBody';
import { Comprehension } from '../../../data/models/assignmentModel';
import CourseUnitTypeAssignment from '../CourseUnitTypeAssignment';
import { useEffect, useState } from 'react';

const ComprehensionQuestion = ({
      question,
      savedAnswer,
      onSaveAnswer,
}: {
      question: Comprehension;
      savedAnswer: {};
      onSaveAnswer: (answer?: {}) => void;
}) => {
      const [answers, setAnswers] = useState({});

      useEffect(() => {
            if (savedAnswer) {
                  setAnswers(savedAnswer);
            }
      }, [savedAnswer]);

      return (
            <div className='flex flex-col w-full gap-4'>
                  <QuestionBody question={question.question_body} />
                  <hr className='mt-2 ' />
                  <CourseUnitTypeAssignment
                        content={question.subparts}
                        isAnswerDone={() => {}}
                        isNested={true}
                        prefilledAnswers={savedAnswer}
                        answersCallback={(answer) => {
                              const newAnswers = { ...answers, ...answer };
                              setAnswers(newAnswers);
                              onSaveAnswer(newAnswers);
                        }}
                  />
            </div>
      );
};

export default ComprehensionQuestion;
