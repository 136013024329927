export const CreateIconWhite = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" fill="#7573FF" stroke="#7573FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12.825 8.7C12.825 8.24437 12.4556 7.875 12 7.875C11.5444 7.875 11.175 8.24437 11.175 8.7V11.175H8.7C8.24437 11.175 7.875 11.5444 7.875 12C7.875 12.4556 8.24437 12.825 8.7 12.825H11.175V15.3C11.175 15.7556 11.5444 16.125 12 16.125C12.4556 16.125 12.825 15.7556 12.825 15.3V12.825H15.3C15.7556 12.825 16.125 12.4556 16.125 12C16.125 11.5444 15.7556 11.175 15.3 11.175H12.825V8.7Z"
        fill="white"
      />
    </svg>
  );
};
