import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CustomImage from '../../../components/customImage';
import { setUser } from '../../../core/store/slice/auth.slice';
import { useAppSelector, useAppDispatch } from '../../../core/store/store';
import { UserPlaceholder } from '../../../svgs/userPlaceholder';
import { InfoInput } from './InfoInput';
import UserModel from '../../auth/data/models/admin.user.model';
import ResetPasswordModal from './ResetPasswordModal';
import { Tooltip } from 'react-tooltip';

export const ProfileInfo = () => {
      const { data } = useAppSelector((state) => state.user);
      const dispatch = useAppDispatch();
      const [userPhoneNo, setUserPhoneNo] = useState<string>(data?.phoneNumber?.toString() ?? '');
      const [userName, setUserName] = useState<string>(data?.name || '');
      const [userEmail, setUserEmail] = useState<string>(data?.email || '');
      const [userAbout, setUserAbout] = useState<string>(data?.about || '');
      const [isEditable, setIsEditable] = useState<boolean>(false);
      const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);

      const handleUpdate = async () => {
            try {
                  await axios.post(`${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/update-account-details`, {
                        userId: data?.userId,
                        name: userName,
                        email: userEmail,
                        phoneNumber: userPhoneNo,
                        about: userAbout,
                  });
                  toast.success('Profile updated successfully');
                  const userData: UserModel = {
                        ...data!,
                        name: userName,
                        email: userEmail,
                        phoneNumber: userPhoneNo,
                        about: userAbout,
                  };
                  dispatch(setUser(userData));
                  setIsEditable(false);
            } catch (error) {
                  /* empty */
            }
      };

      const handleChoosePicture = async () => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.click();
            input.onchange = async () => {
                  const file = input.files?.[0];
                  if (file) {
                        if (file.size > 4 * 1024 * 1024) {
                              toast.error('File size is too large, please choose a smaller file');
                              return;
                        }
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async () => {
                              const base64Image = reader.result;
                              await updateUserProfilePicture(base64Image);
                        };
                  }
            };
      };

      const updateUserProfilePicture = async (base64Image: any) => {
            try {
                  await axios.patch(`${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/update-profile-picture`, {
                        userId: data?.userId,
                        profilePicture: base64Image,
                  });
                  toast.success('Profile picture updated successfully');
                  const userData: UserModel = {
                        ...data!,
                        profilePicture: base64Image,
                  };
                  dispatch(setUser(userData));
            } catch (error) {
                  /* empty */
            }
      };

      return (
            <div className='w-full h-full flex flex-col gap-4 items-center overflow-y-auto mb-7'>
                  <div className='flex flex-col items-center justify-between gap-3 w-full'>
                        {data?.profilePicture ? (
                              <div className='w-36 h-36 rounded-full border-2'>
                                    <CustomImage
                                          image={data.profilePicture}
                                          alt='profile picture'
                                          className='w-full h-full'
                                    />
                              </div>
                        ) : (
                              <div className='w-36 h-36 rounded-full border-2'>
                                    <UserPlaceholder className='w-full h-full' />
                              </div>
                        )}
                        <div>
                              <button
                                    className='text-gray-700 font-bold text-[10px] bg-[#F0EFFA] py-1.5 px-4 rounded-3xl'
                                    onClick={handleChoosePicture}
                              >
                                    Change Picture
                              </button>
                        </div>
                  </div>
                  <div className='flex flex-col gap-3 border border-gray-200 w-full px-3 py-3 rounded-lg shadow-md'>
                        <div className='w-full flex flex-col gap-1'>
                              <p className='text-sm font-semibold text-gray-600'>{'Your Name'}</p>
                              <InfoInput
                                    oldValue={data?.name ?? ''}
                                    value={userName}
                                    setValue={setUserName}
                                    handleUpdate={handleUpdate}
                                    placeholder='Add your name'
                              />
                        </div>
                        <div className='w-full flex flex-col gap-1'>
                              <p className='text-sm font-semibold text-gray-600'>{'Your Password'}</p>
                              <InfoInput
                                    oldValue='******'
                                    value='*******'
                                    setValue={setUserName}
                                    isPassword
                                    handleUpdate={() => {
                                          setShowPasswordModal(true);
                                    }}
                                    placeholder='Add your name'
                              />
                        </div>
                        <div className='flex flex-col gap-1'>
                              <p className='text-sm font-semibold text-gray-600'>{'Email'}</p>
                              <InfoInput
                                    oldValue={data?.email ?? ''}
                                    type='email'
                                    value={userEmail}
                                    setValue={setUserEmail}
                                    handleUpdate={handleUpdate}
                                    placeholder='Add your email'
                              />
                        </div>
                        <div className='flex flex-col gap-1'>
                              <p className='text-sm font-semibold text-gray-600'>{'Phone'}</p>
                              <InfoInput
                                    oldValue={data?.phoneNumber?.toString() ?? ''}
                                    value={userPhoneNo ?? ''}
                                    setValue={setUserPhoneNo}
                                    handleUpdate={handleUpdate}
                                    placeholder='Add phone number'
                              />
                        </div>
                  </div>
                  <div className='flex flex-col gap-2 border border-gray-200 w-full py-3 px-3 rounded-lg shadow-md'>
                        <div className='flex gap-4 justify-between items-center'>
                              <div className='flex gap-2'>
                                    <h2 className='text-sm font-semibold text-gray-900'>{'About'}</h2>
                                    <h2 className='text-sm font-semibold text-bluePrimary'>{data?.name}</h2>
                              </div>
                              <button
                                    className='text-gray-700 font-bold text-[10px] bg-[#F0EFFA] py-1.5 px-4 rounded-3xl'
                                    onClick={() => {
                                          if (isEditable && userAbout !== data?.about) {
                                                handleUpdate();
                                          }
                                          setIsEditable(!isEditable);
                                    }}
                              >
                                    {isEditable ? 'Save' : 'Edit'}
                              </button>
                        </div>
                        <textarea
                              value={userAbout}
                              onChange={(e) => setUserAbout(e.target.value)}
                              className={`w-full h-24 text-sm font-semibold text-gray-900 bg-white outline-none resize-none ${
                                    isEditable ? 'border-[1.5px] rounded-lg p-2 border-bluePrimary' : ''
                              } ${!isEditable ? 'cursor-default' : ''}`}
                              placeholder='Add a short bio about yourself'
                              disabled={!isEditable}
                              onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                          if (isEditable && userAbout !== data?.about) {
                                                handleUpdate();
                                          }
                                          setIsEditable(!isEditable);
                                    }
                              }}
                        ></textarea>
                  </div>
                  <Tooltip
                        className='!max-w-[32rem] !z-[100] !bg-grayBg !text-bluePrimary !font-medium !border-borderColor !rounded-lg !shadow-md'
                        id='coming-soon-tooltip'
                        variant='light'
                        opacity={1}
                  />
                  {showPasswordModal && <ResetPasswordModal onClose={() => setShowPasswordModal(false)} />}
            </div>
      );
};
