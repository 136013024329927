export const ReviseIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_211_78)">
        <path
          d="M14 5.83317C14 5.52375 14.1229 5.22701 14.3417 5.00821C14.5605 4.78942 14.8572 4.6665 15.1667 4.6665H24.5C24.8094 4.6665 25.1062 4.78942 25.325 5.00821C25.5438 5.22701 25.6667 5.52375 25.6667 5.83317C25.6667 6.14259 25.5438 6.43934 25.325 6.65813C25.1062 6.87692 24.8094 6.99984 24.5 6.99984H15.1667C14.8572 6.99984 14.5605 6.87692 14.3417 6.65813C14.1229 6.43934 14 6.14259 14 5.83317Z"
          fill="#7573FF"
        />
        <path
          d="M14 14.0002C14 13.6907 14.1229 13.394 14.3417 13.1752C14.5605 12.9564 14.8572 12.8335 15.1667 12.8335H24.5C24.8094 12.8335 25.1062 12.9564 25.325 13.1752C25.5438 13.394 25.6667 13.6907 25.6667 14.0002C25.6667 14.3096 25.5438 14.6063 25.325 14.8251C25.1062 15.0439 24.8094 15.1668 24.5 15.1668H15.1667C14.8572 15.1668 14.5605 15.0439 14.3417 14.8251C14.1229 14.6063 14 14.3096 14 14.0002Z"
          fill="#7573FF"
        />
        <path
          d="M14 22.1667C14 21.8572 14.1229 21.5605 14.3417 21.3417C14.5605 21.1229 14.8572 21 15.1667 21H24.5C24.8094 21 25.1062 21.1229 25.325 21.3417C25.5438 21.5605 25.6667 21.8572 25.6667 22.1667C25.6667 22.4761 25.5438 22.7728 25.325 22.9916C25.1062 23.2104 24.8094 23.3333 24.5 23.3333H15.1667C14.8572 23.3333 14.5605 23.2104 14.3417 22.9916C14.1229 22.7728 14 22.4761 14 22.1667Z"
          fill="#7573FF"
        />
        <path
          d="M7.0001 4.60828L8.4001 6.99995H5.54177L7.0001 4.60828ZM7.0001 1.16661C6.79968 1.17521 6.60443 1.2329 6.43152 1.33462C6.25861 1.43633 6.11333 1.57895 6.00843 1.74995L2.4501 7.58328C2.36227 7.7652 2.31665 7.9646 2.31665 8.16661C2.31665 8.36862 2.36227 8.56803 2.4501 8.74995C2.54618 8.92862 2.68945 9.0775 2.86431 9.18036C3.03917 9.28322 3.23891 9.33612 3.44177 9.33328H10.5001C10.7005 9.32468 10.8958 9.26699 11.0687 9.16527C11.2416 9.06356 11.3869 8.92094 11.4918 8.74995C11.6071 8.57728 11.6687 8.37428 11.6687 8.16661C11.6687 7.95895 11.6071 7.75595 11.4918 7.58328L7.99177 1.74995C7.89569 1.57127 7.75241 1.42239 7.57755 1.31953C7.40269 1.21667 7.20295 1.16377 7.0001 1.16661Z"
          fill="#7573FF"
        />
        <path
          d="M7 17.5C6.30777 17.5 5.63108 17.2947 5.05551 16.9101C4.47993 16.5256 4.03133 15.9789 3.76642 15.3394C3.50152 14.6999 3.4322 13.9961 3.56725 13.3172C3.7023 12.6383 4.03564 12.0146 4.52513 11.5251C5.01461 11.0356 5.63825 10.7023 6.31719 10.5673C6.99612 10.4322 7.69985 10.5015 8.33939 10.7664C8.97893 11.0313 9.52556 11.4799 9.91014 12.0555C10.2947 12.6311 10.5 13.3078 10.5 14C10.5 14.9283 10.1313 15.8185 9.47488 16.4749C8.8185 17.1313 7.92826 17.5 7 17.5ZM7 12.8333C6.76926 12.8333 6.54369 12.9018 6.35184 13.03C6.15998 13.1581 6.01044 13.3404 5.92214 13.5535C5.83384 13.7667 5.81074 14.0013 5.85575 14.2276C5.90077 14.4539 6.01188 14.6618 6.17504 14.825C6.33821 14.9881 6.54609 15.0992 6.7724 15.1443C6.99871 15.1893 7.23328 15.1662 7.44647 15.0779C7.65965 14.9896 7.84185 14.84 7.97005 14.6482C8.09824 14.4563 8.16667 14.2307 8.16667 14C8.16667 13.6906 8.04375 13.3938 7.82496 13.175C7.60617 12.9563 7.30942 12.8333 7 12.8333Z"
          fill="#7573FF"
        />
        <path
          d="M9.33333 25.6665H4.66667C4.35725 25.6665 4.0605 25.5436 3.84171 25.3248C3.62292 25.106 3.5 24.8093 3.5 24.4998V19.8332C3.5 19.5238 3.62292 19.227 3.84171 19.0082C4.0605 18.7894 4.35725 18.6665 4.66667 18.6665H9.33333C9.64275 18.6665 9.9395 18.7894 10.1583 19.0082C10.3771 19.227 10.5 19.5238 10.5 19.8332V24.4998C10.5 24.8093 10.3771 25.106 10.1583 25.3248C9.9395 25.5436 9.64275 25.6665 9.33333 25.6665ZM5.83333 23.3332H8.16667V20.9998H5.83333V23.3332Z"
          fill="#7573FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_211_78">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
