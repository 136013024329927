export enum UserRoleEnum {
      STUDENT = 'STUDENT',
      ADMIN = 'ADMIN',
}

export enum QueryKey {
      AUTH = 'AUTH',
      BUNDLE = 'BUNDLE',
      SUBBUNDLE = 'SUBBUNDLE',
      FLASHCARD = 'FLASHCARD',
      COURSE = 'COURSE',
      ENROLLEDCOURSE = 'ENROLLEDCOURSE',
      CHECK_COURSE_ENROLLMENT = 'CHECK_COURSE_ENROLLMENT',
      COURSESECTION = 'COURSESECTION',
      COURSESECTIONUNIT = 'COURSESECTIONUNIT',
      UNIT = 'UNIT',
}

export enum CourseUnitTypeEnums {
      CONTENT = 'CONTENT',
      VIDEO = 'VIDEO',
      AUDIO = 'AUDIO',
      PRESENTATION = 'PRESENTATION',
      ASSIGNMENT = 'ASSIGNMENT',
}

export enum CourseAssignmentTypeEnum {
      MCQ = 'MCQ',
      MATCH_THE_FOLLOWING = 'MATCH_THE_FOLLOWING',
      FILL_IN_BLANKS = 'FILL_IN_BLANKS',
      WRITING = 'WRITING_QUESTION',
      LISTENING = 'LISTENING_QUESTION',
      COMPREHENSION = 'COMPREHENSION',
      FLASH_CARD = 'FLASH_CARD',
}
