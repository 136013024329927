import { SecondaryText, PrimaryText } from "../textTitles";

const AuthHeader = () => {
      return (
            <div className='flex flex-col items-center justify-center'>
                  <SecondaryText title='WELCOME TO'></SecondaryText>
                  <PrimaryText
                        title='LEARNING APP'
                        color='text-bluePrimary'
                  />
            </div>
      );
};

export default AuthHeader;
