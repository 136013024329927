import { useEffect, useReducer, useState } from 'react';
import { SuccessModal } from '../../../../components/SuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../../../components/Header';
import { useTranslation } from 'react-i18next';
import { ErrorModal } from '../../../../components/ErrorModal';
import FrontSideForm from '../../../createUserFlash/components/frontSideForm';
import BackSideForm from '../../../createUserFlash/components/backSideForm';
import {
      convertToFlashFormModel,
      FlashFormModel,
      initialFlashFormState,
} from '../../../admin/bundle/data/models/flashFormModel';
import bundleReducer, {
      BundleActionTypes,
      initialBundleReducerState,
} from '../../../admin/bundle/presentation/reducer/bundleReducer';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import { FlashDetailsModel } from '../../../admin/bundle/data/models/flashDetailsModel';
import { updateFlash } from '../../../admin/bundle/data/repository/updateFlash';
import { useAppDispatch, useAppSelector } from '../../../../core/store/store';
import { setUser } from '../../../../core/store/slice/auth.slice';

const Edit = () => {
      const location = useLocation();
      const navigate = useNavigate();
      const { t } = useTranslation();
      const storeDispatch = useAppDispatch();
      const { data } = useAppSelector((state) => state.user);
      const [prevFormData, setPrevFormData] = useState<FlashDetailsModel>();
      const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
      const [formData, setFormData] = useState<FlashFormModel>(initialFlashFormState);
      const [state, dispatch] = useReducer(bundleReducer, initialBundleReducerState);

      useEffect(() => {
            const prevFormData: FlashDetailsModel = location.state;
            if (!prevFormData) {
                  navigate('/library');
            } else {
                  setFormData(convertToFlashFormModel(prevFormData));
                  setPrevFormData(prevFormData);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
            if (state.bundleCUD === true) {
                  storeDispatch(
                        setUser({
                              ...data!,
                              tags: Array.from(new Set([...(data?.tags ?? []), ...(formData.tags ?? [])])),
                        })
                  );
                  setShowSuccessModal(true);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state.bundleCUD]);

      return (
            <div className='bg-white w-full h-full flex flex-col items-center gap-10'>
                  <Header
                        titleOne='Edit'
                        titleTwo='Flash Card'
                  />
                  <div className='w-full flex flex-row gap-8 pl-2'>
                        <FrontSideForm
                              formData={formData}
                              setFormData={setFormData}
                        ></FrontSideForm>
                        <BackSideForm
                              formData={formData}
                              setFormData={setFormData}
                        ></BackSideForm>
                  </div>
                  <PrimaryButton
                        text={state.loading ? t('Please wait..') : t('Update')}
                        onClick={() => {
                              updateFlash({
                                    flashId: prevFormData?._id ?? '',
                                    userId: data?.userId,
                                    formData: formData,
                                    dispatch: dispatch,
                                    isStarred: prevFormData?.isStarred ?? false,
                              });
                        }}
                  ></PrimaryButton>
                  {showSuccessModal && (
                        <SuccessModal
                              handleClose={() => {
                                    setShowSuccessModal(false);
                                    navigate('/library');
                              }}
                              message='Card updated successfully!'
                        />
                  )}
                  {state.error && (
                        <ErrorModal
                              handleClose={() => {
                                    dispatch({ type: BundleActionTypes.CLEAR_ERROR });
                              }}
                              message={state.error}
                        />
                  )}
            </div>
      );
};

export default Edit;
