import { CourseSectionUnitModel } from "./courseSectionUnitModel";

export interface ICourseSectionModel {
    _id?: string;
    courseId?: string;
    name?: string;
    desc?: string;
    isPublished?: boolean;
    createdAt?: string;
    updatedAt?: string;
    unit?: CourseSectionUnitModel[]
}

export interface ICourseSectionResponseModel {
    section?: CourseSectionModel[];
    totalCount: number;
}

export class CourseSectionResponseModel implements ICourseSectionResponseModel {
    section: CourseSectionModel[];
    totalCount: number;

    constructor(data: Partial<ICourseSectionResponseModel> = {}) {
        this.section =
            data.section?.map((section) => new CourseSectionModel(section)) || [];
        this.totalCount = data.totalCount ?? 0;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static convertToSectionResponseModel(obj: any): CourseSectionResponseModel {
        return new CourseSectionResponseModel({
            section:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                obj?.section?.map((section: any) =>
                    CourseSectionModel.convertToSectionModel(section)
                ) ?? [],
            totalCount: obj.totalCount ?? 0,
        });
    }
}

export class CourseSectionModel implements ICourseSectionModel {
    _id?: string;
    courseId?: string;
    name?: string;
    desc?: string;
    isPublished?: boolean;
    createdAt?: string;
    updatedAt?: string;
    unit?: CourseSectionUnitModel[]

    constructor(data: Partial<ICourseSectionModel> = {}) {
        this._id = data._id;
        this.courseId = data.courseId;
        this.name = data.name;
        this.desc = data.desc;
        this.isPublished = data.isPublished ?? false;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.unit = data.unit;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static convertToSectionModel(obj: any): CourseSectionModel {
        return new CourseSectionModel({
            _id: obj._id,
            courseId: obj.courseId,
            name: obj.name,
            desc: obj.desc,
            isPublished: obj.isPublished,
            createdAt: obj.createdAt,
            updatedAt: obj.updatedAt,
            unit: obj.unit !== undefined ?
                (obj.unit as []).map((e) => CourseSectionUnitModel.fromJson(e))
                :
                undefined
        });
    }
}
