export class Prefs {
    static getToken(): string {
        const token = localStorage.getItem("nlaToken");
        if (token) return JSON.parse(token);
        return "";
    }

    static setToken({ token }: { token: string }) {
        if (token) {
            localStorage.setItem("nlaToken", JSON.stringify(token));
        }
    }

    static removeToken() {
        localStorage.removeItem('nlaToken')
    }

}