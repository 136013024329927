import React from 'react';

const InfoIcon = ({ className }: { className?: string }) => {
      return (
            <div className={`h-5 w-5 ${className}`}>
                  <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0,0,256,256'
                  >
                        <g
                              fill='#6260ce'
                              fill-rule='nonzero'
                              stroke='none'
                              stroke-width='1'
                              stroke-linecap='butt'
                              stroke-linejoin='miter'
                              stroke-miterlimit='10'
                              stroke-dasharray=''
                              stroke-dashoffset='0'
                              font-family='none'
                              font-weight='none'
                              font-size='none'
                              text-anchor='none'
                        >
                              <g transform='scale(8.53333,8.53333)'>
                                    <path d='M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM16,21h-2v-7h2zM15,11.5c-0.828,0 -1.5,-0.672 -1.5,-1.5c0,-0.828 0.672,-1.5 1.5,-1.5c0.828,0 1.5,0.672 1.5,1.5c0,0.828 -0.672,1.5 -1.5,1.5z'></path>
                              </g>
                        </g>
                  </svg>
            </div>
      );
};

export default InfoIcon;
