import React from 'react';
import { CourseAssignmentTypeEnum, CourseUnitTypeEnums } from '../constants/enums';
import {
      AssignmentIcon,
      ContentIconSecondary,
      PresentationIcon,
      VideoIcon,
      VolumeIcon,
} from '../../svgs/manager/svgManager';
import { useLocation } from 'react-router-dom';

export function getToken(): string {
      const token = localStorage.getItem('nlaToken');
      if (token) return JSON.parse(token);
      return '';
}

export class HelperFunction {
      //==============GET QUERY PARAMTERS FROM URL=====================
      static getUrlQueryParameter = (searchableString: string): string | undefined => {
            const location = useLocation();
            const params = new URLSearchParams(location.search);
            const searchedString = params.get(searchableString);
            return searchedString === null ? undefined : searchedString;
      };

      //=====================GET CONTENT TYPE ICONS=====================
      static getCourseUnitTypeIcon = (unitType: string): React.ReactElement => {
            switch (unitType) {
                  case CourseUnitTypeEnums.VIDEO.toString():
                        return React.createElement(VideoIcon);
                  case CourseUnitTypeEnums.CONTENT.toString():
                        return React.createElement(ContentIconSecondary);
                  case CourseUnitTypeEnums.AUDIO.toString():
                        return React.createElement(VolumeIcon);
                  case CourseUnitTypeEnums.PRESENTATION.toString():
                        return React.createElement(PresentationIcon);
                  case CourseUnitTypeEnums.ASSIGNMENT.toString():
                        return React.createElement(AssignmentIcon);
                  default:
                        return React.createElement(AssignmentIcon);
            }
      };

      //================STRING TO COURSE ASSIGNMENT TYPE===============
      static stringToCourseAssignmentType(type: string): CourseAssignmentTypeEnum {
            switch (type.toUpperCase()) {
                  case 'MCQ':
                        return CourseAssignmentTypeEnum.MCQ;
                  case 'MATCH_THE_FOLLOWING':
                        return CourseAssignmentTypeEnum.MATCH_THE_FOLLOWING;
                  case 'FILL_IN_BLANKS':
                        return CourseAssignmentTypeEnum.FILL_IN_BLANKS;
                  default:
                        return CourseAssignmentTypeEnum.MCQ;
            }
      }

      static mergeClassNames = (...inputs: string[]): string => {
            const classSet = new Set<string>();

            inputs.forEach((input) => {
                  input.split(' ').forEach((className) => {
                        if (className) {
                              classSet.add(className);
                        }
                  });
            });
            return Array.from(classSet).join(' ');
      };
}
