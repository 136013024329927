export const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_224_3226)">
        <path
          d="M20.3626 9.125H18.9126V17.0625H3.08764V4.1H11.0001V2.5H3.08764C2.88567 2.50634 2.68702 2.55307 2.50342 2.63746C2.31982 2.72184 2.15499 2.84216 2.01867 2.9913C1.88234 3.14045 1.77728 3.3154 1.70969 3.50582C1.6421 3.69624 1.61335 3.89828 1.62514 4.1V17.0625C1.61846 17.2612 1.651 17.4592 1.72091 17.6452C1.79081 17.8313 1.8967 18.0018 2.03253 18.1469C2.16836 18.292 2.33145 18.4089 2.5125 18.4909C2.69354 18.573 2.88898 18.6185 3.08764 18.625H18.9126C19.1113 18.6185 19.3067 18.573 19.4878 18.4909C19.6688 18.4089 19.8319 18.292 19.9677 18.1469C20.1036 18.0018 20.2095 17.8313 20.2794 17.6452C20.3493 17.4592 20.3818 17.2612 20.3751 17.0625L20.3626 9.125Z"
          fill="#7573FF"
        />
        <path
          d="M14.1999 3.5875L8.77487 9.025L8.22487 9.575L8.12487 9.675L6.22487 13.625C6.09739 13.8786 6.05309 14.1659 6.09826 14.4461C6.14342 14.7264 6.27576 14.9852 6.47646 15.1859C6.67716 15.3866 6.93602 15.5189 7.21623 15.5641C7.49645 15.6093 7.78377 15.565 8.03737 15.4375L11.9624 13.525L12.6249 12.8625L13.1624 12.325L18.5874 6.875L19.1499 6.325L19.4624 6.0125C19.9723 5.50162 20.2586 4.8093 20.2586 4.0875C20.2586 3.3657 19.9723 2.67339 19.4624 2.1625C18.9539 1.65772 18.2664 1.37461 17.5499 1.375C16.8296 1.37403 16.1379 1.65698 15.6249 2.1625L14.7499 3.025L14.1874 3.575L14.1999 3.5875ZM7.88737 13.75L9.36237 10.7125L10.9249 12.2875L7.88737 13.75ZM10.3874 9.55L15.2624 4.6625L16.8874 6.3L12.0624 11.25L10.3874 9.55ZM17.4874 2.9125C17.6384 2.91199 17.7881 2.94157 17.9276 2.99952C18.0671 3.05746 18.1936 3.14262 18.2999 3.25C18.5159 3.46774 18.6371 3.76203 18.6371 4.06875C18.6371 4.37547 18.5159 4.66976 18.2999 4.8875L17.9874 5.1875L16.3624 3.5625L16.6749 3.25C16.7877 3.13891 16.9231 3.05348 17.072 2.99955C17.2208 2.94562 17.3796 2.92445 17.5374 2.9375L17.4874 2.9125Z"
          fill="#7573FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_224_3226">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
