import axios from "axios";
import { RevisionActions, RevisionActionTypes } from "../../presentation/reducer/revisionReducer";
import { getToken } from "../../../../core/utils/functions";
import { updateRevisionStatusApi } from "../../../../core/api/apisPath";

export const updateRevisionStatus = async ({ flashId, dispatch }: { flashId: string, dispatch: React.Dispatch<RevisionActions> }) => {
    try {
        dispatch({ type: RevisionActionTypes.REVISION_LOADING })
        const token = getToken()
        const response = await axios.post(updateRevisionStatusApi,
            {
                "cardId": flashId
            },
            {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        )

        dispatch({ type: RevisionActionTypes.REVISION_CRUD, revisionCRUDPayload: true })
        return true

    } catch (error: any) {
        dispatch({ type: RevisionActionTypes.REVISION_DATA_FAILURE, error: error.message })

    }
}