export const DashboardIcon = ({ isSelected = false }: { isSelected: boolean }) => {
  return (
    <div className={`h-[56px] w-[56px] flex justify-center items-center rounded-[50%] ${isSelected ? "bg-bluePrimary" : "bg-whitePrimary"}`}>
      {isSelected ?
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.4896 33.0002C23.5572 33.0002 25.3315 32.8916 26.8138 32.5621C28.3152 32.2285 29.6165 31.6468 30.6316 30.6318C31.6467 29.6167 32.2283 28.3153 32.5619 26.814C32.8914 25.3316 33 23.5574 33 21.4898V16.5002C33 14.6776 31.5226 13.2002 29.7 13.2002H16.5C14.6774 13.2002 13.2 14.6776 13.2 16.5002V29.7002C13.2 31.5228 14.6774 33.0002 16.5 33.0002H21.4896Z" fill="white" />
          <path d="M0 21.4898C0 23.5574 0.108636 25.3316 0.438108 26.814C0.771738 28.3153 1.35335 29.6167 2.36846 30.6318C3.38357 31.6468 4.6849 32.2285 6.1861 32.5621C6.32986 32.5939 6.47435 32.624 6.61944 32.652C8.40888 32.9977 9.9 31.4832 9.9 29.6606V16.5002C9.9 14.6776 8.42254 13.2002 6.6 13.2002H3.3C1.47746 13.2002 0 14.6776 0 16.5002V21.4898Z" fill="white" />
          <path d="M11.5102 0C9.44257 0 7.66841 0.108636 6.18593 0.438108C4.68473 0.771738 3.38341 1.35335 2.36829 2.36846C1.35318 3.38357 0.771573 4.6849 0.437943 6.1861C0.410553 6.30937 0.384779 6.43304 0.360574 6.55707C0.0068469 8.36991 1.54907 9.9 3.39608 9.9H29.6603C31.4829 9.9 32.9974 8.40888 32.6517 6.61944C32.6237 6.47435 32.5937 6.32986 32.5618 6.1861C32.228 4.6849 31.6466 3.38357 30.6313 2.36846C29.6162 1.35335 28.3149 0.771738 26.8137 0.438108C25.3312 0.108636 23.5571 0 21.4895 0H11.5102Z" fill="white" />
        </svg>
        :
        <svg width="33" height="33" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.4896 34.0002C24.5572 34.0002 26.3314 33.8916 27.8138 33.5621C29.3151 33.2285 30.6165 32.6469 31.6316 31.6318C32.6467 30.6167 33.2283 29.3153 33.5619 27.814C33.8914 26.3316 34 24.5574 34 22.4898V17.5002C34 15.6776 32.5226 14.2002 30.7 14.2002H17.5C15.6774 14.2002 14.2 15.6776 14.2 17.5002V30.7002C14.2 32.5228 15.6774 34.0002 17.5 34.0002H22.4896Z" stroke="#7573FF" stroke-width="2" />
          <path d="M1 22.4898C1 24.5574 1.10864 26.3316 1.43811 27.814C1.77174 29.3153 2.35335 30.6167 3.36846 31.6318C4.38357 32.6469 5.6849 33.2285 7.1861 33.5621C7.32986 33.594 7.47435 33.624 7.61944 33.652C9.40888 33.9977 10.9 32.4832 10.9 30.6606V17.5002C10.9 15.6776 9.42254 14.2002 7.6 14.2002H4.3C2.47746 14.2002 1 15.6776 1 17.5002L1 22.4898Z" stroke="#7573FF" stroke-width="2" />
          <path d="M12.5102 1C10.4426 1 8.6684 1.10864 7.18592 1.43811C5.68472 1.77174 4.3834 2.35335 3.36828 3.36846C2.35317 4.38357 1.77156 5.6849 1.43793 7.1861C1.41054 7.30937 1.38477 7.43304 1.36056 7.55707C1.00684 9.36991 2.54906 10.9 4.39607 10.9H30.6603C32.4829 10.9 33.9974 9.40888 33.6517 7.61944C33.6237 7.47435 33.5937 7.32986 33.5618 7.1861C33.228 5.6849 32.6466 4.38357 31.6313 3.36846C30.6162 2.35335 29.3149 1.77174 27.8137 1.43811C26.3312 1.10864 24.5571 1 22.4895 1H12.5102Z" stroke="#7573FF" stroke-width="2" />
        </svg>
      }
    </div>
  );
};
