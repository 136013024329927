export interface IBundleModel {
    id?: string;
    userId?: string;
    name?: string;
    desc?: string;
    image?: string;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
    isPublished?: boolean;
}

export interface IBundleResponseModel {
    bundles?: BundleModel[];
    totalCount: number;
}

export class BundleModel implements IBundleModel {
    id?: string;
    userId?: string;
    name?: string;
    desc?: string;
    image?: string;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
    isPublished?: boolean;

    constructor(data: Partial<IBundleModel> = {}) {
        this.id = data.id;
        this.userId = data.userId;
        this.name = data.name;
        this.desc = data.desc;
        this.image = data.image;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.__v = data.__v;
        this.isPublished = data.isPublished;
    }
    static fromJson(data: any): BundleModel {
        return new BundleModel({
            id: data._id,
            userId: data.userId,
            name: data.name,
            desc: data.desc,
            image: data.image,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            __v: data.__v,
            isPublished: data.isPublished,
        });
    }
}

export class BundleResponseModel implements IBundleResponseModel {
    bundles: BundleModel[];
    totalCount: number;

    constructor(data: Partial<IBundleResponseModel> = {}) {
        this.bundles = data.bundles?.map((bundle) => new BundleModel(bundle)) || [];
        this.totalCount = data.totalCount ?? 0;
    }

    static fromJson(data: any): BundleResponseModel {
        return new BundleResponseModel({
            bundles: data.bundles?.map((bundle: any) => BundleModel.fromJson(bundle)) || [],
            totalCount: data.totalCount ?? 0,
        });
    }

    static initialBundleState: IBundleModel = {
        id: undefined,
        userId: undefined,
        name: undefined,
        desc: undefined,
        image: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        __v: undefined,
        isPublished: undefined,
    };

}




