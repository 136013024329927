export const PlusIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 21C16.2989 21 21 16.2989 21 10.5C21 4.70101 16.2989 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.2989 4.70101 21 10.5 21ZM11.2875 7.35C11.2875 6.91508 10.9349 6.5625 10.5 6.5625C10.0651 6.5625 9.7125 6.91508 9.7125 7.35V9.7125H7.35C6.91508 9.7125 6.5625 10.0651 6.5625 10.5C6.5625 10.9349 6.91508 11.2875 7.35 11.2875H9.7125V13.65C9.7125 14.0849 10.0651 14.4375 10.5 14.4375C10.9349 14.4375 11.2875 14.0849 11.2875 13.65V11.2875H13.65C14.0849 11.2875 14.4375 10.9349 14.4375 10.5C14.4375 10.0651 14.0849 9.7125 13.65 9.7125H11.2875V7.35Z"
        fill="#7573FF"
      />
    </svg>
  );
};
