export interface FlashDetailsModel {
    _id: string;
    userId: string;
    bundleId?: string;
    subBundleId?: string;
    image: string;
    audio: string;
    front: Sides;
    back: Sides;
    note: string;
    tags: string[];
    isStarred: boolean;
    interval: number;
    repetition: number;
    efactor: number;
    createdAt: Date;
}

interface Sides {
    text: string;
    example: string;
}

export function convertToFlashDetailsModel(response: any): FlashDetailsModel {
    return {
        _id: response._id,
        userId: response.userId,
        bundleId: response.bundleId,
        subBundleId: response.subBundleId,
        image: response.image,
        audio: response.audio,
        front: {
            text: response.front.text,
            example: response.front.example
        },
        back: {
            text: response.back.text,
            example: response.back.example
        },
        note: response.note,
        tags: response.tags,
        isStarred: response.isStarred,
        interval: response.interval,
        repetition: response.repetition,
        efactor: response.efactor,
        createdAt: new Date(response.createdAt)
    };
}
