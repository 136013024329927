import { useTranslation } from 'react-i18next';
import { Header } from '../../components/Header';
import GetStarted from './components/getStarted';
import Revision from './components/revision';
import Stats from './components/stats';

const Dashboard = () => {
      const { t } = useTranslation();
      return (
            <div className='bg-white w-full h-full flex flex-col gap-6'>
                  <Header
                        titleOne={t('Welcome To')}
                        titleTwo={t('Learning App')}
                  />
                  <div className='flex flex-row gap-12 w-full'>
                        <div className='flex flex-col xl:gap-10 gap-6 w-full pl-2'>
                              <GetStarted />
                              <Stats />
                        </div>
                        <Revision />
                  </div>
            </div>
      );
};
export default Dashboard;
