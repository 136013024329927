import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../../../core/constants/enums"
import { endpoints } from "../../../../core/api/apisPath"
import { DAxios } from "../../../../core/api/axiosConfig"
import { convertToFlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel"



export const useGetCards = () => {
    return useQuery({
        queryKey: [QueryKey.FLASHCARD],
        queryFn: async () => {
            const response = await DAxios.get({
                endpoint: endpoints.getFlashCards,
            });
            const flashCards = (response.cards as []).map((element) => convertToFlashDetailsModel(element));
            return flashCards;
        },

    });
};
