import { useEffect, useState } from 'react';
import { Text14 } from '../../../../../components/textTitles';
import { WritingTypeQuestionBody } from '../../../data/models/assignmentModel';
import QuestionBody from './QuestionBody';

const WritingQuestion = ({
      question,
      savedAnswer,
      onSaveAnswer,
}: {
      question: WritingTypeQuestionBody;
      savedAnswer: string;
      onSaveAnswer: (answer: string) => void;
}) => {
      const [passage, setPassage] = useState<string>();

      useEffect(() => {
            if (savedAnswer) {
                  setPassage(savedAnswer);
            }
      }, [savedAnswer]);

      return (
            <div className='flex flex-col w-full gap-6'>
                  <QuestionBody question={question.question_body} />
                  {question.questionMedia && renderPreviewMedia()}
                  <div>
                        <Text14
                              title='Answer:'
                              fontWeight='font-semibold'
                        />
                        <textarea
                              name='answer'
                              rows={12}
                              value={passage}
                              placeholder='Type you answer here...'
                              className='w-full p-2 border-2 rounded-md shadow-md xl:text-xs text-[11px] font-medium'
                              onChange={(e) => {
                                    setPassage(e.target.value);
                                    onSaveAnswer(e.target.value);
                              }}
                        ></textarea>
                  </div>
            </div>
      );
      function renderPreviewMedia() {
            if (!question.mediaType) return;
            if (question.mediaType === 'VIDEO') {
                  return (
                        <video
                              src={question.questionMedia}
                              controls
                              className='w-full aspect-video mt-4'
                        />
                  );
            } else if (question.mediaType === 'AUDIO') {
                  return (
                        <audio
                              src={question.questionMedia}
                              controls
                              className='w-full mt-4'
                        />
                  );
            } else if (question.mediaType === 'IMAGE') {
                  return (
                        <img
                              src={question.questionMedia}
                              alt='Selected'
                              className='w-1/3 mt-4'
                        />
                  );
            } else {
                  return (
                        <Text14
                              title='Unsupported file type'
                              fontWeight='font-medium'
                        />
                  );
            }
      }
};

export default WritingQuestion;
