import { useTranslation } from 'react-i18next';
import { Header } from '../../components/Header';
import { ProfileInfo } from './components/ProfileInfo';

const Profile = () => {
      const { t } = useTranslation();

      return (
            <>
                  <Header
                        titleOne={t('Welcome To')}
                        titleTwo={t('Learning App')}
                  />
                  <ProfileInfo />
            </>
      );
};
export default Profile;
