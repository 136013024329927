export class DRoutes {
  static root: string = '/';
  static login: string = '/login';
  static register: string = '/register';
  static dashboard: string = '/dashboard';
  static bundle: string = '/bundle';
  static subBundle: string = 'sub-bundle';
  static flashCard: string = 'flash-card';
  static reviseUserCards: string = 'revise-cards';
  static course: string = '/course';
  static enrolledCourse: string = '/enrolledCourse';
  static courseDetails: string = '/course/';
  static enrolledCourseDetails: string = '/enrolledCourse/';
  static createChapter: string = 'create-chapter';
  static courseContent: string = 'courseContent';
  static unit: string = 'unit';
  static courseUnitCompletion: string = 'course-unit-complete';
  static payment: string = '/payment';
  static paymentSuccess: string = 'success';
}
