import { useState } from 'react';
import CustomInputField from '../../../../components/customInputField';
import { EmailIcon, GoogleIcon, KeyIcon, ProfileIcon } from '../../../../svgs/manager/svgManager';
import { useNavigate } from 'react-router-dom';
import { PrimaryText, SecondaryText } from '../../../../components/textTitles';
import SignUpFormModel from '../../data/models/signup.form.model';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import { useSignup } from '../../data/service/signup.service';

const SignUp = () => {
      const navigate = useNavigate();
      const [formData, setFormData] = useState<SignUpFormModel>();
      const { mutate: signup, isPending } = useSignup();

      const handleGoogleAuth = async () => {
            window.location.href = `${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/auth/google`;
      };

      return (
            <div className='h-screen w-screen bg-whitePrimary flex justify-center items-center'>
                  <div className='h-fit xl:w-[35%] lg:w-[40%] sm:w-[50%] w-max-[550px] flex flex-col justify-center items-center gap-5 md:py-6 md:px-8 md:rounded-[50px] md:shadow-primary'>
                        <section className='flex flex-col items-center'>
                              <SecondaryText title='WELCOME TO'></SecondaryText>
                              <PrimaryText
                                    title='LEARNING APP'
                                    color='text-bluePrimary'
                              ></PrimaryText>
                        </section>
                        <form
                              onSubmit={(e) => {
                                    e.preventDefault();
                                    signup(formData);
                              }}
                              className='w-full flex flex-col items-center gap-3'
                        >
                              <CustomInputField
                                    prefixIcon={<ProfileIcon />}
                                    required
                                    helperText={'Full Name'}
                                    onChanged={(event) => {
                                          setFormData((prev) => ({ ...prev, name: event.target.value }));
                                    }}
                              />
                              <CustomInputField
                                    required
                                    prefixIcon={<EmailIcon />}
                                    helperText={'Email Address'}
                                    onChanged={(event) => {
                                          setFormData((prev) => ({ ...prev, email: event.target.value }));
                                    }}
                              />
                              <CustomInputField
                                    required
                                    prefixIcon={<KeyIcon />}
                                    helperText={'Password'}
                                    onChanged={(event) => {
                                          setFormData((prev) => ({ ...prev, password: event.target.value }));
                                    }}
                              />
                              <CustomInputField
                                    required
                                    prefixIcon={<KeyIcon />}
                                    helperText={'Confirm Password'}
                                    onChanged={(event) => {
                                          setFormData((prev) => ({
                                                ...prev,
                                                cPassword: event.target.value,
                                          }));
                                    }}
                              />
                              <div>
                                    <p className='text-gray-700 xl:text-xs text-[11px] my-2 mx-2'>
                                          Password must be at least 10 characters long, contain at least one uppercase
                                          letter, one lowercase letter, one number, and one special character.
                                    </p>
                              </div>
                              <PrimaryButton
                                    text='Sign up'
                                    loading={isPending}
                              ></PrimaryButton>
                        </form>
                        <div className='flex flex-col items-center justify-center mt-4'>
                              <p className='text-gray-700 text-xs mb-2'>Or register using</p>
                              <button onClick={handleGoogleAuth}>
                                    <GoogleIcon />
                              </button>
                        </div>
                        <p className='text-gray-700 text-sm'>
                              Already have an account?{' '}
                              <button
                                    className='text-bluePrimary hover:text-[#8b89ff]'
                                    onClick={() => navigate('/')}
                              >
                                    Click here
                              </button>
                        </p>
                  </div>
            </div>
      );
};

export default SignUp;
