import { Reducer } from 'react';
import { BundleModel } from '../../data/models/bundleModel';
import { SubBundleModel } from '../../data/models/subBundleModel';
import { FlashDetailsModel } from '../../data/models/flashDetailsModel';

export enum BundleActionTypes {
  BUNDLE_LOADING = 'BUNDLE_LOADING',
  BUNDLE_DATA_SUCCESS = 'BUNDLE_DATA_SUCCESS',
  BUNDLE_CUD_SUCCESS = 'BUNDLE_CUD_SUCCESS',
  SUBBUNDLE_DATA_SUCCESS = 'SUBBUNDLE_DATA_SUCCESS',
  FLASH_DATA_SUCCESS = 'FLASH_DATA_SUCCESS',
  BUNDLE_DATA_FAILURE = 'BUNDLE_DATA_FAILURE',
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLEAR_BUNDLE_CUD = 'CLEAR_BUNDLE_CUD'
}

export interface BundleReducerState {
  bundleData: BundleModel[] | null;
  bundleCUD: boolean | null;
  subBundleData: SubBundleModel[] | null;
  flashData: FlashDetailsModel[] | null;
  loading: boolean;
  error: string | null;
}

export interface BundleActions {
  type: BundleActionTypes;
  bundlesPayload?: BundleModel[];
  cudPayload?: boolean;
  subBundlesPayload?: SubBundleModel[];
  flashDataPayload?: FlashDetailsModel[];
  error?: string;
}

export const initialBundleReducerState: BundleReducerState = {
  bundleData: null,
  bundleCUD: null,
  subBundleData: null,
  flashData: null,
  loading: false,
  error: null,
};

const bundleReducer: Reducer<BundleReducerState, BundleActions> = (state: BundleReducerState = initialBundleReducerState, action: BundleActions): BundleReducerState => {
  switch (action.type) {
    case BundleActionTypes.BUNDLE_LOADING:
      return { ...state, loading: true, error: null };
    case BundleActionTypes.BUNDLE_DATA_SUCCESS:
      return { ...state, bundleData: action.bundlesPayload ?? null, loading: false, error: null };
    case BundleActionTypes.BUNDLE_CUD_SUCCESS:
      return { ...state, bundleCUD: action.cudPayload ?? null, loading: false, error: null }
    case BundleActionTypes.SUBBUNDLE_DATA_SUCCESS:
      return { ...state, subBundleData: action.subBundlesPayload ?? null, loading: false, error: null }
    case BundleActionTypes.FLASH_DATA_SUCCESS:
      return { ...state, flashData: action.flashDataPayload ?? null, loading: false, error: null }
    case BundleActionTypes.BUNDLE_DATA_FAILURE:
      return { ...state, loading: false, error: action.error ?? null };
    case BundleActionTypes.CLEAR_ERROR:
      return { ...state, error: null };
    case BundleActionTypes.CLEAR_BUNDLE_CUD:
      return { ...state, bundleCUD: null }
    default:
      return state;
  }
};

export default bundleReducer;
