import React, { useEffect, useState } from 'react';
import { FlashCardModel, Sides } from '../../../data/models/assignmentModel';
import ReactCardFlip from 'react-card-flip';
import CustomImage from '../../../../../components/customImage';
import { PrimaryText, Text12 } from '../../../../../components/textTitles';

const FlashCardQuestion = ({
      question,
      onSaveAnswer,
}: {
      question: FlashCardModel;
      onSaveAnswer: (isFlipped: boolean) => void;
}) => {
      const [isFlipped, setIsFlipped] = useState<boolean>(false);
      const [flash, setFlash] = useState<FlashCardModel>();

      useEffect(() => {
            setFlash(question);
      }, [question]);

      return (
            <div className='w-full flex justify-center'>
                  <ReactCardFlip
                        isFlipped={isFlipped}
                        flipDirection='horizontal'
                  >
                        {frontSide({ front: flash?.front, image: flash?.image })}
                        {backSide({ back: flash?.back, note: flash?.note })}
                  </ReactCardFlip>
            </div>
      );

      function frontSide({ front, image }: { front?: Sides; image?: string }) {
            return (
                  <button
                        onClick={() => {
                              setIsFlipped((prev) => !prev);
                              onSaveAnswer(true);
                        }}
                        className='bg-blueTertiary h-[400px] w-80 rounded-lg flex flex-col justify-between items-center p-4'
                  >
                        <div>
                              {image && (
                                    <CustomImage
                                          image={image}
                                          alt='temp image'
                                          className='aspect-video rounded-md mb-6'
                                    />
                              )}
                              <PrimaryText
                                    title={front?.text ?? ''}
                                    fontWeight='font-semibold'
                                    color='text-whitePrimary'
                              />
                              <div className='h-4'></div>
                              <p className='text-whitePrimary xl:text-sm lg:text-xs text-xs'>{front?.example}</p>
                        </div>
                        <p className='text-gray-400 xl:text-xs text-[11px]'>Tap to flip</p>
                  </button>
            );
      }

      function backSide({ back, note }: { back?: Sides; note?: string }) {
            return (
                  <button
                        onClick={() => setIsFlipped((prev) => !prev)}
                        className='bg-blueTertiary h-[400px] w-80 rounded-lg flex flex-col justify-center items-center px-5'
                  >
                        <PrimaryText
                              title={back?.text ?? ''}
                              fontWeight='font-bold'
                              color='text-whitePrimary'
                        />
                        <div className='h-2'></div>
                        <p className='text-whitePrimary xl:text-sm lg:text-xs text-xs font-semibold'>
                              {back?.example ?? ''}
                        </p>
                        <div className='h-6'></div>
                        {note && (
                              <div className='flex flex-col items-start'>
                                    <Text12
                                          title='Note - '
                                          fontWeight='font-semibold'
                                          color='text-whitePrimary'
                                    />
                                    <div className='line-clamp-6 text-start'>
                                          <p className='text-whitePrimary xl:text-xs text-[11px]'>{note ?? ''}</p>
                                    </div>
                              </div>
                        )}
                  </button>
            );
      }
};

export default FlashCardQuestion;
