export const CreateIcon = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <div className={`h-[56px] w-[56px] flex justify-center items-center rounded-[50%] ${isSelected ? "bg-bluePrimary" : "bg-whitePrimary"}`}>
      {isSelected
        ?
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 33C25.6126 33 33 25.6126 33 16.5C33 7.3873 25.6126 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6126 7.3873 33 16.5 33ZM17.7375 11.55C17.7375 10.8666 17.1834 10.3125 16.5 10.3125C15.8166 10.3125 15.2625 10.8666 15.2625 11.55V15.2625H11.55C10.8666 15.2625 10.3125 15.8166 10.3125 16.5C10.3125 17.1834 10.8666 17.7375 11.55 17.7375H15.2625V21.45C15.2625 22.1334 15.8166 22.6875 16.5 22.6875C17.1834 22.6875 17.7375 22.1334 17.7375 21.45V17.7375H21.45C22.1334 17.7375 22.6875 17.1834 22.6875 16.5C22.6875 15.8166 22.1334 15.2625 21.45 15.2625H17.7375V11.55Z" fill="white" />
        </svg>
        :
        <svg
          width="33"
          height="33"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.7319 16.5127C32.7319 25.073 25.7923 32.0127 17.2319 32.0127C8.67152 32.0127 1.73193 25.073 1.73193 16.5127C1.73193 7.95228 8.67152 1.0127 17.2319 1.0127C25.7923 1.0127 32.7319 7.95228 32.7319 16.5127ZM19.4694 11.5627C19.4694 10.327 18.4676 9.3252 17.2319 9.3252C15.9962 9.3252 14.9944 10.327 14.9944 11.5627V14.2752H12.2819C11.0462 14.2752 10.0444 15.277 10.0444 16.5127C10.0444 17.7484 11.0462 18.7502 12.2819 18.7502H14.9944V21.4627C14.9944 22.6984 15.9962 23.7002 17.2319 23.7002C18.4676 23.7002 19.4694 22.6984 19.4694 21.4627V18.7502H22.1819C23.4176 18.7502 24.4194 17.7484 24.4194 16.5127C24.4194 15.277 23.4176 14.2752 22.1819 14.2752H19.4694V11.5627Z"
            fill="white"
            stroke="#7573FF"
            stroke-width="2"
          />
        </svg>
      }
    </div>
  );
};
