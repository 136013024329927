export const GroupWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49477 0.500012H8.49493L17.5052 0.500012L17.5054 0.500012C17.8154 0.499914 18.012 0.500629 18.177 0.520397C19.1644 0.638694 20.0299 1.25716 20.5566 2.16667H5.44359C5.97027 1.25715 6.83569 0.638694 7.8231 0.520397C7.98811 0.500629 8.18478 0.499914 8.49477 0.500012Z"
        stroke="#7573FF"
      />
      <path
        d="M20.2942 3.8335C21.4897 3.8335 22.507 4.41587 23.0965 5.26394C22.9013 5.22775 22.7056 5.19717 22.5105 5.17107L22.4444 5.66461L22.5105 5.17107C21.1128 4.98412 19.3612 4.98423 17.3831 4.98436L17.3522 4.98436H17.3522H8.79742H8.64787H8.64784L8.61691 4.98436C6.63879 4.98423 4.88728 4.98412 3.48965 5.17107C3.29442 5.19718 3.09878 5.22776 2.90357 5.26395C3.49303 4.41587 4.51036 3.8335 5.70579 3.8335H20.2942Z"
        stroke="#7573FF"
      />
      <path
        d="M1.54309 19.4474L1.04843 19.5203L1.54309 19.4474L0.993227 15.7142C0.700875 13.7292 0.49157 12.2983 0.500258 11.2027C0.508785 10.1274 0.728062 9.46429 1.22043 8.95519C1.72141 8.43718 2.44404 8.14199 3.64757 7.98875C4.85941 7.83446 6.46681 7.8335 8.67411 7.8335H17.3259C19.5332 7.8335 21.1406 7.83446 22.3524 7.98875C23.556 8.14199 24.2786 8.43718 24.7796 8.9552C25.2719 9.4643 25.4912 10.1274 25.4997 11.2027C25.5084 12.2983 25.2991 13.7292 25.0068 15.7142L24.4569 19.4474C24.2275 21.0052 24.0618 22.1225 23.8143 22.9688C23.5722 23.7971 23.2671 24.3079 22.7927 24.6839C22.3125 25.0644 21.6959 25.2775 20.7354 25.3879C19.7646 25.4994 18.5082 25.5002 16.776 25.5002H9.22398C7.49183 25.5002 6.23542 25.4994 5.26466 25.3879C4.30413 25.2775 3.68756 25.0644 3.20742 24.6839C2.73297 24.3078 2.42786 23.7971 2.18568 22.9688C1.93821 22.1225 1.77252 21.0052 1.54309 19.4474ZM8.88334 19.3676C8.06314 19.3676 7.30001 19.9737 7.30001 20.8359C7.30001 21.6982 8.06309 22.3043 8.88334 22.3043H17.1167C17.9369 22.3043 18.7 21.6982 18.7 20.8359C18.7 19.9737 17.9369 19.3676 17.1167 19.3676H8.88334Z"
        stroke="#7573FF"
      />
    </svg>
  );
};
