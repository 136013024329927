import { useState } from 'react';

export const InfoInput = ({
      oldValue,
      value,
      setValue,
      handleUpdate,
      placeholder,
      isPassword,
}: {
      type?: string;
      oldValue: string;
      value: string;
      setValue: (value: string) => void;
      handleUpdate?: () => void;
      placeholder: string;
      isPassword?: boolean;
}) => {
      const [isEditable, setIsEditable] = useState<boolean>(false);

      return (
            <div className='w-full flex flex-row gap-4 justify-between items-center'>
                  <input
                        type='text'
                        placeholder={placeholder}
                        value={value}
                        className={`w-full text-sm font-semibold text-gray-900 outline-none bg-white ${
                              isEditable ? 'border-b-2 border-bluePrimary' : ''
                        } ${!isEditable ? 'cursor-default' : ''}`}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={!isEditable}
                        onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                    if (isEditable && handleUpdate && value?.toString() !== oldValue?.toString()) {
                                          handleUpdate();
                                    }
                                    setIsEditable(!isEditable);
                              }
                        }}
                  />

                  <button
                        className='text-gray-700 font-bold text-[10px] bg-[#F0EFFA] py-1.5 px-4 rounded-3xl'
                        onClick={() => {
                              if (isPassword && handleUpdate) {
                                    handleUpdate();
                              } else if (isEditable && handleUpdate && value?.toString() !== oldValue?.toString()) {
                                    handleUpdate();
                                    setIsEditable(!isEditable);
                              }
                        }}
                  >
                        {isEditable ? 'Save' : 'Edit'}
                  </button>
            </div>
      );
};
