import { useQuery } from "@tanstack/react-query"
import { DAxios } from "../../../../core/api/axiosConfig"
import { endpoints } from "../../../../core/api/apisPath"
import { QueryKey } from "../../../../core/constants/enums"
import { convertToEnrolledCourseMeta } from "../models/enrolledCourseMetaModel"

export const useCheckCourseEnrollment = ({ courseId }: { courseId?: string }) => {
    return useQuery({
        queryKey: [QueryKey.CHECK_COURSE_ENROLLMENT, courseId],
        queryFn: async () => {
            const response = await DAxios.get({
                endpoint: endpoints.checkCourseEnrollment + courseId
            })
            const enrolledData = convertToEnrolledCourseMeta(response)
            console.log(enrolledData)
            return enrolledData
        },
        enabled: courseId! === undefined ? false : true

    })
}