export const ChevronIconRight = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19"
        cy="19"
        r="18"
        fill="white"
        stroke="#7573FF"
        stroke-width="2"
      />
      <path
        d="M14.6445 26.6486L14.6433 26.6499C14.4705 26.826 14.4155 27.0442 14.4063 27.2195C14.3972 27.3941 14.4302 27.5648 14.4747 27.7098C14.5614 27.9924 14.726 28.2858 14.9125 28.476C15.1298 28.6983 15.4215 28.814 15.711 28.814C15.7093 28.814 15.7083 28.814 15.7083 28.814C15.7083 28.8143 15.7256 28.8158 15.7775 28.8245C15.7863 28.8259 15.7962 28.8276 15.807 28.8295C15.8546 28.8377 15.9196 28.8488 15.9816 28.8565C16.0569 28.8659 16.1631 28.8752 16.2738 28.8632C16.3787 28.8517 16.5583 28.8135 16.7049 28.6641L16.705 28.6642L16.7125 28.6562L25.2665 19.5449C25.6947 19.1036 25.6945 18.3937 25.2658 17.9526L16.9508 9.15635L16.9509 9.15629L16.9444 9.14969C16.8033 9.00579 16.6258 8.92594 16.4742 8.88102C16.3168 8.83441 16.149 8.81396 15.9892 8.81396C15.8295 8.81396 15.6616 8.83441 15.5043 8.88102C15.3527 8.92594 15.1752 9.00579 15.0341 9.14969C14.8116 9.37646 14.6916 9.71541 14.6526 10.0023C14.6164 10.2688 14.6243 10.6929 14.9101 10.9868L22.3902 18.8083L14.6445 26.6486Z"
        fill="#7573FF"
        stroke="#7573FF"
      />
    </svg>
  );
};
