import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../../core/constants/enums';
import { DAxios } from '../../../../core/api/axiosConfig';
import { endpoints } from '../../../../core/api/apisPath';
import { CourseResponseModel } from '../models/courseModel';

export const useGetCourses = () => {
    return useQuery({
        queryKey: [QueryKey.COURSE],
        queryFn: async () => {
            const response = await DAxios.get({
                endpoint: endpoints.getCourses,
            });
            return CourseResponseModel.convertToCourseResponseModel(response.response);
        },
        refetchInterval: false,
    });
};
