import { LibraryHeader } from '../components/LibraryHeader';
import { useState } from 'react';
import { Header } from '../../../../components/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FlashDetailsModel } from '../../../admin/bundle/data/models/flashDetailsModel';
import { Text14 } from '../../../../components/textTitles';
import { formatDateToDMY } from '../../../../core/utils/dateFormatter';
import { EditIcon } from '../../../../svgs/editIcon';
import { TrashIcon } from '../../../../svgs/trashIcon';
import ConfirmationCard from '../../../admin/bundle/presentation/components/subBundle/subBundleFlashCards/confirmationCard';
import NoteCard from '../../../admin/bundle/presentation/components/subBundle/subBundleFlashCards/noteCard';
import LibraryTableHead from '../components/libraryTableHead';
import { useGetCards } from '../../data/services/getCards.service';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import { useDeleteCard } from '../../data/services/deleteCard.service';
import { useGetFilteredCards } from '../../data/services/getFilteredCards.service';

const Library = () => {
      const { t } = useTranslation();
      const navigate = useNavigate();
      const [openNote, setOpenNote] = useState(false);
      const [card, setCard] = useState<FlashDetailsModel>();
      const [dialogRender, setDialogRender] = useState(false);
      const { data: cards, isPending } = useGetCards();
      const { mutate: deleteCard, isPending: deletePending } = useDeleteCard(setDialogRender);
      const { mutate: getFilteredCards, isPending: filterPending } = useGetFilteredCards();

      return (
            <div className='bg-white w-full h-full flex gap-10'>
                  <div className='flex flex-col gap-6 w-full h-full pl-2'>
                        <Header
                              titleOne={t('Your Library')}
                              titleTwo=''
                        />
                        <LibraryHeader
                              handleSearch={(val) => {
                                    getFilteredCards({ searchString: val });
                              }}
                              handleFilterByStarred={(val) => {
                                    getFilteredCards({ isStarred: val });
                              }}
                        />
                        <div className='h-full w-full px-4 relative'>
                              <table className='w-full text-left'>
                                    <LibraryTableHead onChange={(tag) => {}} />
                              </table>
                              <LoadingEmptyContainer
                                    loading={isPending || filterPending}
                                    empty={cards?.length === 0}
                                    className='w-full'
                              >
                                    <table className='w-full text-left'>
                                          <tbody className='w-full'>
                                                {cards?.map((element) => {
                                                      return (
                                                            <tr
                                                                  key={element._id}
                                                                  className='h-12 overflow-y-scroll'
                                                            >
                                                                  <td
                                                                        className='w-16 hover:cursor-pointer'
                                                                        onClick={() => {
                                                                              navigate('/edit', { state: element });
                                                                        }}
                                                                  >
                                                                        <EditIcon />
                                                                  </td>
                                                                  <td>
                                                                        <Text14
                                                                              title={element.back.text}
                                                                              fontWeight='font-medium'
                                                                        />
                                                                  </td>
                                                                  <td>
                                                                        <Text14
                                                                              title={element.front.text}
                                                                              fontWeight='font-medium'
                                                                        />
                                                                  </td>
                                                                  <td>
                                                                        {element.tags.map((tag, index) => (
                                                                              <div
                                                                                    key={index}
                                                                                    className='inline-block px-3 py-1 bg-slate-100 w-fit m-1'
                                                                              >
                                                                                    <Text14
                                                                                          title={tag}
                                                                                          fontWeight='font-medium'
                                                                                    />
                                                                              </div>
                                                                        ))}
                                                                  </td>
                                                                  <td>
                                                                        <Text14
                                                                              title={formatDateToDMY({
                                                                                    date: element.createdAt,
                                                                              })}
                                                                              fontWeight='font-medium'
                                                                        />
                                                                  </td>
                                                                  <td
                                                                        onClick={() => {
                                                                              setOpenNote(true);
                                                                        }}
                                                                        className='hover:cursor-pointer'
                                                                  >
                                                                        <Text14
                                                                              title='view notes'
                                                                              color='text-bluePrimary'
                                                                              fontWeight='font-bold'
                                                                        />
                                                                  </td>
                                                                  <td
                                                                        className='hover:cursor-pointer'
                                                                        onClick={() => {
                                                                              setCard(element);
                                                                              setDialogRender(true);
                                                                        }}
                                                                  >
                                                                        <TrashIcon size='20px' />
                                                                  </td>
                                                                  {dialogRender && (
                                                                        <ConfirmationCard
                                                                              confirmText='Yes'
                                                                              loading={deletePending}
                                                                              onYesClick={() => {
                                                                                    deleteCard(card?._id);
                                                                              }}
                                                                              onNoClick={() => {
                                                                                    setDialogRender(false);
                                                                              }}
                                                                        />
                                                                  )}
                                                                  {openNote && (
                                                                        <NoteCard
                                                                              noteData={element.note}
                                                                              setOpenNote={setOpenNote}
                                                                        />
                                                                  )}
                                                            </tr>
                                                      );
                                                })}
                                                {/* <PaginatedSection totalPages={0} onPageChange={(page) => { console.log(page) }} /> */}
                                          </tbody>
                                    </table>
                              </LoadingEmptyContainer>
                        </div>
                  </div>
            </div>
      );
};

export default Library;
