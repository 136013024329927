import React from 'react';
import { useTranslation } from 'react-i18next';

interface CustomInputFieldProps {
      value?: string | number;
      suffixIcon?: any | null;
      prefixIcon?: any | null;
      helperText: string;
      textAlign?: 'left' | 'right' | 'center';
      disabled?: boolean;
      required?: boolean;
      type?: React.HTMLInputTypeAttribute;
      onChanged: React.ChangeEventHandler<HTMLInputElement>;
}

const CustomInputField = ({
      value,
      required,
      suffixIcon,
      prefixIcon,
      helperText,
      type = 'text',
      textAlign = 'left',
      onChanged,
}: CustomInputFieldProps) => {
      const { t } = useTranslation();
      return (
            <div className='flex items-center gap-3 justify-between bg-white shadow-primary rounded-2xl px-4 w-full '>
                  {prefixIcon}
                  <input
                        type={type}
                        value={value}
                        required={required}
                        className='w-full h-14 rounded-2xl outline-none xl:px-2 px-1 xl:text-base lg:text-sm text-xs  text-gray-900 font-medium'
                        placeholder={t(`${helperText}`)}
                        onChange={onChanged}
                  />
                  {suffixIcon}
            </div>
      );
};

export default CustomInputField;
