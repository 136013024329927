export const Last15DaysIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 31 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0215 0H7.91317C6.47678 0 5.31175 1.02368 5.31175 2.28615V4.5723H2.60142C1.16503 4.5723 0 5.73734 0 7.17372V9.972H31V7.17372C31 5.73734 29.884 4.5723 28.5075 4.5723H26.0144C26.0144 4.5723 26.0144 3.54862 26.0144 2.28615C26.0144 1.02368 24.8493 0 23.4129 0H23.3045C21.8682 0 20.7031 1.02368 20.7031 2.28615V4.5723H10.6229C10.6229 4.5723 10.6229 3.54862 10.6229 2.28615C10.6229 1.02368 9.45788 0 8.0215 0Z"
        fill="#7573FF"
      />
      <path
        d="M0 29.8905C0 31.2976 1.14079 32.4384 2.5479 32.4384H28.452C29.8591 32.4384 30.9999 31.2976 30.9999 29.8905V11.8901H0V29.8905ZM24.418 26.1826H28.0278V29.7924H24.418V26.1826ZM21.021 29.7924H17.4112V26.1826H21.021V29.7924ZM16.8609 19.2339C16.4245 19.2339 16.0271 19.353 15.6997 19.4919L14.8168 18.9066L15.015 15.4828H19.2815V16.8223H16.3239L16.245 18.0231C16.5227 17.9536 16.7705 17.904 17.1578 17.904C18.5273 17.904 19.5892 18.5587 19.5892 20.1366C19.5892 21.615 18.5471 22.5482 16.9489 22.5482C15.8379 22.5482 15.0246 22.1513 14.3493 21.5061L15.2717 20.4048C15.7979 20.8812 16.3138 21.159 16.9298 21.159C17.6438 21.159 18.0903 20.8119 18.0903 20.1864C18.0909 19.5816 17.6049 19.2339 16.8609 19.2339ZM14.0143 29.7918H10.4044V26.1821H14.0141L14.0143 29.7918ZM11.9669 15.4336H13.009V22.4295H11.5006V16.9815L10.2903 17.2795L9.97317 16.0292L11.9669 15.4336ZM3.39766 26.1814H7.00742V29.7911H3.39766V26.1814Z"
        fill="#7573FF"
      />
    </svg>
  );
};
