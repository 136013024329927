import { SetStateAction, useState } from "react"
import CustomImage from "../../../../components/customImage"
import { PrimaryText, TertiaryText, Text12 } from "../../../../components/textTitles"
import { FlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel"
import { DotsIcon } from "../../../../svgs/dotsIcon"
import AddToLibraryBox from "./addToLibraryBox"

const RevisionFlashFront = ({ setCardFlip, cardData }: {
    cardData: FlashDetailsModel
    setCardFlip: React.Dispatch<SetStateAction<boolean>>,
}) => {

    const [showBox, setShowBox] = useState(false)

    return (
        <div className="relative h-full xl:w-[600px] w-[520px] rounded-3xl p-6 shadow-primary flex flex-col justify-start gap-5 items-center">
            <section className="h-full w-full flex flex-col justify-start gap-5 items-center" onClick={() => { setCardFlip(true) }}>
                {/* <CustomImage
                    image={cardData.image ?? ""}
                    alt="image"
                    height="h-[250px]"
                    width="w-[400px]"
                    borderRadius='rounded-3xl'
                /> */}
                <img src={cardData.image ?? ""} alt={"image"} className='rounded-3xl object-cover h-[250px] w-[400px]' />
                <PrimaryText title={cardData.front.text} color="text-bluePrimary"></PrimaryText>
                <TertiaryText title={cardData.front.example} ></TertiaryText>
                <div className="absolute bottom-4 right-4">
                    <Text12 title="Tap to flip the card" fontWeight="font-semibold"></Text12>
                </div>
            </section>
            <button className="absolute top-4 right-3 p-3" onClick={() => { setShowBox(true) }}>
                <DotsIcon />
            </button>
            {
                showBox &&
                <AddToLibraryBox cardData={cardData} handleClose={setShowBox} />
            }
        </div>
    )
}

export default RevisionFlashFront