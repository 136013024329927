import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { endpoints } from '../../../../core/api/apisPath';
import { DAxios } from '../../../../core/api/axiosConfig';
import { UserRoleEnum } from '../../../../core/constants/enums';
import { DRoutes } from '../../../../core/routes';
import { setUser } from '../../../../core/store/slice/auth.slice';
import { useAppDispatch } from '../../../../core/store/store';
import { Prefs } from '../../../../core/utils/prefs';
import { DToast } from '../../../../core/utils/toasts';
import { convertToUserModel } from '../models/admin.user.model';
import LoginFormModel from '../models/login.form.model';

const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: async (loginForm: LoginFormModel) => {
      return await DAxios.post({
        endpoint: endpoints.login,
        body: {
          email: loginForm.email,
          password: loginForm.password,
          role: UserRoleEnum.STUDENT,
        },
      });
    },

    onError(error: Error) {
      new DToast().showErrorToast(error.message);
    },

    onSuccess(data) {
      const response = convertToUserModel(data);
      Prefs.setToken({ token: data.user.token.toString() });
      dispatch(setUser(response));
      navigate(DRoutes.dashboard);
    },
  });
};

export default useLogin;
