import { useTranslation } from 'react-i18next';
import { Header } from '../../../../components/Header';
import BundlesList from '../components/BundlesList';

const Bundle = () => {
      const { t } = useTranslation();

      return (
            <div className='w-full h-full flex flex-col gap-10'>
                  <Header
                        titleOne={t('Bundles')}
                        titleTwo={t('')}
                  />
                  <section className='w-full h-full flex justify-center'>
                        <BundlesList />
                  </section>
            </div>
      );
};

export default Bundle;
