import { useTranslation } from "react-i18next";
import { Text12 } from "../../../../../components/textTitles";

const GradesSection = ({ onGradeChanged }: { onGradeChanged: (grade: number) => void; }) => {

    const { t } = useTranslation();

    const grades = [
        { id: 1, grade: t("Forgot"), color: "bg-[#FF0000]" },
        { id: 2, grade: t("Difficult"), color: "bg-[#E46000]" },
        { id: 3, grade: t("Medium"), color: "bg-[#FFA800]" },
        { id: 4, grade: t("Easy"), color: "bg-[#C0E000]" },
        { id: 5, grade: t("Very easy"), color: "bg-[#43D300]" },
    ];

    return (
        <div className="flex flex-row gap-4 items-center">
            {
                grades.map((grade) =>
                    <button key={grade.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            onGradeChanged(grade.id);
                        }}
                        className={`${grade.color} px-4 py-2.5 rounded-lg shadow-sm text-sm font-bold text-gray-900 hover:opacity-90 transition-all`}>
                        <Text12 title={grade.grade} fontWeight="font-semibold" />
                    </button>
                )
            }
        </div>
    )
}

export default GradesSection