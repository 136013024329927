import { CreateFlashButton } from "./createFlashButton";
import { LibraryQuickRevision } from "./LibraryQuickRevision";
import { LibrarySearchBar } from "./LibrarySearchBar";
import { LibraryStarredFilter } from "./LibraryStarredFilter";

interface LibraryHeaderProps {
  handleSearch: (search: string) => void;
  handleFilterByStarred: (e: boolean) => void;
}

export const LibraryHeader = ({
  handleSearch,
  handleFilterByStarred,
}: LibraryHeaderProps) => {
  return (
    <div className="flex flex-wrap gap-5 items-center">
      <LibrarySearchBar handleSearch={handleSearch} />
      <CreateFlashButton />
      <LibraryQuickRevision />
      <LibraryStarredFilter handleFilterByStarred={handleFilterByStarred} />
    </div>
  );
};
