export const NotificationIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4836 27.8325C12.6401 29.3027 14.457 30.25 16.4999 30.25C18.5429 30.25 20.3597 29.3027 21.5162 27.8325C18.1862 28.2838 14.8136 28.2838 11.4836 27.8325Z"
        fill="#7573FF"
      />
      <path
        d="M25.78 12.375V13.3431C25.78 14.505 26.1117 15.6409 26.733 16.6075L28.2557 18.9766C29.6466 21.1405 28.5849 24.0816 26.1657 24.7659C19.8375 26.5559 13.1625 26.5559 6.83425 24.7659C4.41519 24.0816 3.35338 21.1405 4.74425 18.9766L6.26698 16.6075C6.88837 15.6409 7.21995 14.505 7.21995 13.3431V12.375C7.21995 7.05926 11.3748 2.75 16.5 2.75C21.6252 2.75 25.78 7.05926 25.78 12.375Z"
        fill="#7573FF"
      />
    </svg>
  );
};
