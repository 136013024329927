import axios from "axios"
import { BundleActions, BundleActionTypes } from "../../presentation/reducer/bundleReducer"
import { getToken } from "../../../../../core/utils/functions"
import { getSubBundlesApi } from "../../../../../core/api/apisPath"
import { convertToSubBundleModel, SubBundleModel } from "../models/subBundleModel"

export const fetchSubBundles = async (bundleId: string, dispatch: React.Dispatch<BundleActions>) => {
    try {
        dispatch({ type: BundleActionTypes.BUNDLE_LOADING })
        const token = getToken()
        const response = await axios.get(getSubBundlesApi, {
            headers: {
                Authorization: "Bearer " + token
            },
            params: {
                "bundleId": bundleId
            }
        })
        const data: SubBundleModel[] = (response.data.subBundles.subBundles as []).map((element) => convertToSubBundleModel(element))
        dispatch({ type: BundleActionTypes.SUBBUNDLE_DATA_SUCCESS, subBundlesPayload: data })
    } catch (error: any) {
        dispatch({ type: BundleActionTypes.BUNDLE_DATA_FAILURE, error: error.message })
    }
}