export const CalculatorIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 21 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00012 9.33333H20.0001M14.5716 21.8333V17.6667M6.42869 21.8333H6.44227M10.5001 21.8333H10.5137M10.5001 17.6667H10.5137M6.42869 17.6667H6.44227M14.5716 13.5H14.5851M10.5001 13.5H10.5137M6.42869 13.5H6.44227M5.34298 26H15.6573C17.1774 26 17.9375 26 18.5181 25.6972C19.0288 25.431 19.4441 25.006 19.7043 24.4833C20.0001 23.8892 20.0001 23.1112 20.0001 21.5556V5.44444C20.0001 3.88875 20.0001 3.11089 19.7043 2.51669C19.4441 1.99401 19.0288 1.56907 18.5181 1.30276C17.9375 1 17.1774 1 15.6573 1H5.34298C3.82284 1 3.06276 1 2.48215 1.30276C1.97142 1.56907 1.55618 1.99401 1.29597 2.51669C1.00012 3.11089 1.00012 3.88874 1.00012 5.44444V21.5556C1.00012 23.1112 1.00012 23.8892 1.29597 24.4833C1.55618 25.006 1.97142 25.431 2.48215 25.6972C3.06276 26 3.82283 26 5.34298 26Z"
        stroke="#7573FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
