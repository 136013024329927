import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { BackCard } from './components/BackCard';
import { FrontCard } from './components/FrontCard';
import { useGetCardsRevision } from './data/services/useGetCardsRevision.service';
import LoadingEmptyContainer from '../../core/components/LoadingEmptyContainer';
import { useHandleGrade } from './data/services/useHandleGrade.service';
import { useUpdateFlashCard } from './data/services/useUpdateCard.service';
import { HelperFunction } from '../../core/utils/functions';
import ConfirmationCard from '../admin/bundle/presentation/components/subBundle/subBundleFlashCards/confirmationCard';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

const ReviseCards = () => {
      const [isFlipped, setIsFlipped] = useState<boolean>(false);
      const category = HelperFunction.getUrlQueryParameter('category');
      const tag = HelperFunction.getUrlQueryParameter('tag');
      const { data: cards, isPending, isRefetching, error } = useGetCardsRevision({ category, tag });
      const [index, setIndex] = useState(0);
      const { mutate: handleGrade, isPending: gradePending } = useHandleGrade(updateIndex);
      const { mutate: updateFlash } = useUpdateFlashCard();
      const [showCompletionDialog, setShowCompletionDialog] = useState(false);
      const navigate = useNavigate();

      return (
            <LoadingEmptyContainer
                  loading={isPending || isRefetching}
                  error={error}
                  empty={cards === undefined || cards.length === 0}
                  className='flex flex-col justify-center items-center'
            >
                  {cards !== undefined && cards.length > 0 && (
                        <div className='relative'>
                              <ReactCardFlip
                                    isFlipped={isFlipped}
                                    flipDirection='horizontal'
                              >
                                    <FrontCard
                                          key='front'
                                          card={cards[index]}
                                          setIsFlipped={setIsFlipped}
                                          handleUpdateStarred={(val) => {
                                                updateFlash({
                                                      card: cards[index],
                                                      cardId: cards[index]._id,
                                                      isStarred: val,
                                                });
                                          }}
                                    />
                                    <BackCard
                                          key='back'
                                          card={cards[index]}
                                          setIsFlipped={setIsFlipped}
                                          handleUpdateStarred={(val) => {
                                                updateFlash({
                                                      card: cards[index],
                                                      cardId: cards[index]._id,
                                                      isStarred: val,
                                                });
                                          }}
                                          handleGrade={(grade) => {
                                                handleGrade({ cardId: cards[index]._id, grade });
                                          }}
                                    />
                              </ReactCardFlip>
                              {gradePending && (
                                    <div className='absolute top-0 left-0 xl:w-[600px] w-[520px] h-[550px] rounded-[3rem] flex justify-center items-center bg-blackSecondary opacity-75'>
                                          <BeatLoader color='white' />
                                    </div>
                              )}
                        </div>
                  )}
                  {showCompletionDialog && (
                        <ConfirmationCard
                              text='Do you want to revise again?'
                              confirmText='Yes'
                              onNoClick={() => {
                                    setShowCompletionDialog(false);
                                    navigate(-1);
                              }}
                              onYesClick={() => {
                                    setShowCompletionDialog(false);
                                    setIsFlipped((prev) => !prev);
                                    setIndex(0);
                              }}
                        />
                  )}
            </LoadingEmptyContainer>
      );

      function updateIndex() {
            if (index < cards!.length - 1) {
                  setIsFlipped((prev) => !prev);
                  setIndex((prev) => prev + 1);
            } else {
                  setShowCompletionDialog(true);
            }
      }
};

export default ReviseCards;
