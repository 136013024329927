import axios from "axios"
import { RevisionActions, RevisionActionTypes } from "../../presentation/reducer/revisionReducer"
import { getCardsForRevision } from "../../../../core/api/apisPath"
import { getToken } from "../../../../core/utils/functions"
import { convertToFlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel"

export const fetchRevisionFlash = async (dispatch: React.Dispatch<RevisionActions>, subBundleId?: string, userId?: string) => {
    try {
        dispatch({ type: RevisionActionTypes.REVISION_LOADING })
        const token = getToken()
        const response = await axios.get(getCardsForRevision,
            {
                headers: {
                    Authorization: "Bearer " + token
                },
                params: {
                    "subBundleId": subBundleId,
                    "userId": userId
                }
            }
        )
        const data = (response.data.cards as []).map((data) => convertToFlashDetailsModel(data))
        dispatch({ type: RevisionActionTypes.REVISION_DATA_SUCCESS, revisionPayload: data })

    } catch (error: any) {
        dispatch({ type: RevisionActionTypes.REVISION_DATA_FAILURE, error: error.message })

    }
}

export default fetchRevisionFlash