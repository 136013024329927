import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../../core/constants/enums';
import { DAxios } from '../../../../core/api/axiosConfig';
import { endpoints } from '../../../../core/api/apisPath';
import { CourseSectionResponseModel } from '../models/courseSectionModel';

export const useGetCourseSection = ({ courseId }: { courseId: string }) => {
    return useQuery({
        queryKey: [QueryKey.COURSESECTION, courseId],
        queryFn: async () => {
            const response = await DAxios.get({
                endpoint: endpoints.getCourseSection + courseId,
            });
            const sections = CourseSectionResponseModel.convertToSectionResponseModel(
                response.response
            );
            return sections
        },
        refetchInterval: false,
    });
};
