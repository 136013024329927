export const ResetIcon = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3321 8.10388C16.1176 7.59462 15.8553 7.10715 15.5489 6.64808L13.9032 7.76693C14.3775 8.47716 14.7102 9.27348 14.8829 10.1116C15.0588 10.9853 15.0588 11.8856 14.8829 12.7592C14.7967 13.1812 14.669 13.5935 14.5017 13.9901C14.3391 14.3797 14.1386 14.7521 13.9032 15.1019C13.4327 15.8008 12.8344 16.4031 12.1403 16.8767C11.4358 17.3542 10.6459 17.6894 9.81459 17.8635C8.94772 18.0385 8.055 18.0385 7.18813 17.8635C5.94048 17.6053 4.7956 16.9837 3.89564 16.0758C3.30116 15.4766 2.82667 14.7678 2.49804 13.9881C2.33222 13.5915 2.20523 13.1796 2.11886 12.7582C1.85683 11.457 1.98893 10.1065 2.49804 8.88178C2.82541 8.10217 3.29969 7.39387 3.89464 6.79606C4.48933 6.19825 5.19219 5.72072 5.96523 5.38926C6.35732 5.22228 6.76925 5.0933 7.18615 5.00731C7.29236 4.98531 7.40055 4.97531 7.50775 4.95832V7.9989L12.4708 3.99945L7.50775 0V2.9376C7.26645 2.96451 7.02636 3.00153 6.78811 3.04858C5.70036 3.27311 4.66721 3.7114 3.74774 4.3384C2.11438 5.44827 0.914357 7.09507 0.352747 8.99737C-0.208863 10.8997 -0.0972002 12.9394 0.668653 14.768C1.09502 15.7861 1.71362 16.7111 2.4901 17.4916C3.26552 18.2712 4.18245 18.8934 5.191 19.3243C6.77653 20.0001 8.52859 20.1741 10.2146 19.8233C11.5642 19.5423 12.8259 18.9355 13.8913 18.0549C14.9567 17.1743 15.794 16.0464 16.3311 14.768C16.5495 14.248 16.7172 13.7071 16.8274 13.1602C17.0575 12.022 17.0575 10.8488 16.8274 9.71066C16.7143 9.16018 16.5484 8.62206 16.3321 8.10388Z"
        fill="#7573FF"
      />
    </svg>
  );
};
