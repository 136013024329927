import { getToken } from "../../../../core/utils/functions";
import { BundleActions, BundleActionTypes } from "../../../admin/bundle/presentation/reducer/bundleReducer";
import { enrollCard } from "../../../../core/api/apisPath";
import axios from "axios";

export const enrollSubBundle = async (dispatch: React.Dispatch<BundleActions>, subBundleId: string) => {
    try {
        dispatch({ type: BundleActionTypes.BUNDLE_LOADING })
        const token = getToken()
        const response = await axios.get(enrollCard + subBundleId,
            {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        )
        dispatch({ type: BundleActionTypes.BUNDLE_CUD_SUCCESS, cudPayload: true })

    } catch (error: any) {
        dispatch({ type: BundleActionTypes.BUNDLE_DATA_FAILURE, error: error.message })
    }
}