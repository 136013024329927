import PrimaryButton from '../../../../components/buttons/primaryButton';
import TertiaryButton from '../../../../components/buttons/tertiaryButton';
import CustomImage from '../../../../components/customImage';
import { Header } from '../../../../components/Header';
import { TertiaryText, Text16, Text12 } from '../../../../components/textTitles';
import { endpoints } from '../../../../core/api/apisPath';
import { useAppSelector } from '../../../../core/store/store';
import { HelperFunction } from '../../../../core/utils/functions';
import DollarIcon from '../../../../svgs/DollarIcon';
import { useGetCourse } from '../../../course/data/services/getCourse.service';
import { CgFileDocument } from 'react-icons/cg';
import { CiDiscount1 } from 'react-icons/ci';
import { useCheckCourseEnrollment } from '../../../course/data/services/checkCourseEnrolled.service';

const Payment = () => {
      const courseId = HelperFunction.getUrlQueryParameter('courseId');
      const { data: course } = useGetCourse(courseId);
      const { data: user } = useAppSelector((state) => state.user);
      const { data: isEnrolledSuccess } = useCheckCourseEnrollment({ courseId });

      return (
            <>
                  <Header
                        titleOne='Payment'
                        titleTwo=''
                  />
                  <div className='flex mt-6 overflow-y-auto mb-8'>
                        <section className='flex-1'>
                              <section className='w-full flex flex-col gap-3'>
                                    <TertiaryText title={course?.courses[0].name ?? ''} />
                                    <CustomImage
                                          image={course?.courses[0].image ?? ''}
                                          alt='alphabets'
                                          className='aspect-video object-cover rounded-2xl'
                                    />
                                    <div className='flex items-center gap-4'>
                                          <DollarIcon />
                                          <TertiaryText
                                                title={`Kr ${course?.courses[0].price}`}
                                                fontWeight='font-bold'
                                                className='mt-2'
                                          />
                                    </div>
                                    <section className='mt-2 flex flex-col gap-3'>
                                          <Text16
                                                title='About Course'
                                                fontWeight='font-semibold'
                                          />
                                          <Text12 title={course?.courses[0].desc ?? ''} />
                                    </section>
                              </section>
                        </section>
                        <div className='w-6 h-[80vh] border-r-2 border-gray-300 mx-2'>&nbsp;</div>
                        <form
                              className='flex-1 ml-6 flex flex-col justify-between'
                              action={`${process.env.REACT_APP_BASE_URL}${endpoints.payment}${courseId}/${user?.userId}`}
                              method='post'
                        >
                              <div>
                                    <TertiaryText title='Order Summary' />
                                    <div className='border-2 rounded-md p-4 mt-8 flex flex-col gap-3'>
                                          {priceTile(
                                                'Item Total',
                                                `Kr ${course?.courses[0].price}`,
                                                <CgFileDocument color='#6B7280' />
                                          )}
                                          {priceTile('Discount', `N/A`, <CiDiscount1 />)}
                                          <hr />
                                          {priceTile('Total', `Kr ${course?.courses[0].price}`)}
                                    </div>
                                    <div className='flex items-center gap-2 mt-4'>
                                          <input
                                                type='text'
                                                placeholder='Enter coupon code'
                                                className='w-full border h-9 px-2 xl:text-sm lg:text-xs text-xs font-semibold'
                                          />
                                          <TertiaryButton
                                                text={'Apply'}
                                                onClick={() => {}}
                                          />
                                    </div>
                              </div>
                              <PrimaryButton
                                    text={'Pay'}
                                    disabled={isEnrolledSuccess ? true : false}
                                    onClick={() => {}}
                              />
                        </form>
                  </div>
            </>
      );

      function priceTile(title: string, value: string | number, icon?: any) {
            return (
                  <div className='flex justify-between items-center'>
                        <div className='flex gap-1 items-center'>
                              {icon}
                              <label className='xl:text-sm lg:text-xs text-xs font-semibold text-gray-500'>
                                    {title}
                              </label>
                        </div>
                        <label className='xl:text-sm lg:text-xs text-xs font-semibold text-blackPrimary'>{value}</label>
                  </div>
            );
      }

};

export default Payment;
