import { useState } from 'react';
import { Last15DaysIcon } from '../../../svgs/last15DaysIcon';
import { Last30DaysIcon } from '../../../svgs/last30DaysIcon';
import { LastWeekIcon } from '../../../svgs/lastWeek';
import { ReviseIcon } from '../../../svgs/reviseIcon';
import { TodayIcon } from '../../../svgs/todayIcon';
import { useTranslation } from 'react-i18next';
import { Text16 } from '../../../components/textTitles';
import { useNavigate } from 'react-router-dom';
import { DRoutes } from '../../../core/routes';
import { useAppSelector } from '../../../core/store/store';

export const RevisionDashboard = () => {
      const { t } = useTranslation();
      const [showDropdown, setShowDropdown] = useState<boolean>(false);
      const navigate = useNavigate();
      const { data } = useAppSelector((state) => state.user);
     

      const categories = [
            {
                  label: t('Revise All'),
                  query: 'all',
                  icon: <ReviseIcon />,
            },
            {
                  label: t('Revise by Category'),
                  query: 'tag',
                  icon: <ReviseIcon />,
            },
            {
                  label: t('Today'),
                  query: 'today',
                  icon: <TodayIcon />,
            },
            {
                  label: t('Last Week'),
                  query: 'last-week',
                  icon: <LastWeekIcon />,
            },
            {
                  label: t('Last 15 Days'),
                  query: 'last-15-days',
                  icon: <Last15DaysIcon />,
            },
            {
                  label: t('Last 30 Days'),
                  query: 'last-30-days',
                  icon: <Last30DaysIcon />,
            },
      ];

      return (
            <div className='flex flex-col flex-1 gap-10 bg-white w-full shadow-[0px_4px_31.100000381469727px_0px_#00000040] rounded-[2rem] xl:p-6 p-5'>
                  <div className='grid grid-cols-3 xl:gap-4 gap-3'>
                        {categories.map((category, index) => (
                              <div className='flex flex-col'>
                                    <button
                                          key={index}
                                          className='flex flex-row items-center gap-4 bg-white px-6 xl:py-5 py-3.5 shadow-[1px_4px_14.5px_0px_rgba(0,_0,_0,_0.25)] hover:shadow-[1px_4px_12.5px_0px_rgba(0,_0,_0,_0.25)] transition-colors duration-300 rounded-2xl'
                                          onClick={() => {
                                                if (
                                                      category.label === 'Revise by Category' ||
                                                      category.label === 'Revider etter kategori'
                                                ) {
                                                      setShowDropdown((prev) => !prev);
                                                } else {
                                                      navigate(DRoutes.reviseUserCards + '?category=' + category.query);
                                                }
                                          }}
                                    >
                                          {category.icon}
                                          <Text16
                                                title={category.label}
                                                fontWeight='font-semibold'
                                          />
                                    </button>
                                    {showDropdown &&
                                          (category.label === 'Revise by Category' ||
                                                category.label === 'Revider etter kategori') && (
                                                <div
                                                      className='flex flex-col gap-2 absolute top-[240px] left-[532px] bg-white w-80 border-bluePrimary transition-colors duration-300 rounded-2xl shadow-sm border-[1.5px]'
                                                      onClick={() => setShowDropdown(false)}
                                                >
                                                      {data?.tags?.map((tag, index) => (
                                                            <button
                                                                  key={index}
                                                                  className='text-lg text-center font-semibold text-gray-900 hover:bg-bluePrimary py-2 px-5 rounded-xl hover:text-white'
                                                                  onClick={() => {
                                                                        navigate(
                                                                              DRoutes.reviseUserCards +
                                                                                    '?category=' +
                                                                                    category.query +
                                                                                    '&tag=' +
                                                                                    tag
                                                                        );
                                                                  }}
                                                            >
                                                                  {tag}
                                                            </button>
                                                      ))}
                                                </div>
                                          )}
                              </div>
                        ))}
                  </div>
            </div>
      );
};
