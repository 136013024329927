import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Text12, Text14 } from '../../../../../components/textTitles';
import { ListeningQuestionBody } from '../../../data/models/assignmentModel';
import QuestionBody from './QuestionBody';
import { useEffect, useState } from 'react';

const ListeningQuestion = ({
      question,
      savedAnswer,
      onSaveAnswer,
}: {
      question: ListeningQuestionBody;
      savedAnswer: File;
      onSaveAnswer: (answer: File) => void;
}) => {
      const recorderControls = useAudioRecorder();
      const [audio, setAudio] = useState<
            | {
                    file: File;
                    blob?: Blob;
              }
            | undefined
      >();

      useEffect(() => {
            if (savedAnswer) {
                  setAudio({ file: savedAnswer });
            }
      }, [savedAnswer]);

      return (
            <div className='flex flex-col w-full gap-4'>
                  <QuestionBody question={question.question_body} />
                  {question.questionMedia && renderPreviewMedia()}
                  <div className='mt-6'>
                        <Text12 title='Note : Record your voice by clicking on the mic button' />
                  </div>
                  <div className='flex items-center gap-3 mt-6  justify-between'>
                        <div className=' flex gap-3'>
                              <div
                                    onClick={() => {
                                          setAudio(undefined);
                                    }}
                              >
                                    <AudioRecorder
                                          onRecordingComplete={(blob) => {
                                                const file = new File([blob], 'audio-recording.wav', {
                                                      type: 'audio/wav',
                                                });
                                                setAudio({ file, blob });
                                                onSaveAnswer(file);
                                          }}
                                          recorderControls={recorderControls}
                                    />
                              </div>
                              {audio && (
                                    <audio
                                          src={audio.blob && URL.createObjectURL(audio.blob)}
                                          controls
                                    />
                              )}
                        </div>
                        <button
                              className='hover:cursor-pointer'
                              onClick={() => {
                                    const input = document.createElement('input');
                                    input.type = 'file';
                                    input.accept = 'audio/*';
                                    input.click();
                                    input.onchange = async () => {
                                          if (input.files?.[0]) {
                                                setAudio({ file: input.files[0] });
                                                // setAudioURL(URL.createObjectURL(input.files[0]));
                                          }
                                    };
                              }}
                        >
                              {/* <RectangularAddIcon /> */}
                        </button>
                  </div>
            </div>
      );
      function renderPreviewMedia() {
            if (!question.mediaType) return;

            if (question.mediaType === 'VIDEO') {
                  return (
                        <video
                              src={question.questionMedia}
                              controls
                              className='w-full aspect-video mt-2'
                        />
                  );
            } else if (question.mediaType === 'AUDIO') {
                  return (
                        <audio
                              src={question.questionMedia}
                              controls
                              className='w-3/4 mt-2'
                        />
                  );
            } else if (question.mediaType === 'IMAGE') {
                  return (
                        <img
                              src={question.questionMedia}
                              alt='Selected'
                              className='w-1/3 mt-2'
                        />
                  );
            } else {
                  return (
                        <Text14
                              title='Unsupported file type'
                              fontWeight='font-medium'
                        />
                  );
            }
      }
};

export default ListeningQuestion;
