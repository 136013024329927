import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '../../components/auth/authHeader';
import RegisterButton from './components/registerButton';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../core/store/store';
import { setUser } from '../../core/store/slice/auth.slice';
import { convertToUserModel } from '../auth/data/models/admin.user.model';
import { TertiaryText } from '../../components/textTitles';
import { Prefs } from '../../core/utils/prefs';

const Register = () => {
      const [nativeLanguage, setNativeLanguage] = useState<string>('english');
      const navigate = useNavigate();
      const { data } = useAppSelector((state) => state.user);
      const dispatch = useAppDispatch();

      useEffect(() => {
            initialSetup();
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      async function initialSetup() {
            const url = window.location.href;
            const urlParams = new URL(url);
            const userData = urlParams.searchParams.get('user');

            if (userData) {
                  try {
                        const user = JSON.parse(userData);

                        Prefs.setToken({ token: user.token.toString() });
                        const user1 = convertToUserModel({ user });

                        dispatch(setUser(user1));
                        localStorage.setItem('user', JSON.stringify(user));
                        if (user1.currentNorwegianSkill) {
                              navigate('/dashboard');
                        }
                  } catch (error) {}
            }
      }

      const updateAccount = async (norwegianLevel: string) => {
            try {
                  await axios.post(`${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/updateNorwegianLevel`, {
                        userId: data?.userId,
                        currentNorwegianSkill: norwegianLevel,
                        nativeLanguage: nativeLanguage,
                  });
                  navigate('/dashboard');
            } catch (error: any) {}
      };

      return (
            <div className='bg-white w-full h-[100vh]'>
                  <div className='flex flex-col gap-8 items-center justify-center h-full'>
                        <AuthHeader />
                        <div className='flex flex-col xl:w-[480px] w-[380px] xl:h-[500px] h-[400px] bg-[#F9F9F9] rounded-[3rem] shadow-[8px_8px_22.7px_6px_rgba(0,_0,_0,_0.25)] py-6'>
                              <div className='flex flex-col items-center justify-center pt-4'>
                                    <TertiaryText title='What is your current' />
                                    <TertiaryText title='norwegian level?' />
                              </div>
                              <div className='flex flex-col items-center justify-center'>
                                    <RegisterButton
                                          label='Beginner'
                                          onClick={() => {
                                                updateAccount('beginner');
                                          }}
                                    />
                                    <RegisterButton
                                          label='Intermediate'
                                          onClick={() => {
                                                updateAccount('intermediate');
                                          }}
                                    />
                                    <RegisterButton
                                          label='Advanced'
                                          onClick={() => {
                                                updateAccount('advanced');
                                          }}
                                    />
                              </div>
                              <div className='flex flex-col items-center justify-center mt-auto xl:mb-10 mb-2'>
                                    <div className='flex gap-1.5 items-center justify-center'>
                                          <h2 className='text-gray-700 font-semibold text-sm'>My Native Language:</h2>
                                          <select
                                                className='text-bluePrimary placeholder-bluePrimary outline-none text-sm font-semibold bg-transparent
                -500'
                                                onChange={(e) => setNativeLanguage(e.target.value)}
                                          >
                                                <option value='english'>English</option>
                                                <option value='norwegian'>Norwegian</option>
                                          </select>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default Register;
