import { SetStateAction } from 'react'
import { SecondaryText, Text16 } from '../../../../../../../components/textTitles'

const NoteCard = ({ noteData, setOpenNote, title }: { title?: string, noteData: string, setOpenNote: React.Dispatch<SetStateAction<boolean>> }) => {
    return (
        <div className='fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-25 flex justify-center items-center'>
            <div className='bg-white relative flex flex-col w-[500px] min-h-[300px] gap-3 items-start rounded-3xl p-8'>
                <SecondaryText title={title ?? 'Note'} color='text-bluePrimary'></SecondaryText>
                <Text16 title={noteData}></Text16>
                <button onClick={() => { setOpenNote(false) }} className="absolute top-[-12px] right-[-12px] w-[50px] h-[50px] bg-white rounded-[100px] flex justify-center items-center shadow-primary">&#x2715;</button>
            </div>
        </div>
    )
}

export default NoteCard