import { useMutation, useQueryClient } from "@tanstack/react-query"
import { DAxios } from "../../../../core/api/axiosConfig"
import { endpoints } from "../../../../core/api/apisPath"
import { DToast } from "../../../../core/utils/toasts"
import { QueryKey } from "../../../../core/constants/enums"
import { FlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel"

export const useDeleteCard = (setDialogRender: React.Dispatch<React.SetStateAction<boolean>>) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (cardId?: string) => {
            if (!cardId) throw new Error('Card deletion failed')
            const response = await DAxios.delete({
                endpoint: endpoints.deleteFlashCard,
                params: {
                    cardId
                }
            })
            return response
        },
        onSuccess: (data, variables) => {
            queryClient.setQueryData(
                [QueryKey.FLASHCARD],
                (prev: FlashDetailsModel[]) => {
                    return prev?.filter((element) => element._id !== variables)
                }
            )
            setDialogRender(false)
            new DToast().showSuccessToast('Card deleted successfully')
        },
        onError: (error: Error) => {
            new DToast().showErrorToast("Card deletion failed")
        }

    })
}