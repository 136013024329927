import axios, { AxiosRequestConfig } from 'axios';
import { Prefs } from '../utils/prefs';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const token = Prefs.getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
api.interceptors.response.use(
    (response) => {
        if (response.data.code === false) {
            throw Error(response.data.message);
        } else {
            return response;
        }
    },
    (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response Error:', error.response.data);
            console.error('Response Status:', error.response.status);
            throw new Error(error.response.data.error || 'An error occurred');
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from server:', error.request);
            throw new Error('No response received from server');
        } else {
            // Something happened in setting up the request that triggered an error
            console.error('Error setting up request:', error.message);
            throw new Error('Error setting up request');
        }
    }
);

export class DAxios {
    static async get({
        endpoint,
        params,
    }: {
        endpoint: string;
        params?: object;
    }) {
        const response = await api.get(endpoint, { params });
        return response.data;
    }

    static async post({
        endpoint,
        body,
        config,
    }: {
        endpoint: string;
        body: object;
        config?: AxiosRequestConfig;
    }) {
        const response = await api.post(endpoint, body, { ...config });
        return response.data;
    }

    static async put({
        endpoint,
        body,
        params,
    }: {
        endpoint: string;
        body?: object;
        params?: object;
    }) {
        const response = await api.put(endpoint, body, { params });
        return response.data;
    }

    static async patch({
        endpoint,
        body,
        params,
    }: {
        endpoint: string;
        body: object;
        params?: object;
    }) {
        const response = await api.patch(endpoint, body, { params });
        return response.data;
    }

    static async delete({
        endpoint,
        params,
    }: {
        endpoint: string;
        params?: object;
    }) {
        const response = await api.delete(endpoint, { data: params });
        return response.data;
    }

}

