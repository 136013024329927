export const ChevronIconLeft = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19"
        cy="19"
        r="18"
        fill="white"
        stroke="#7573FF"
        stroke-width="2"
      />
      <path
        d="M23.3476 11.0343L23.3489 11.033C23.5217 10.8568 23.5767 10.6386 23.5859 10.4633C23.595 10.2888 23.562 10.1181 23.5175 9.9731C23.4308 9.69046 23.2662 9.39709 23.0797 9.20684C22.8624 8.9846 22.5707 8.8689 22.2812 8.8689C22.2829 8.8689 22.2839 8.86891 22.2839 8.86884C22.2839 8.86859 22.2666 8.86709 22.2147 8.85839C22.2059 8.85692 22.196 8.85522 22.1852 8.85336C22.1376 8.84521 22.0726 8.83406 22.0106 8.82636C21.9352 8.817 21.829 8.80762 21.7184 8.81971C21.6135 8.83116 21.4339 8.86935 21.2873 9.01877L21.2872 9.01869L21.2797 9.02667L12.7257 18.1379C12.2975 18.5793 12.2977 19.2892 12.7264 19.7303L21.0414 28.5265L21.0413 28.5266L21.0478 28.5332C21.1889 28.6771 21.3664 28.7569 21.518 28.8018C21.6754 28.8484 21.8432 28.8689 22.003 28.8689C22.1627 28.8689 22.3305 28.8484 22.4879 28.8018C22.6395 28.7569 22.817 28.6771 22.9581 28.5332C23.1806 28.3064 23.3006 27.9675 23.3396 27.6806C23.3758 27.4141 23.3678 26.99 23.0821 26.696L15.602 18.8746L23.3476 11.0343Z"
        fill="#7573FF"
        stroke="#7573FF"
      />
    </svg>
  );
};
