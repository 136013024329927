export const GoogleIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.2532 21.9871C39.2532 20.745 39.1515 19.4976 38.9375 18.2764H21.6102V25.3091H31.5316C31.3269 26.4345 30.896 27.5067 30.2649 28.4607C29.6338 29.4148 28.8157 30.2309 27.86 30.8596V35.4262H33.781C37.2569 32.2426 39.2532 27.5454 39.2532 21.9871Z"
        fill="#4285F4"
      />
      <path
        d="M21.6128 39.8336C26.5657 39.8336 30.7435 38.2157 33.7863 35.4235L27.8678 30.8594C26.2212 31.9737 24.0944 32.6052 21.618 32.6052C16.8269 32.6052 12.7638 29.3902 11.3051 25.0688H5.19879V29.7738C6.73737 32.8041 9.08591 35.3485 11.9835 37.1244C14.8811 38.9002 18.2143 39.838 21.6128 39.8336Z"
        fill="#34A853"
      />
      <path
        d="M11.2973 25.0687C10.5278 22.801 10.5278 20.3428 11.2973 18.0751V13.3701H5.19621C3.91139 15.9132 3.242 18.7226 3.242 21.5719C3.242 24.4211 3.91139 27.2305 5.19621 29.7737L11.2973 25.0687Z"
        fill="#FBBC04"
      />
      <path
        d="M21.6128 10.5285C24.2292 10.486 26.7582 11.4696 28.6585 13.2685L33.9037 8.052C30.5702 4.94682 26.1681 3.24582 21.6128 3.30265C18.2133 3.29834 14.8793 4.23707 11.9816 6.01444C9.08379 7.7918 6.73582 10.3381 5.19879 13.3702L11.2947 18.07C12.7456 13.7434 16.8164 10.5285 21.6076 10.5285H21.6128Z"
        fill="#EA4335"
      />
    </svg>
  );
};
