const Arrow = ({ rotation = 0, className = '' }) => {
      const rotationClass =
            {
                  0: 'rotate-0',
                  90: 'rotate-90',
                  180: 'rotate-180',
                  270: 'rotate-270',
            }[rotation] || 'rotate-0';

      return (
            <div className={`h-[8px] w-[8px] transform ${rotationClass} ${className}`}>
                  <svg
                        viewBox='0 0 8 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                  >
                        <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M1.99936 0L0.589355 1.41L5.16936 6L0.589355 10.59L1.99936 12L7.99936 6L1.99936 0Z'
                              fill='black'
                              fillOpacity='0.87'
                        />
                  </svg>
            </div>
      );
};

export default Arrow;
