import { resetPassword } from "../store/slice/auth.slice";

export const createBundleApi = process.env.REACT_APP_BASE_URL + 'create-bundle';
export const deleteBundleApi = process.env.REACT_APP_BASE_URL + 'delete-bundle';
export const getPublishedBundlesApi = process.env.REACT_APP_BASE_URL + 'get-published-bundle';
export const updateBundleApi = process.env.REACT_APP_BASE_URL + 'update-bundle';
export const getSubBundlesApi = process.env.REACT_APP_BASE_URL + 'get-sub-bundle';
export const getPublishedSubBundlesApi = process.env.REACT_APP_BASE_URL + 'get-published-sub-bundle';
export const createSubBundleApi = process.env.REACT_APP_BASE_URL + 'create-sub-bundle';
export const updateSubBundleApi = process.env.REACT_APP_BASE_URL + 'update-sub-bundle';
export const createFlashApi = process.env.REACT_APP_BASE_URL + 'create-card/';
export const getFlashApi = process.env.REACT_APP_BASE_URL + 'get-cards/';
export const updateFlashApi = process.env.REACT_APP_BASE_URL + 'update-card';
export const deleteFlashApi = process.env.REACT_APP_BASE_URL + 'delete-card';
export const getCardsForRevision = process.env.REACT_APP_BASE_URL + 'get-cards-for-revision';
export const enrollCard = process.env.REACT_APP_BASE_URL + 'enroll-card/';
export const updateFlashGradeApi = process.env.REACT_APP_BASE_URL + 'revision';
export const updateRevisionStatusApi = process.env.REACT_APP_BASE_URL + '/update-card-status';

export const uploadFiles = process.env.REACT_APP_BASE_URL + 'upload';

export const endpoints = {
      upload: 'upload',

      /***********AUTHENTICATION****************/
      login: 'login',
      signup: 'signup',
      getUser: 'get-user',
      resetPassword: 'forgot-password',
      updatePassword: 'update-password',

      /****************BUDNLE*******************/
      getBundle: 'get-published-bundle',

      /***************SUB BUDNLE****************/
      getSubBundle: 'get-published-sub-bundle',

      /***************FLASH CARD***************/
      getFlashCards: 'get-cards/',
      createFlashCard: 'create-card',
      updateFlashCard: 'update-card',
      deleteFlashCard: 'delete-card',

      /*****************COURSE****************/
      createCourse: 'create-course',
      getCourse: 'user-get-single-course/',
      getCourses: 'user-get-all-course',
      getEnrolledCourses: 'get-enrolled-courses',
      getCourseSection: 'user-get-all-section/',
      createCourseSection: 'create-section',
      enrollCourse: 'enroll-course/',
      getUnit: 'get-unit/',
      completeUnit: 'update-completed-unit',
      checkCourseEnrollment: 'get-enrollment/',
      payment: 'checkout/'
} as const;
