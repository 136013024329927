import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { DotsIcon } from '../../../svgs/dotsIcon';
import { EditIcon } from '../../../svgs/editIcon';
import { StarIcon } from '../../../svgs/starIcon';
import { useTranslation } from 'react-i18next';
import { CardContext } from '../../../contexts/cardContext';
import { useNavigate } from 'react-router-dom';
import { PrimaryText, TertiaryText, Text12, Text14 } from '../../../components/textTitles';
import CustomTextArea from '../../../components/customTextArea';
import { GradeSelector } from './GradeSelector';
import { getToken } from '../../../core/utils/functions';
import axios from 'axios';
import { useAppSelector } from '../../../core/store/store';

export const BackCard = ({
      card,
      setIsFlipped,
      handleUpdateStarred,
      setShowCardPreview,
      updateIndex,
}: {
      card: any;
      setIsFlipped: Dispatch<SetStateAction<boolean>>;
      handleUpdateStarred: any;
      setShowCardPreview: any;
      updateIndex: any;
}) => {
      const { t } = useTranslation();
      const [showMenu, setShowMenu] = useState<boolean>(false);
      const { storeCard } = useContext(CardContext);
      const navigate = useNavigate();
      const { data } = useAppSelector((state) => state.user);

      const handleGrade = async (cardId: string, grade: number) => {
            try {
                  const token = getToken();
                  await axios.put(
                        `${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/revision`,
                        {
                              userId: data?.userId,
                              cardId,
                              grade,
                        },
                        {
                              headers: {
                                    Authorization: 'Bearer ' + token,
                              },
                        }
                  );
                  updateIndex((prev: number) => prev + 1);
                  setIsFlipped(false);
            } catch (error) {
            }
      };

      return (
            <div
                  className='flex flex-col gap-3 shadow-primary xl:w-[600px] w-[520px] h-[550px] rounded-[3rem] px-6 py-2 justify-center items-center relative bg-white'
                  onClick={() => {
                        setShowMenu(false);
                        setIsFlipped((prev) => !prev);
                  }}
            >
                  <button
                        className='absolute top-6 right-8'
                        onClick={(e) => {
                              e.stopPropagation();
                              setShowMenu((prev) => !prev);
                        }}
                  >
                        <DotsIcon />
                  </button>
                  {showMenu && (
                        <div className='absolute flex flex-col top-14 right-8 bg-[#E5E5E5] text-bluePrimary font-semibold gap-2 shadow-lg rounded-lg p-4'>
                              <button
                                    onClick={() => {
                                          storeCard({
                                                englishWord: card?.front?.text ? card.front.text : card.englishWord,
                                                englishExample: card?.front?.example
                                                      ? card.front.example
                                                      : card.englishExample,
                                                norwegianWord: card?.back?.text ? card.back.text : card.norwegianWord,
                                                norwegianExample: card?.back?.example
                                                      ? card.back.example
                                                      : card.norwegianExample,
                                                note: card.note,
                                                tags: card.tags,
                                                _id: card._id,
                                                location: window.location.pathname,
                                          });
                                          navigate('/edit');
                                    }}
                                    className='border-b border-gray-400 pb-2 flex flex-row gap-2 items-center'
                              >
                                    <EditIcon />
                                    Edit Card
                              </button>
                              <button
                                    onClick={(e) => {
                                          e.stopPropagation();
                                          handleUpdateStarred(card);
                                          setShowMenu(false);
                                    }}
                                    className='flex flex-row gap-2 items-center'
                              >
                                    <StarIcon />
                                    {card.isStarred ? t('Unstar') : t('Star')}
                              </button>
                        </div>
                  )}
                  <section className='text-center'>
                        <PrimaryText
                              title={card?.front?.text ? card.front.text : card.englishWord}
                              color='text-bluePrimary'
                        />
                        <TertiaryText
                              title={card?.front?.example ? card.front.example : card.englishExample}
                              color='text-blackSecondary'
                              fontWeight='font-semibold'
                        />
                  </section>
                  <section className='text-center'>
                        <PrimaryText
                              title={card?.back?.text ? card.back.text : card.norwegianWord}
                              color='text-bluePrimary'
                        />
                        <TertiaryText
                              title={card?.back?.example ? card.back.example : card.norwegianExample}
                              color='text-blackSecondary'
                              fontWeight='font-semibold'
                        />
                  </section>
                  <div className='flex flex-col gap-4 items-center justify-between w-full px-8 mt-4'>
                        <CustomTextArea
                              value={card.note ? card.note : ''}
                              rows={6}
                              placeholder=''
                              disabled
                              onChange={() => {}}
                        />
                        <div className='flex flex-row gap-1.5 items-center'>
                              <h2 className='text-base font-bold text-bluePrimary'>{t('Tags')}:</h2>
                              <Text14
                                    fontWeight='font-semibold'
                                    title={Array.isArray(card.tags) ? card.tags.join(', ') : card.tags}
                              />
                        </div>
                        <GradeSelector
                              handleGrade={handleGrade}
                              card={card}
                        />
                  </div>
                  <button>
                        <button className='absolute bottom-8 right-10'>
                              <Text12
                                    title={t('Tap to Flip the Card')}
                                    color='text-blackSecondary'
                                    fontWeight='font-semibold'
                              />
                        </button>
                  </button>
            </div>
      );
};
