import { SetStateAction, useEffect, useReducer, useState } from "react"
import { Text16 } from "../../../../components/textTitles"
import { createFlash } from "../../../admin/bundle/data/repository/createFlash"
import { FlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel"
import bundleReducer, { initialBundleReducerState } from "../../../admin/bundle/presentation/reducer/bundleReducer"
import { convertToFlashFormModel } from "../../../admin/bundle/data/models/flashFormModel"
import { SuccessModal } from "../../../../components/SuccessModal"

const AddToLibraryBox = ({ cardData, handleClose }: { cardData: FlashDetailsModel, handleClose: React.Dispatch<SetStateAction<boolean>> }) => {

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [state, dispatch] = useReducer(bundleReducer, initialBundleReducerState)

    useEffect(() => {
        if (state.bundleCUD === true) {
            setShowSuccessModal(true)
            handleClose(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.bundleCUD])

    return (
        <div className="shadow-primary absolute top-0 right-0 flex flex-col gap-3 bg-white rounded-lg p-6">
            <button className="bg-slate-200 rounded-md px-3 py-1" onClick={handleAddToLibrary}>
                <Text16 title={state.loading ? "Please wait.." : "Add to library"} fontWeight="font-semibold"></Text16>
            </button>
            <button className="bg-slate-200 rounded-md px-3 py-1" onClick={() => { handleClose(false) }}><Text16 title="Close" fontWeight="font-semibold"></Text16></button>
            {
                showSuccessModal &&
                <div className="w-screen h-screen absolute top-0 left-0">
                    <SuccessModal message="Card added to library successfully" handleClose={() => { setShowSuccessModal(false) }} />
                </div>
            }
        </div>
    )

    function handleAddToLibrary() {
        const formData = convertToFlashFormModel(cardData)
        createFlash({
            formData: formData,
            dispatch: dispatch
        })
    }

}

export default AddToLibraryBox