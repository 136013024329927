import { useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../../../core/constants/enums";
import { endpoints } from "../../../../core/api/apisPath";
import { DAxios } from "../../../../core/api/axiosConfig";
import { convertToFlashDetailsModel, FlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel";

export const useGetCards = () => {
    const [filteredCards, setFilteredCards] = useState<FlashDetailsModel[]>([]);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [tagFilter, setTagFilter] = useState<string | null>(null);
    const [dateFilter, setDateFilter] = useState<"latest" | "oldest" | null>(null);

    const queryResult = useQuery({
        queryKey: [QueryKey.FLASHCARD],
        queryFn: async () => {
            const response = await DAxios.get({
                endpoint: endpoints.getFlashCards,
            });
            const flashCards = (response.cards as []).map((element) =>
                convertToFlashDetailsModel(element)
            );
            return flashCards;
        },
    });

    const originalCards = useMemo(() => {
        return queryResult.data ? [...queryResult.data] : [];
    }, [queryResult.data]);

    const applyFilters = () => {
        let result = [...originalCards];

        if (searchText) {
            result = result.filter((card) =>
                card.front.text.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        if (tagFilter) {
            result = result.filter((card) => card.tags.includes(tagFilter));
        }

        if (dateFilter) {
            result = result.sort((a, b) => {
                const dateA = new Date(a.createdAt).getTime();
                const dateB = new Date(b.createdAt).getTime();
                return dateFilter === "latest" ? dateB - dateA : dateA - dateB;
            });
        }

        setFilteredCards(result);
    };

    const searchCardsByText = (text: string) => {
        setSearchText(text);
    };

    const filterCardsByTag = (tag?: string) => {
        setTagFilter(tag || null);
    };

    const filterCardsByDate = (order: "latest" | "oldest" | undefined) => {
        setDateFilter(order || null);
    };

    useEffect(() => {
        if (originalCards.length > 0) {
            applyFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, tagFilter, dateFilter, originalCards]);

    return {
        ...queryResult,
        filteredCards,
        searchCardsByText,
        filterCardsByTag,
        filterCardsByDate
    };
};
