import { CreateIcon, DashboardIcon, LogoutIcon, GroupIcon, RevisionIconSidebar } from '../../svgs/manager/svgManager';
import { DRoutes } from '../routes';

export interface SidebarTilesModel {
      name: string;
      navigate: string;
      icon: any;
}

export function sidebarTile(role: string, url: string): SidebarTilesModel[] {
      return [
            {
                  name: 'Dashboard',
                  navigate: '/dashboard',
                  icon: <DashboardIcon isSelected={url.includes('dashboard')} />,
            },
            {
                  name: 'Add Flash Card',
                  navigate: '/create',
                  icon: <CreateIcon isSelected={url === 'create'} />,
            },
            {
                  name: 'Library',
                  navigate: '/library',
                  icon: <GroupIcon isSelected={url === 'library'} />,
            },
            {
                  name: 'Revision',
                  navigate: '/revision',
                  icon: <RevisionIconSidebar isSelected={url === 'revision'} />,
            },
            {
                  name: 'Bundle',
                  navigate: DRoutes.bundle,
                  icon: <CreateIcon isSelected={url === DRoutes.bundle.split('/')[1]} />,
            },
            {
                  name: 'Course',
                  navigate: DRoutes.course,
                  icon: <CreateIcon isSelected={url === DRoutes.course.split('/')[1]} />,
            },
            {
                  name: 'My Courses',
                  navigate: DRoutes.enrolledCourse,
                  icon: <CreateIcon isSelected={url === DRoutes.enrolledCourse.split('/')[1]} />,
            },
            {
                  name: 'Logout',
                  navigate: DRoutes.login,
                  icon: <LogoutIcon isSelected={url.includes('/Logout')} />,
            },
      ];
}
