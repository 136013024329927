import { Reducer } from 'react';
import { FlashDetailsModel } from '../../../admin/bundle/data/models/flashDetailsModel';

export enum RevisionActionTypes {
    REVISION_LOADING = 'REVISION_LOADING',
    REVISION_DATA_SUCCESS = 'REVISION_DATA_SUCCESS',
    REVISION_DATA_FAILURE = 'REVISION_DATA_FAILURE',
    REVISION_CRUD = 'REVISION_CRUD',
    CLEAR_ERROR = 'CLEAR_ERROR',
    CLEAR_REVISION_CRUD = 'CLEAR_REVISION_CRUD',
}

export interface RevisionReducerState {
    revisionData: FlashDetailsModel[] | null;
    revisionCRUDData: boolean | null;
    loading: boolean;
    error: string | null;
}

export interface RevisionActions {
    type: RevisionActionTypes;
    revisionPayload?: FlashDetailsModel[];
    revisionCRUDPayload?: boolean;
    error?: string;
}

export const initialRevisionReducerState: RevisionReducerState = {
    revisionData: null,
    revisionCRUDData: null,
    loading: false,
    error: null,
};

const revisionReducer: Reducer<RevisionReducerState, RevisionActions> = (state: RevisionReducerState = initialRevisionReducerState, action: RevisionActions): RevisionReducerState => {
    switch (action.type) {
        case RevisionActionTypes.REVISION_LOADING:
            return { ...state, loading: true, error: null };
        case RevisionActionTypes.REVISION_DATA_SUCCESS:
            return { ...state, revisionData: action.revisionPayload ?? null, loading: false, error: null };
        case RevisionActionTypes.REVISION_CRUD:
            return { ...state, revisionCRUDData: action.revisionCRUDPayload ?? null, loading: false, error: null };
        case RevisionActionTypes.REVISION_DATA_FAILURE:
            return { ...state, loading: false, error: action.error ?? null };
        case RevisionActionTypes.CLEAR_REVISION_CRUD:
            return { ...state, loading: false, revisionCRUDData: false, error: null };
        case RevisionActionTypes.CLEAR_ERROR:
            return { ...state, error: null };
        default:
            return state;
    }
};

export default revisionReducer;
