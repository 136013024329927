import { LibraryHeader } from '../components/LibraryHeader';
import { useState } from 'react';
import { Header } from '../../../../components/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FlashDetailsModel } from '../../../admin/bundle/data/models/flashDetailsModel';
import { Text14 } from '../../../../components/textTitles';
import { formatDateToDMY } from '../../../../core/utils/dateFormatter';
import { EditIcon } from '../../../../svgs/editIcon';
import { TrashIcon } from '../../../../svgs/trashIcon';
import ConfirmationCard from '../../../admin/bundle/presentation/components/subBundle/subBundleFlashCards/confirmationCard';
import NoteCard from '../../../admin/bundle/presentation/components/subBundle/subBundleFlashCards/noteCard';
import LibraryTableHead from '../components/libraryTableHead';
import { useGetCards } from '../../data/services/getCards.service';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import { useDeleteCard } from '../../data/services/deleteCard.service';
import { useGetFilteredCards } from '../../data/services/getFilteredCards.service';

const Library = () => {
      const { t } = useTranslation();
      const navigate = useNavigate();
      const [openNote, setOpenNote] = useState(false);
      const [card, setCard] = useState<FlashDetailsModel>();
      const [dialogRender, setDialogRender] = useState(false);

      const { isPending, filteredCards: cards, filterCardsByTag, filterCardsByDate, searchCardsByText } = useGetCards();
      const { mutate: deleteCard, isPending: deletePending } = useDeleteCard(setDialogRender);
      const { mutate: getFilteredCards, isPending: filterPending } = useGetFilteredCards();

      return (
            <div className='bg-white w-full h-full flex gap-10'>
                  <div className='flex flex-col gap-6 w-full h-full pl-2'>
                        <Header
                              titleOne={t('Your Library')}
                              titleTwo=''
                        />
                        <LibraryHeader
                              handleSearch={(val) => {
                                    searchCardsByText(val);
                              }}
                              handleFilterByStarred={(val) => {
                                    getFilteredCards({ isStarred: val });
                              }}
                        />
                        <div className='h-full w-full px-4 relative'>
                              <LoadingEmptyContainer
                                    loading={isPending || filterPending}
                                    empty={cards?.length === 0}
                              >
                                    <table className='w-full max-w-6xl text-left table-auto'>
                                          <LibraryTableHead
                                                onChange={(tag) => {
                                                      filterCardsByTag(tag === 'Tags' ? undefined : tag);
                                                }}
                                                onChangeDate={(type) => {
                                                      filterCardsByDate(type);
                                                }}
                                          />
                                          <tbody className='w-full'>
                                                {cards?.map((element) => (
                                                      <tr
                                                            key={element._id}
                                                            className='h-12'
                                                      >
                                                            {/* Edit Icon */}
                                                            <td
                                                                  className='w-16 p-4 hover:cursor-pointer'
                                                                  onClick={() => {
                                                                        navigate('/edit', { state: element });
                                                                  }}
                                                            >
                                                                  <EditIcon />
                                                            </td>
                                                            {/* Back Text */}
                                                            <td className='p-4'>
                                                                  <Text14
                                                                        title={element.back.text}
                                                                        fontWeight='font-medium'
                                                                  />
                                                            </td>
                                                            {/* Front Text */}
                                                            <td className='p-4'>
                                                                  <Text14
                                                                        title={element.front.text}
                                                                        fontWeight='font-medium'
                                                                  />
                                                            </td>
                                                            {/* Tags */}
                                                            <td className='p-4'>
                                                                  {element.tags.map((tag, index) => (
                                                                        <div
                                                                              key={index}
                                                                              className='inline-block px-3 py-1 bg-slate-100 w-fit m-1'
                                                                        >
                                                                              <Text14
                                                                                    title={tag}
                                                                                    fontWeight='font-medium'
                                                                              />
                                                                        </div>
                                                                  ))}
                                                            </td>
                                                            {/* Date Created */}
                                                            <td className='p-4'>
                                                                  <Text14
                                                                        title={formatDateToDMY({
                                                                              date: element.createdAt,
                                                                        })}
                                                                        fontWeight='font-medium'
                                                                  />
                                                            </td>
                                                            {/* View Notes */}
                                                            <td
                                                                  className='p-4 hover:cursor-pointer '
                                                                  onClick={() => {
                                                                        setCard(element);
                                                                        setOpenNote(true);
                                                                  }}
                                                            >
                                                                  <Text14
                                                                        title='View notes'
                                                                        color='text-blue-500'
                                                                        fontWeight='font-bold'
                                                                  />
                                                            </td>
                                                            {/* Trash Icon */}
                                                            <td
                                                                  className='p-4 hover:cursor-pointer'
                                                                  onClick={() => {
                                                                        setCard(element);
                                                                        setDialogRender(true);
                                                                  }}
                                                            >
                                                                  <TrashIcon size='20px' />
                                                            </td>
                                                      </tr>
                                                ))}
                                          </tbody>
                                    </table>
                              </LoadingEmptyContainer>
                              {/* Confirmation Dialog */}
                              {dialogRender && (
                                    <ConfirmationCard
                                          confirmText='Yes'
                                          loading={deletePending}
                                          onYesClick={() => {
                                                deleteCard(card?._id);
                                          }}
                                          onNoClick={() => {
                                                setDialogRender(false);
                                          }}
                                    />
                              )}
                              {/* Note Card */}
                              {openNote && (
                                    <NoteCard
                                          noteData={card?.note ?? ''}
                                          setOpenNote={setOpenNote}
                                    />
                              )}
                        </div>
                  </div>
            </div>
      );
};

export default Library;
