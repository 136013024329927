const ContentIconSecondary = ({ className }: { className?: string }) => {
      return (
            <div className={`${className ?? 'h-[20px] w-[20px]'}`}>
                  <svg
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                  >
                        <rect
                              width='20'
                              height='20'
                              fill='url(#pattern0_218_4045)'
                        />
                        <defs>
                              <pattern
                                    id='pattern0_218_4045'
                                    patternContentUnits='objectBoundingBox'
                                    width='1'
                                    height='1'
                              >
                                    <use
                                          href='#image0_218_4045'
                                          transform='scale(0.0111111)'
                                    />
                              </pattern>
                              <image
                                    id='image0_218_4045'
                                    width='90'
                                    height='90'
                                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACg0lEQVR4nO2dwY7aQBBEfUr2E+Ls5rM4IyG6EGghJ7A/IMd8V8ieNvzE7nnFHuJTRyOFi4VJAM2UGVdJLSGYkXoepbLpgykKSZKkvGVmX83sFcBLeM3uJ0sB+AbAW/Wd3dcQILtgp4Psgp0Osgt2Osg+CNjj8fju7x3BE4D3CwB5n8rM9gC2AFbL5fJj0QdNp9MHADs2HMSr3Xw+/0J3cuaQ/QCb6uwQFz2A4IlqxQT91AMAnqh+MkHvewDAU1Q4Kw00+/BIXAINgaa7EHI06OAUHeBDVUZDoOmOgxwNOiRFB/gAldHoV+kHCwSa7kIMyNGNmW0mk8nnUACq8F7XejP7DWANoEzQfxl6O9XPLYGujuyrTqxfE85R5QC6bO9bLBafutaHz1Kf41Q/NwM6xEXR0mw2uz/ni4mtf/Rz09FRn8joDeEcdQ6gmwD7nIvh4eIZu///6eeWQHsuJdAQaLoLIUeDDk7RAT5UZTQEussJjWYdaW7vqiP7NOuIALps79OsI4KjNevgRkfdtV6zjstBN5p1pHG051LX8hJoCLSzXSxHQ6DpzoMcDTqsnKOj0awjDejqyD7NOiKALtv7NOuI4GjNOrjRUXet16zjctCNZh1pHO251LW8BBoC7WwXy9EQaLrzIEeDDivn6Gg060gDujqyT7OOCKDL9j7NOiI4WrMObnTUXes167gcdKNZRxpHey51LS+BhkA728VyNASa7jzI0aDDUnSAD1IZjX6Ubu+QOeiBPT/6jQl6SE9E39JAh+feD8jRjzTQ4Z8czOwXGwLi1/NoNPpQsP+HJXPYz+GZS0UfFJwNYBlyLIcLpJntzexHiAu6kyVJkiRJkopz9AdIymV4QKmAnwAAAABJRU5ErkJggg=='
                              />
                        </defs>
                  </svg>
            </div>
      );
};

export default ContentIconSecondary;
