import { useNavigate } from 'react-router-dom';
import { Header } from '../../../../components/Header';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import CourseCard from '../components/CourseCard';
import { DRoutes } from '../../../../core/routes';
import { useGetCourses } from '../../data/services/getCourses.service';

const Courses = () => {
      const navigate = useNavigate();
      const { data, isPending, error } = useGetCourses();

      return (
            <>
                  <Header
                        titleOne='Courses'
                        titleTwo=''
                  />
                  <LoadingEmptyContainer
                        loading={isPending}
                        empty={data?.courses?.length === 0}
                        error={error}
                        className='mt-2'
                  >
                        <section className='flex flex-wrap gap-4 p-2'>
                              {data?.courses?.map((element) => (
                                    <CourseCard
                                          key={element._id}
                                          course={element}
                                          onClick={() => {
                                                navigate(DRoutes.courseDetails + element._id);
                                          }}
                                    />
                              ))}
                        </section>
                  </LoadingEmptyContainer>
            </>
      );
};

export default Courses;
