const VideoIcon = ({ className }: { className?: string }) => {
      return (
            <div className={`${className ?? 'h-[20px] w-[20px]'}`}>
                  <svg
                        viewBox='0 0 20 19'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                  >
                        <rect
                              width='20'
                              height='19'
                              fill='url(#pattern0_218_4046)'
                        />
                        <defs>
                              <pattern
                                    id='pattern0_218_4046'
                                    patternContentUnits='objectBoundingBox'
                                    width='1'
                                    height='1'
                              >
                                    <use
                                          href='#image0_218_4046'
                                          transform='matrix(0.0105556 0 0 0.0111111 0.025 0)'
                                    />
                              </pattern>
                              <image
                                    id='image0_218_4046'
                                    width='90'
                                    height='90'
                                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACc0lEQVR4nO2czW7TQBRGZwNljWglCnt4EF4h68h27hcvCOzIc7DiRegrFLoqSLwFC4TKpoBkNGoiRZWI/JOJxzPnSFfKJonn+PobZ2KNcwAAAAAAAAAAGTGfzx+Z2Vszu5L0S1JDqdm4+CzpzWq1OhkkebFYPJf0FbHa21xm9sW76t3JSFYn2b0628cFnayuMbnqI9pnMnmsTg4+9RF9g2h1ajTvrLPo/33YkPdqIhXaD6KF6IaOJjoaokNkdDP2hMdkKERze7cDt3dKJDooDT5JiNZxrgZEK33Rt2b2rizL86IoTv1rST+mGFOxi17f//6N8A+S/o4tLyXRT/ccx0szu8hOdIiT5FpgZq8kfYtFVrKiPZIe6I7viA4oektVVY8lvTezP3R0yFwLnN8u9+g4Vn67FmQp+tD57VqQrehD5rdrQfait9R1/ULSR0QrTEcfIr+j6OgQ5QLj83uzfvI7urGkJHrLRnZcY0lRtKQn0Y0lRdFFUZxGN5YURUtaRzeWlETr7sfMOtTaSBDRQ96rEURP9vZuKqI1YMFpyBizEV3xE1xBRbOodISONpZJw0aHWPgPm9EVf2WFFS3+nA0v2nJ73CBElWV5vuc48nyAJlCt738/j4SFEX3rZfvOruv6jIccI7g0FXnFHh1NKoVoTUx0DleDC+wH0UJ0Q0cTHQ3RITK6GXvCYzIUorm924HbO00sOtiOTZ1PkJn97COaDQZ1hA0G/S6yMUxMmlCZ2evOov2Gpn5j07EPXtOp69ls9tD1wW/Vi2y1krxcLp/1krzb2X4XWZ8/TJDajYkbM7v0cdG7kwEAAAAAAAAA3DT5Bwb2S88yufXiAAAAAElFTkSuQmCC'
                              />
                        </defs>
                  </svg>
            </div>
      );
};

export default VideoIcon;
