import { userCardsTableHeaderItems } from '../../../../core/constants/tableHeadersList';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../core/store/store';
import { Text16 } from '../../../../components/textTitles';

const LibraryTableHead = ({
      onChange,
      onChangeDate,
}: {
      onChange: (val: string) => void;
      onChangeDate: (type: 'latest' | 'oldest') => void;
}) => {
      const { t } = useTranslation();
      const { data } = useAppSelector((state) => state.user);
      const dateFilter = ['latest', 'oldest'];

      return (
            <thead>
                  <tr className='border-b-2'>
                        <th className='w-16'></th>
                        {userCardsTableHeaderItems.map((element, index) => (
                              <th
                                    key={index}
                                    className='p-4'
                              >
                                    {element.type === 'dropdown' ? (
                                          <div className='flex items-center justify-left gap-1'>
                                                <div>{element.icon}</div>
                                                <select
                                                      className='bg-white appearance-none'
                                                      onChange={(e) => {
                                                            if (element.filter === 'tags') {
                                                                  onChange(e.target.value);
                                                            } else if (element.filter === 'date') {
                                                                  onChangeDate(e.target.value as 'latest' | 'oldest');
                                                            }
                                                      }}
                                                >
                                                      {/* Tags Dropdown */}
                                                      {element.filter === 'tags' && <option value='Tags'>Tags</option>}
                                                      {element.filter === 'tags' &&
                                                            data?.tags?.map((element, index) => (
                                                                  <option
                                                                        key={index}
                                                                        value={element}
                                                                  >
                                                                        {element}
                                                                  </option>
                                                            ))}
                                                      {/* Date Dropdown */}
                                                      {element.filter === 'date' &&
                                                            dateFilter.map((element, index) => (
                                                                  <option
                                                                        key={index}
                                                                        value={element}
                                                                  >
                                                                        {element}
                                                                  </option>
                                                            ))}
                                                </select>
                                          </div>
                                    ) : (
                                          <div className='flex items-center gap-1'>
                                                <Text16
                                                      title={t(element.name)}
                                                      fontWeight='font-bold'
                                                />
                                          </div>
                                    )}
                              </th>
                        ))}
                  </tr>
            </thead>
      );
};

export default LibraryTableHead;
