import { UserProvider } from './contexts/userContext';
import { CardProvider } from './contexts/cardContext';
import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './AppRoutes';
import './i18n';
import { ToastContainer } from 'react-toastify';

function App() {

      return (
            <UserProvider>
                  <CardProvider>
                        <AppRoutes />
                  </CardProvider>
                  <ToastContainer
                        position='top-right'
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme='light'
                  />
            </UserProvider>
      );
}

export default App;
