import { useEffect, useState } from 'react';
import { Text14 } from '../../../../components/textTitles';
import { CourseAssignmentTypeEnum } from '../../../../core/constants/enums';
import Mcq from './assignments/Mcq';
import TertiaryButton from '../../../../components/buttons/tertiaryButton';
import FillBlanks from './assignments/FillBlanks';
import MatchFollowing from './assignments/MatchFollowing';
import WritingQuestion from './assignments/WritingQuestion';
import ListeningQuestion from './assignments/ListeningQuestion';
import { AssignmentModel } from '../../data/models/assignmentModel';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import ComprehensionQuestion from './assignments/ComprehensionQuestion';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import FlashCardQuestion from './assignments/FlashCardQuestion';

const CourseUnitTypeAssignment: React.FC<{
      content: AssignmentModel[];
      isNested?: boolean;
      isAnswerDone: (isAnswerDone: boolean) => void;
      answersCallback?: (answer: {}) => void;
      prefilledAnswers?: {};
}> = ({ content, isAnswerDone, isNested = false, answersCallback, prefilledAnswers }) => {
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [answers, setAnswers] = useState<any>({});

      useEffect(() => {
            if (prefilledAnswers) {
                  setAnswers(prefilledAnswers);
            }
      }, [prefilledAnswers]);

      const handleSaveAnswer = (questionIndex: number, answer: any) => {
            setAnswers((prevAnswers: any) => {
                  const newAnswers = { ...prevAnswers, [questionIndex]: answer };
                  if (Object.keys(newAnswers).length === content.length) {
                        let isDone = true;
                        if (answersCallback !== undefined) {
                              answersCallback(newAnswers);
                        }
                        for (let i = 0; i < Object.keys(newAnswers).length; i++) {
                              if (newAnswers[i] === undefined) {
                                    isDone = false;
                                    break;
                              }
                        }

                        isAnswerDone(isDone);
                  }
                  return newAnswers;
            });
      };

      return (
            <LoadingEmptyContainer
                  loading={false}
                  empty={content.length === 0}
            >
                  <div className={`${isNested === true ? '' : 'mt-5'} flex flex-col items-center w-full`}>
                        {!isNested && (
                              <div className='flex justify-center items-center gap-2'>
                                    <Text14
                                          title={`Current Question ${currentQuestion + 1}`}
                                          fontWeight='font-semibold'
                                    />
                                    |
                                    <Text14
                                          title={`Total Questions ${content.length}`}
                                          fontWeight='font-semibold'
                                          color='text-gray-500'
                                    />
                              </div>
                        )}
                        {isNested === true && (
                              <div className='flex w-full justify-end items-center gap-2'>
                                    <Text14
                                          title={`${currentQuestion + 1}`}
                                          fontWeight='font-semibold'
                                    />
                                    |
                                    <Text14
                                          title={`${content.length}`}
                                          fontWeight='font-semibold'
                                          color='text-gray-500'
                                    />
                              </div>
                        )}
                        {!isNested && (
                              <progress
                                    value={(currentQuestion / (content.length - 1)) * 100}
                                    max='100'
                                    className='h-3 rounded-lg w-3/4 mt-3'
                              />
                        )}
                        <div className={`p-3 ${isNested === true ? 'w-[90%]' : 'w-3/4 mt-8'} `}>
                              {content[currentQuestion]?.assignmentType === CourseAssignmentTypeEnum.MCQ.toString() &&
                                    content[currentQuestion]?.mcqTypeQuestionBody !== undefined && (
                                          <Mcq
                                                question={content[currentQuestion].mcqTypeQuestionBody!}
                                                savedAnswer={answers[currentQuestion]}
                                                onSaveAnswer={(answer) => handleSaveAnswer(currentQuestion, answer)}
                                          />
                                    )}
                              {content[currentQuestion]?.assignmentType ===
                                    CourseAssignmentTypeEnum.FILL_IN_BLANKS.toString() &&
                                    content[currentQuestion]?.fillBlanksTypeQuestionBody !== undefined && (
                                          <FillBlanks
                                                question={content[currentQuestion].fillBlanksTypeQuestionBody!}
                                                savedAnswer={answers[currentQuestion]}
                                                onSaveAnswer={(answer) => handleSaveAnswer(currentQuestion, answer)}
                                          />
                                    )}
                              {content[currentQuestion]?.assignmentType ===
                                    CourseAssignmentTypeEnum.MATCH_THE_FOLLOWING.toString() &&
                                    content[currentQuestion]?.matchFollowingQuestionBody !== undefined && (
                                          <MatchFollowing
                                                question={content[currentQuestion].matchFollowingQuestionBody!}
                                                savedAnswer={answers[currentQuestion]}
                                                onSaveAnswer={(answer) => handleSaveAnswer(currentQuestion, answer)}
                                          />
                                    )}
                              {content[currentQuestion]?.assignmentType ===
                                    CourseAssignmentTypeEnum.WRITING.toString() &&
                                    content[currentQuestion]?.writingTypeQuestionBody !== undefined && (
                                          <WritingQuestion
                                                question={content[currentQuestion].writingTypeQuestionBody!}
                                                savedAnswer={answers[currentQuestion]}
                                                onSaveAnswer={(answer) => {
                                                      handleSaveAnswer(currentQuestion, answer);
                                                }}
                                          />
                                    )}
                              {content[currentQuestion]?.assignmentType ===
                                    CourseAssignmentTypeEnum.LISTENING.toString() &&
                                    content[currentQuestion]?.listeningTypeQuestionBody !== undefined && (
                                          <ListeningQuestion
                                                question={content[currentQuestion].listeningTypeQuestionBody!}
                                                savedAnswer={answers[currentQuestion]}
                                                onSaveAnswer={(answer) => {
                                                      handleSaveAnswer(currentQuestion, answer);
                                                }}
                                          />
                                    )}
                              {content[currentQuestion]?.assignmentType ===
                                    CourseAssignmentTypeEnum.COMPREHENSION.toString() &&
                                    content[currentQuestion]?.comprehension !== undefined && (
                                          <ComprehensionQuestion
                                                question={content[currentQuestion].comprehension!}
                                                savedAnswer={answers[currentQuestion]}
                                                onSaveAnswer={(answer) => {
                                                      handleSaveAnswer(currentQuestion, answer);
                                                }}
                                          />
                                    )}
                              {content[currentQuestion]?.assignmentType ===
                                    CourseAssignmentTypeEnum.FLASH_CARD.toString() &&
                                    content[currentQuestion]?.flashCard !== undefined && (
                                          <FlashCardQuestion
                                                question={content[currentQuestion].flashCard!}
                                                onSaveAnswer={(answer) => {
                                                      handleSaveAnswer(currentQuestion, answer);
                                                }}
                                          />
                                    )}
                        </div>
                        <div
                              className={`${isNested === true ? 'w-full justify-end gap-5' : 'w-3/4 justify-between'} flex mt-10 mb-10`}
                        >
                              {isNested === true ? (
                                    <button
                                          disabled={currentQuestion === 0}
                                          onClick={() => {
                                                setCurrentQuestion((prev) => prev - 1);
                                          }}
                                    >
                                          <FaArrowAltCircleLeft size={33} />
                                    </button>
                              ) : (
                                    <TertiaryButton
                                          text='Prev Question'
                                          disabled={currentQuestion === 0}
                                          onClick={() => {
                                                setCurrentQuestion((prev) => prev - 1);
                                          }}
                                    />
                              )}
                              {isNested === true ? (
                                    <button
                                          disabled={currentQuestion === content.length - 1}
                                          onClick={() => {
                                                setCurrentQuestion((prev) => prev + 1);
                                          }}
                                    >
                                          <FaArrowAltCircleRight size={33} />
                                    </button>
                              ) : (
                                    <TertiaryButton
                                          text='Next Question'
                                          disabled={currentQuestion === content.length - 1}
                                          onClick={() => {
                                                setCurrentQuestion((prev) => prev + 1);
                                          }}
                                    />
                              )}
                        </div>
                  </div>
            </LoadingEmptyContainer>
      );
};

export default CourseUnitTypeAssignment;
