import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import { useGetCourseSection } from '../../data/services/getCourseSection.service';
import { HelperFunction } from '../../../../core/utils/functions';
import CourseContentItem from '../components/CourseContentItem';
import { useCheckCourseEnrollment } from '../../data/services/checkCourseEnrolled.service';
import { useEffect, useState } from 'react';
import CustomProgressBar from '../components/CustomProgressBar';
import CourseHeader from '../components/CourseHeader';
import { useGetCourse } from '../../data/services/getCourse.service';
import TertiaryButton from '../../../../components/buttons/tertiaryButton';
import { useNavigate } from 'react-router-dom';
import { DRoutes } from '../../../../core/routes';

const CourseContent = () => {
      const courseId = HelperFunction.getUrlQueryParameter('courseId');
      const { data, isPending, isError } = useGetCourseSection({ courseId: courseId ?? '' });
      const { data: course } = useGetCourse(courseId);
      const { data: enrolledCourseMeta } = useCheckCourseEnrollment({ courseId });
      const [progress, setProgress] = useState(0);
      const [unitsCount, setUnitsCount] = useState(0);
      const navigate = useNavigate();

      useEffect(() => {
            if (data) {
                  let completedCount = 0;
                  let unitsCount = 0;
                  for (let i = 0; i < data.section.length; i++) {
                        const units = data.section[i].unit;
                        if (units) {
                              for (let j = 0; j < units.length; j++) {
                                    unitsCount += 1;
                                    if (units[j].isCompleted) {
                                          completedCount += 1;
                                    }
                              }
                        }
                  }
                  setProgress(completedCount);
                  setUnitsCount(unitsCount);
            }
      }, [data]);

      return (
            <LoadingEmptyContainer
                  loading={isPending}
                  empty={data?.section.length === 0 || isError}
                  className='flex flex-col gap-6'
            >
                  <div>
                        <div className='flex items-center justify-between'>
                              <CourseHeader
                                    tOne={course?.courses[0]}
                                    tTwo='Chapters'
                              />
                              {enrolledCourseMeta && (
                                    <TertiaryButton
                                          text={
                                                enrolledCourseMeta?.enrollment?.completedUnits.length !== 0
                                                      ? 'Resume Course'
                                                      : 'Play Course'
                                          }
                                          onClick={() => {
                                                if (enrolledCourseMeta?.resumableUnitId) {
                                                      navigate(
                                                            DRoutes.unit +
                                                                  `?unitId=${enrolledCourseMeta.resumableUnitId}`
                                                      );
                                                }
                                          }}
                                    />
                              )}
                        </div>
                        <div className='h-6'></div>
                        {enrolledCourseMeta && (
                              <div>
                                    <CustomProgressBar
                                          value={progress}
                                          total={unitsCount}
                                    />
                                    <div className='h-6'></div>
                              </div>
                        )}
                        {data?.section.map((item) => {
                              return (
                                    <CourseContentItem
                                          key={item._id}
                                          isEnrolled={enrolledCourseMeta ? true : false}
                                          courseSection={item}
                                    />
                              );
                        })}
                  </div>
            </LoadingEmptyContainer>
      );
};

export default CourseContent;
