import { Text16, Text14 } from "../../../components/textTitles";

interface StatsCardProps {
  stat: string;
  value: string;
  icon: React.ReactNode;
}

const StatsCard = ({ stat, value, icon }: StatsCardProps) => {
  return (
    <div className="flex justify-between items-center w-[75%] gap-2">
      <div className="flex items-center gap-6">
        <div>{icon}</div>
        <div className="flex flex-col">
          <Text16 title={stat} fontWeight="font-semibold" />
          <Text14 title={value} fontWeight="font-medium" color="text-blackSecondary" />
        </div>
      </div>
      <div className="rounded-full bg-[#B1FFAF] px-2.5 py-2">
        <Text14 title="99" fontWeight="font-semibold" color="text-blackSecondary"/>
        {/* <h1 className="xl:text-sm text-xs font-semibold text-gray-800">99</h1> */}
      </div>
    </div>
  );
};

export default StatsCard;
