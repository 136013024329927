export const ProfileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1875 7.35745C17.1875 10.6856 15.1771 13.5418 12.5 13.5418C9.82082 13.5418 7.81248 10.6856 7.81248 7.35641C7.81248 4.02933 9.54165 2.0835 12.5 2.0835C15.4583 2.0835 17.1875 4.02829 17.1875 7.35745ZM4.2729 20.9814C4.67394 21.4585 6.40102 22.9168 12.5 22.9168C18.5989 22.9168 20.325 21.4585 20.7271 20.9825C20.7644 20.9367 20.7919 20.8838 20.808 20.827C20.8242 20.7702 20.8285 20.7108 20.8208 20.6522C20.7291 19.7335 19.9021 15.6252 12.5 15.6252C5.0979 15.6252 4.27082 19.7335 4.17811 20.6522C4.17056 20.7108 4.17508 20.7704 4.19138 20.8271C4.20768 20.8839 4.23542 20.9357 4.2729 20.9814Z"
        fill="#7573FF"
      />
    </svg>
  );
};
