export const GroupIcon = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <div className={`h-[56px] w-[56px] flex justify-center items-center rounded-[50%] ${isSelected ? "bg-bluePrimary" : "bg-whitePrimary"}`}>
      {
        isSelected ?
          <svg width="33" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0215 1.46336e-05H22.7118C23.1012 -0.00010576 23.3997 -0.000185907 23.6606 0.030394C25.516 0.247845 27.0347 1.58433 27.6786 3.38462H6.0547C6.69868 1.58433 8.21733 0.247845 10.0727 0.030394C10.3336 -0.000185907 10.6322 -0.00010576 11.0215 1.46336e-05Z" fill="white" />
            <path d="M7.4029 4.23077C5.12421 4.23077 3.25578 5.63517 2.63225 7.49829C2.61925 7.53712 2.60678 7.57617 2.59487 7.61539C3.24728 7.41375 3.92627 7.28201 4.61359 7.19209C6.3839 6.96044 8.62115 6.96056 11.22 6.96071H11.4141H22.5134C25.1122 6.96056 27.3495 6.96044 29.1199 7.19209C29.8071 7.28201 30.4861 7.41375 31.1385 7.61539C31.1267 7.57617 31.1142 7.53712 31.1011 7.49829C30.4776 5.63517 28.6091 4.23077 26.3305 4.23077H7.4029Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2541 9.30769H22.4792C28.1714 9.30769 31.0175 9.30769 32.6163 10.9248C34.2149 12.5419 33.8388 15.0403 33.0865 20.0372L32.3731 24.7755C31.7831 28.6941 31.4882 30.6534 29.975 31.8267C28.4617 33 26.2297 33 21.7658 33H11.9675C7.50366 33 5.27173 33 3.75847 31.8267C2.24521 30.6534 1.95023 28.6941 1.36026 24.7755L0.646855 20.0372C-0.10545 15.0403 -0.481594 12.5419 1.11711 10.9248C2.71582 9.30769 5.56192 9.30769 11.2541 9.30769ZM10.12 26.4454C10.12 25.7666 10.7493 25.2163 11.5256 25.2163H22.2078C22.9841 25.2163 23.6133 25.7666 23.6133 26.4454C23.6133 27.1243 22.9841 27.6745 22.2078 27.6745H11.5256C10.7493 27.6745 10.12 27.1243 10.12 26.4454Z" fill="white" />
          </svg>
          :
          <svg width="33" height="33" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7864 1.28874C10.9875 1.26465 11.2295 1.26356 11.6252 1.26369H11.6254H23.0616L23.0618 1.26369C23.4576 1.26356 23.6994 1.26465 23.9005 1.28874C25.0498 1.42643 26.0704 2.11709 26.7293 3.14829H7.95773C8.61658 2.11708 9.63713 1.42643 10.7864 1.28874ZM10.7864 1.28874L10.6996 0.563903L10.7864 1.28874Z" stroke="#7573FF" stroke-width="1.5" />
            <path d="M29.8922 7.03107C29.7382 7.00583 29.5843 6.98311 29.4308 6.96256L29.3313 7.70594L29.4308 6.96256C27.6492 6.72427 25.4197 6.72441 22.9145 6.72456L22.8685 6.72457H22.8684H12.0105H11.8206H11.8206L11.7745 6.72456C9.26933 6.72441 7.03993 6.72427 5.25837 6.96256C5.10475 6.98311 4.95085 7.00583 4.79688 7.03108C5.54099 6.11102 6.71816 5.49463 8.08647 5.49463H26.6026C27.9709 5.49463 29.1481 6.11103 29.8922 7.03107ZM31.2695 8.01214C31.1825 7.7464 31.0707 7.48999 30.9365 7.24566L31.306 8.12924C31.2945 8.09003 31.2823 8.05098 31.2695 8.01214Z" stroke="#7573FF" stroke-width="1.5" />
            <path d="M32.4695 20.4423L31.7715 25.1805L32.5135 25.2898L31.7715 25.1806C31.4798 27.161 31.2707 28.5697 30.9594 29.6345C30.656 30.6721 30.2788 31.2959 29.7017 31.7532C29.1161 32.2174 28.3577 32.4837 27.1491 32.6226C25.925 32.7632 24.3381 32.7643 22.1368 32.7643H12.5516C10.3504 32.7643 8.76347 32.7632 7.53947 32.6226C6.33082 32.4837 5.57249 32.2174 4.98686 31.7532C4.40977 31.2959 4.03252 30.6721 3.72912 29.6344C3.41779 28.5697 3.20861 27.161 2.91693 25.1806L2.17493 25.2898L2.91693 25.1806L2.21903 20.4422C1.8474 17.919 1.58362 16.1134 1.59456 14.7339C1.60526 13.3848 1.87981 12.5772 2.47619 11.9605C3.08548 11.3305 3.97178 10.9613 5.48842 10.7682C7.01752 10.5735 9.04895 10.572 11.8537 10.572H22.8348C25.6395 10.572 27.6709 10.5735 29.2 10.7682C30.7167 10.9613 31.603 11.3305 32.2123 11.9605C32.8087 12.5772 33.0832 13.3848 33.0939 14.7339C33.1049 16.1134 32.8411 17.919 32.4695 20.4423ZM12.1192 24.9807C11.027 24.9807 9.99425 25.7898 9.99425 26.9597C9.99425 28.1297 11.0269 28.9389 12.1192 28.9389H22.5693C23.6616 28.9389 24.6943 28.1297 24.6943 26.9597C24.6943 25.7898 23.6615 24.9807 22.5693 24.9807H12.1192Z" stroke="#7573FF" stroke-width="1.5" />
          </svg>

      }
    </div>
  );
};
