import { useMutation } from "@tanstack/react-query"
import { endpoints } from "../../../../core/api/apisPath"
import { DAxios } from "../../../../core/api/axiosConfig"
import { useAppSelector } from "../../../../core/store/store"
import { DToast } from "../../../../core/utils/toasts"
import { FlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel"

export const useUpdateFlashCard = () => {
    const { data } = useAppSelector(state => state.user)
    return useMutation({
        mutationFn: ({ card, cardId, isStarred }: { card: FlashDetailsModel, cardId: string, isStarred: boolean }) => {
            return DAxios.patch({
                endpoint: endpoints.updateFlashCard,
                body: {
                    cardId: cardId,
                    userId: data?.userId,
                    audio: card?.audio,
                    frontText: card?.front.text,
                    frontExample: card?.front.example,
                    backText: card?.back.text,
                    backExample: card?.back.example,
                    note: card?.note,
                    tags: card?.tags,
                    isStarred: isStarred,
                    image: card?.image,
                }
            })
        },
        onSuccess: () => {
            new DToast().showSuccessToast('Card updated successfully')
        },
        onError: (error) => {
            new DToast().showErrorToast(error.toString())
        }
    })
}