import axios from 'axios';
import { BundleActions, BundleActionTypes } from '../../presentation/reducer/bundleReducer';
import { updateFlashApi, uploadFiles } from '../../../../../core/api/apisPath';
import { getToken } from '../../../../../core/utils/functions';
import { sessionExpiredMessage } from '../../../../../core/constants/messages';
import { FlashFormModel } from '../models/flashFormModel';

interface UpdateFlashProps {
      flashId: string;
      userId?: string;
      formData?: FlashFormModel;
      isStarred: boolean;
      dispatch: React.Dispatch<BundleActions>;
      imageFile?: File;
      audioFile?: File;
}

export const updateFlash = async ({
      flashId,
      userId,
      formData,
      isStarred,
      dispatch,
      imageFile,
      audioFile,
}: UpdateFlashProps) => {
      try {
            dispatch({ type: BundleActionTypes.BUNDLE_LOADING });
            if (!userId) throw new Error(sessionExpiredMessage);

            if (formData) {
                  const {
                        image,
                        nativeText: englishText,
                        nativeExample: englishExample,
                        norwegianText,
                        norwegianExample,
                        note,
                        tags,
                  } = formData;

                  // if (!image || image.trim() === "")
                  //     throw new Error("Please select an image")
                  if (!englishText || englishText.trim() === '') throw new Error('Please enter english text');
                  if (!englishExample || englishExample.trim() === '') throw new Error('Please enter english example');
                  if (!norwegianText || norwegianText.trim() === '') throw new Error('Please enter norwegian text');
                  if (!norwegianExample || norwegianExample.trim() === '')
                        throw new Error('Please enter norwegian example');
                  if (!note || note.trim() === '') throw new Error('Please enter note');
            }

            const token = getToken();

            let image;
            let audio;

            if (audioFile) {
                  const formData = new FormData();
                  formData.append('file', audioFile);
                  const audioResponse = await axios.post(uploadFiles, formData, {
                        headers: {
                              'Content-Type': 'multipart/form-data',
                              'Authorization': 'Bearer ' + token,
                        },
                  });
                  audio = audioResponse.data.response.url ?? null;
            }

            if (imageFile) {
                  const formData = new FormData();
                  formData.append('file', imageFile);
                  const imageResponse = await axios.post(uploadFiles, formData, {
                        headers: {
                              'Content-Type': 'multipart/form-data',
                              'Authorization': 'Bearer ' + token,
                        },
                  });
                  image = imageResponse.data.response.url ?? null;
            }

            const response = await axios.patch(
                  updateFlashApi,
                  {
                        cardId: flashId,
                        userId: userId,
                        audio: audio ?? formData?.audio,
                        frontText: formData?.nativeText,
                        frontExample: formData?.nativeExample,
                        backText: formData?.norwegianText,
                        backExample: formData?.norwegianExample,
                        note: formData?.note,
                        tags: formData?.tags,
                        isStarred: isStarred,
                        image: image ?? formData?.image,
                  },
                  {
                        headers: {
                              Authorization: 'Bearer ' + token,
                        },
                  }
            );

            dispatch({ type: BundleActionTypes.BUNDLE_CUD_SUCCESS, cudPayload: true });
      } catch (error: any) {
            dispatch({ type: BundleActionTypes.BUNDLE_DATA_FAILURE, error: error.message });
      }
};
