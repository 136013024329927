import { useEffect, useState } from 'react';
import { Text14 } from '../../../../components/textTitles';
import { CourseSectionUnitModel } from '../../data/models/courseSectionUnitModel';
import CourseUnitTypeAssignment from './CourseUnitTypeAssignment';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import { ComingSoon } from '../../../../svgs/manager/svgManager';
import { useCompleteUnit } from '../../data/services/completeUnit.service';
import { DToast } from '../../../../core/utils/toasts';

const NextUnitModal = ({
      data,
      closeModal,
      onSucess,
}: {
      data: CourseSectionUnitModel | undefined;
      closeModal: () => void;
      onSucess: () => void;
}) => {
      const [isAnswerDone, setIsAnswerDone] = useState(false);
      const { mutate: completeUnit, isPending, isSuccess } = useCompleteUnit();

      useEffect(() => {
            if (isSuccess) {
                  onSucess();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isSuccess]);

      return (
            <div className='z-50 w-screen h-screen bg-black bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center gap-8'>
                  <div className='relative bg-white flex flex-col border justify-center items-center gap-8 rounded-[70px] shadow-[1px_4px_14.5px_0px_rgba(0,_0,_0,_0.25)] px-5 py-16 w-[90%] h-[90%]'>
                        <button
                              onClick={closeModal}
                              className='absolute top-5 right-5 w-[50px] h-[50px] bg-white rounded-[100px] flex justify-center items-center shadow-primary'
                        >
                              &#x2715;{/*Close Form Button */}
                        </button>
                        <div className='overflow-y-scroll w-full flex justify-center items-center'>
                              {(data?.completionAssignment?.length ?? 0) > 0 && data ? (
                                    <center className='w-full'>
                                          <Text14
                                                title='NOTE : Please complete the following assignment to complete this chapter'
                                                fontWeight='font-semibold'
                                          />
                                          <CourseUnitTypeAssignment
                                                content={data.completionAssignment}
                                                isAnswerDone={(val) => {
                                                      setIsAnswerDone(val);
                                                }}
                                          ></CourseUnitTypeAssignment>
                                          <PrimaryButton
                                                text='Complete Chapter & Proceed to Next Unit'
                                                loading={isPending}
                                                onClick={() => {
                                                      if (
                                                            (data?.completionAssignment?.length ?? 0) > 0 &&
                                                            !isAnswerDone
                                                      ) {
                                                            new DToast().showErrorToast(
                                                                  'Please answer the following questions'
                                                            );
                                                            return;
                                                      }
                                                      completeUnit({
                                                            courseId: data?.courseId ?? '',
                                                            unitId: data?._id ?? '',
                                                      });
                                                }}
                                          />
                                    </center>
                              ) : (
                                    <div className='flex items-center'>
                                          <ComingSoon />
                                          <div>
                                                <h1 className='text-5xl font-bold'>Hurray!</h1>
                                                <h2 className='font-medium mt-2'>You have successfully completed</h2>
                                                <h2 className='font-medium mt-[-6px] mb-6'>this chapter</h2>
                                                <PrimaryButton
                                                      text='Complete Chapter & Proceed to Next Unit'
                                                      loading={isPending}
                                                      onClick={() => {
                                                            if (
                                                                  (data?.completionAssignment?.length ?? 0) > 0 &&
                                                                  !isAnswerDone
                                                            ) {
                                                                  new DToast().showErrorToast(
                                                                        'Please answer the following questions'
                                                                  );
                                                                  return;
                                                            }
                                                            completeUnit({
                                                                  courseId: data?.courseId ?? '',
                                                                  unitId: data?._id ?? '',
                                                            });
                                                      }}
                                                />
                                          </div>
                                    </div>
                              )}
                        </div>
                  </div>
            </div>
      );
};

export default NextUnitModal;
