import React from 'react';
import { Text12 } from '../../../../../components/textTitles';

interface QuestionBodyProps {
      question?: string;
      rows?: number;
}

const QuesstionBody: React.FC<QuestionBodyProps> = ({ rows = 2, question }) => {
      return (
            <div>
                  <Text12
                        title='Question : '
                        fontWeight='font-semibold'
                  />
                  <div className='bg-white shadow-xs border rounded-md w-full px-6 py-3 mt-2 min-h-16'>
                        <p className='xl:text-sm lg:text-xs text-xs font-semibold'>{question}</p>
                        {/* <textarea
                              value={question}
                              disabled
                              className='w-full rounded-md outline-none xl:text-sm lg:text-xs text-xs resize-none font-medium'
                              rows={rows}
                        /> */}
                  </div>
            </div>
      );
};

export default QuesstionBody;
