import { RevisionDashboard } from './components/RevisionDashboard';
import { RevisionGraph } from './components/RevisionGraph';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';

const Revision = () => {
      const { t } = useTranslation();

      return (
            <div className='bg-white w-full h-full flex flex-col gap-6 pl-2 mb-4'>
                  <Header
                        titleOne={t('Revision')}
                        titleTwo=''
                  />
                  <div className='flex flex-col gap-10 w-full'>
                        <div style={{ flex: '1 1 auto' }}>
                              <RevisionDashboard />
                        </div>
                        <div>
                              <RevisionGraph />
                        </div>
                  </div>
            </div>
      );
};

export default Revision;
