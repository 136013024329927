interface LoginFormModel {
    email?: string | null,
    password?: string | null
}

export const initialLoginFormState: LoginFormModel = {
    email: null,
    password: null
}

export default LoginFormModel