import { useMutation, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../../../core/api/apisPath";
import { DAxios } from "../../../../core/api/axiosConfig";
import { QueryKey } from "../../../../core/constants/enums";
import { convertToFlashDetailsModel } from "../../../admin/bundle/data/models/flashDetailsModel";

export interface IFlashCardFetchParams {
  isStarred?: boolean;
  searchString?: string;
  tag?: string;
}

export const useGetFilteredCards = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ isStarred, searchString, tag }: IFlashCardFetchParams) => {
      const response = await DAxios.get({
        endpoint: endpoints.getFlashCards,
        params: {
          starred: isStarred === true ? true : undefined,
          search: searchString && searchString.length > 0 ? searchString : undefined,
          tag: tag,
        },
      });
      return response;
    },
    onSuccess: (data) => {
      const flashCards = (data.cards as []).map((element) => convertToFlashDetailsModel(element));
      queryClient.setQueryData([QueryKey.FLASHCARD], flashCards);
    },
  });
};
