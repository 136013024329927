import React from 'react';

interface TextProps {
      title: string;
      fontWeight?: string;
      color?: string;
      lineClamp?: string;
      className?: string;
}

const PrimaryText = ({ title, fontWeight, color }: TextProps) => {
      return (
            <h1
                  className={`xl:text-2xl lg:text-xl md:text-lg  ${fontWeight ? fontWeight : 'font-bold'} ${color ? color : 'text-gray-900'}`}
            >
                  {title}
            </h1>
      );
};

const SecondaryText = ({ title, fontWeight, color }: TextProps) => {
      return (
            <h1
                  className={`xl:text-xl lg:text-lg text-base ${fontWeight ? fontWeight : 'font-bold'} ${color ? color : 'text-gray-900'}`}
            >
                  {title}
            </h1>
      );
};

const TertiaryText = ({ title, fontWeight, color, className }: TextProps) => {
      return (
            <h1
                  className={`xl:text-lg lg:text-base text-sm font-bold ${className}  ${fontWeight ? fontWeight : 'font-bold'} ${color ? color : 'text-gray-900'}`}
            >
                  {title}
            </h1>
      );
};

const Text16 = ({ title, fontWeight, color }: TextProps) => {
      return (
            <h1
                  className={`xl:text-base lg:text-sm text-xs font-bold ${fontWeight ? fontWeight : 'font-medium'} ${color ? color : 'text-gray-900'}`}
            >
                  {title}
            </h1>
      );
};

const Text14 = ({ title, fontWeight, color, lineClamp }: TextProps) => {
      return (
            <h1
                  className={`xl:text-sm lg:text-xs text-xs font-bold ${fontWeight ? fontWeight : 'font-normal'} ${color ? color : 'text-gray-900'}`}
            >
                  {title}
            </h1>
      );
};

const Text12 = ({ title, fontWeight, color, lineClamp }: TextProps) => {
      return (
            <h1
                  className={`xl:text-xs text-[11px] font-bold ${fontWeight ? fontWeight : 'font-normal'} ${color ? color : 'text-gray-900'}`}
            >
                  {title}
            </h1>
      );
};

export { PrimaryText, SecondaryText, TertiaryText, Text16, Text14, Text12 };
