import { useMutation } from "@tanstack/react-query"
import { DAxios } from "../../../../core/api/axiosConfig"
import { endpoints } from "../../../../core/api/apisPath"
import { useAppSelector } from "../../../../core/store/store"
import { DToast } from "../../../../core/utils/toasts"

export const useHandleGrade = (updateIndex: (val: boolean) => void) => {
    const { data } = useAppSelector(state => state.user);
    return useMutation({
        mutationFn: async ({ cardId, grade }: { cardId: string, grade: number }) => {
            return await DAxios.put({
                endpoint: endpoints.revision,
                body: {
                    "userId": data?.userId,
                    cardId,
                    grade,
                }
            })
        },
        onSuccess: (data) => {
            console.log(data)
            updateIndex(true)
        },
        onError: (error) => {
            new DToast().showErrorToast(error.toString())
        }
    })
}