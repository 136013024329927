import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text14 } from '../../../../components/textTitles';
import { Arrow } from '../../../../svgs/manager/svgManager';
import { HelperFunction } from '../../../../core/utils/functions';
import { CourseSectionModel } from '../../data/models/courseSectionModel';
import { DRoutes } from '../../../../core/routes';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const CourseContentItem = ({
      courseSection,
      isEnrolled,
}: {
      courseSection: CourseSectionModel;
      isEnrolled: boolean;
}) => {
      const navigate = useNavigate();
      const [isExpanded, setIsExpanded] = useState(false);
      return (
            <div className='w-full flex gap-4 items-center relative'>
                  <div className='w-full border mb-2 rounded-md flex flex-col'>
                        <div className='w-full flex items-center justify-between px-2 py-3'>
                              <div className='flex gap-3 items-center'>
                                    <button
                                          className='mr-3 ml-2'
                                          onClick={() => {
                                                setIsExpanded((prev) => !prev);
                                          }}
                                    >
                                          <Arrow
                                                rotation={isExpanded ? 90 : 0}
                                                className='mb-[1px]'
                                          />
                                    </button>
                                    <Text14
                                          title={courseSection.name ?? ''}
                                          fontWeight='font-semibold'
                                    />
                              </div>
                              <Text14
                                    title={`${courseSection.unit?.length ?? 0} ${courseSection.unit?.length ?? 0 < 2 ? 'section' : 'sections'}`}
                                    fontWeight='font-medium'
                              />
                        </div>
                        {isExpanded && (
                              <div className='bg-blueSecondary bg-opacity-20 w-full px-11'>
                                    <ul>
                                          {courseSection.unit?.map((unit) => {
                                                return (
                                                      <li
                                                            key={unit._id}
                                                            className='py-2'
                                                      >
                                                            <button
                                                                  className='flex justify-between items-center gap-5 w-full'
                                                                  onClick={() => {
                                                                        if (isEnrolled === true) {
                                                                              navigate(
                                                                                    DRoutes.unit + `?unitId=${unit._id}`
                                                                              );
                                                                        }
                                                                  }}
                                                            >
                                                                  <div className='flex items-center gap-5 hover:underline'>
                                                                        {HelperFunction.getCourseUnitTypeIcon(
                                                                              unit.type
                                                                        )}
                                                                        <Text14
                                                                              title={unit.name ?? ''}
                                                                              fontWeight='font-medium'
                                                                        />
                                                                  </div>
                                                                  <div>
                                                                        {isEnrolled === true
                                                                              ? unit.isCompleted === true && (
                                                                                      <IoIosCheckmarkCircle
                                                                                            color='green'
                                                                                            size={24}
                                                                                      />
                                                                                )
                                                                              : ''}
                                                                  </div>
                                                            </button>
                                                      </li>
                                                );
                                          })}
                                    </ul>
                              </div>
                        )}
                  </div>
            </div>
      );
};

export default CourseContentItem;
