import { useInfiniteQuery } from "@tanstack/react-query";
import { DAxios } from "../../../../core/api/axiosConfig";
import { endpoints } from "../../../../core/api/apisPath";
import { QueryKey } from "../../../../core/constants/enums";
import { BundleResponseModel } from "../models/bundle.model";

export const useGetBundle = () => {
    // return useQuery({
    //     queryKey: [QueryKey.BUNDLE],
    //     queryFn: async () => {
    //         const data = await DAxios.get({ endpoint: endpoints.getBundle });
    //         const bundlesResponse = BundleResponseModel.fromJson(data.bundles);
    //         return bundlesResponse;
    //     },
    //     refetchInterval: false,
    // });
    return useInfiniteQuery<BundleResponseModel>({
        queryKey: [QueryKey.BUNDLE],
        queryFn: async ({ pageParam = 1 }) => {
          const data = await DAxios.get({
            endpoint: endpoints.getBundle,
            params: { page: pageParam },
          });
    
          return BundleResponseModel.fromJson(data.bundles);
        },
        getNextPageParam: (lastPage, allPages) => {
          if ((lastPage?.bundles.length ?? 0) < 10) return undefined;
          return allPages.length + 1;
        },
        initialPageParam: 1,
        refetchInterval: false,
        refetchOnMount: false
      });
};
