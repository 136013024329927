export const NetworkIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.60988 11.3262C11.0145 11.2773 12.4143 11.5166 13.723 12.0293C15.0316 12.5423 16.2204 13.3172 17.2178 14.3077C18.2149 15.2981 18.9986 16.4822 19.5207 17.7874C20.0426 19.0924 20.292 20.4907 20.2527 21.8957M10.8489 3.70148C13.0965 3.62298 15.3362 4.00581 17.43 4.82641C19.5238 5.64698 21.4283 6.88911 23.0241 8.47378C24.6198 10.0584 25.873 11.9525 26.7083 14.0407C27.5435 16.1287 27.9425 18.3636 27.8797 20.6118M8.51286 23.0633C7.70924 22.2596 7.70924 20.9568 8.51285 20.1532C9.31633 19.3497 10.6193 19.3496 11.4229 20.1532C12.2266 20.9568 12.2264 22.2598 11.4229 23.0633C10.6193 23.8669 9.31647 23.8669 8.51286 23.0633Z"
        stroke="#7573FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NetworkIcon;
