const ContentIcon = ({ className }: { className?: string }) => {
      return (
            <svg
                  className={`${className ?? 'h-[45px] w-[45px]'}`}
                  viewBox='0 0 56 56'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
            >
                  <g clip-path='url(#clip0_152_126)'>
                        <rect
                              width='56'
                              height='56'
                              rx='2'
                              fill='#7573FF'
                        />
                        <g clip-path='url(#clip1_152_126)'>
                              <path
                                    d='M35 38H21C20.2044 38 19.4413 37.6839 18.8787 37.1213C18.3161 36.5587 18 35.7956 18 35V19C18 18.7348 18.1054 18.4804 18.2929 18.2929C18.4804 18.1054 18.7348 18 19 18H33C33.2652 18 33.5196 18.1054 33.7071 18.2929C33.8946 18.4804 34 18.7348 34 19V31H38V35C38 35.7956 37.6839 36.5587 37.1213 37.1213C36.5587 37.6839 35.7956 38 35 38ZM34 33V35C34 35.2652 34.1054 35.5196 34.2929 35.7071C34.4804 35.8946 34.7348 36 35 36C35.2652 36 35.5196 35.8946 35.7071 35.7071C35.8946 35.5196 36 35.2652 36 35V33H34ZM32 36V20H20V35C20 35.2652 20.1054 35.5196 20.2929 35.7071C20.4804 35.8946 20.7348 36 21 36H32ZM22 23H30V25H22V23ZM22 27H30V29H22V27ZM22 31H27V33H22V31Z'
                                    fill='white'
                              />
                        </g>
                  </g>
                  <defs>
                        <clipPath id='clip0_152_126'>
                              <rect
                                    width='56'
                                    height='56'
                                    fill='white'
                              />
                        </clipPath>
                        <clipPath id='clip1_152_126'>
                              <rect
                                    width='24'
                                    height='24'
                                    fill='white'
                                    transform='translate(16 16)'
                              />
                        </clipPath>
                  </defs>
            </svg>
      );
};

export default ContentIcon;
