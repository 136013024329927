import { useNavigate } from 'react-router-dom';
import { RevisionIcon } from '../../../svgs/revisionIcon';
import TimeSlot from './timeSlot';
import { useTranslation } from 'react-i18next';
import { TodayIcon } from '../../../svgs/todayIcon';
import { CalenderIcon } from '../../../svgs/calenderIcon';
import { Last15DaysIcon } from '../../../svgs/last15DaysIcon';
import { Last30DaysIcon } from '../../../svgs/last30DaysIcon';
import { Text16 } from '../../../components/textTitles';
import { DRoutes } from '../../../core/routes';

const Revision = () => {
      const { t } = useTranslation();
      const navigate = useNavigate();
      const TimeSlots = [
            {
                  title: t('Today'),
                  filter: 'today',
                  icon: <TodayIcon />,
            },
            {
                  title: t('Last Week'),
                  filter: 'last-week',
                  icon: <CalenderIcon />,
            },
            {
                  title: t('Last 15 Days'),
                  filter: 'last-15-days',
                  icon: <Last15DaysIcon />,
            },
            {
                  title: t('Last 30 Days'),
                  filter: 'last-30-days',
                  icon: <Last30DaysIcon />,
            },
      ];

      return (
            <div className='flex flex-col gap-4 min-w-64 w-[35%] h-[360px] rounded-3xl shadow-primary p-5'>
                  <div className='flex items-center gap-3'>
                        <RevisionIcon />
                        <Text16
                              title={t('Quick Revision')}
                              fontWeight='font-semibold'
                        />
                  </div>
                  <div className='flex flex-col xl:gap-5 gap-4'>
                        {TimeSlots.map((slot) => {
                              return (
                                    <TimeSlot
                                          slot={slot.title}
                                          icon={slot.icon}
                                          onClick={() => navigate(`/revision/${DRoutes.reviseUserCards}?category=${slot.filter}`)}
                                    />
                              );
                        })}
                  </div>
            </div>
      );
};

export default Revision;
