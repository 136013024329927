import axios from "axios";
import { getToken } from "../../../../core/utils/functions";
import { RevisionActions, RevisionActionTypes } from "../../presentation/reducer/revisionReducer";
import { updateFlashGradeApi } from "../../../../core/api/apisPath";

export const updateUserFlashGrade = async ({ flashId, grade, dispatch }: { flashId: string, grade: number, dispatch: React.Dispatch<RevisionActions> }) => {
    try {
        dispatch({ type: RevisionActionTypes.REVISION_LOADING })
        const token = getToken()
        const response = await axios.put(updateFlashGradeApi,
            {
                "cardId": flashId,
                "grade": grade
            },
            {
                headers: {
                    Authorization: "Bearer " + token
                }
            })

        dispatch({ type: RevisionActionTypes.REVISION_CRUD, revisionCRUDPayload: true })
        return true;
    } catch (error: any) {
        dispatch({ type: RevisionActionTypes.REVISION_DATA_FAILURE, error: error.message })
    }
}