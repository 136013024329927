interface Enrollment {
    _id: string;
    completedUnits: string[];
    courseId?: string;
    userId: string;
}

interface UnitData {
    enrollment?: Enrollment;
    resumableUnitId?: string;
}

export function convertToEnrolledCourseMeta(data: any): UnitData {
    return {
        enrollment: {
            _id: data?.enrollment?._id ?? "",
            completedUnits: data?.enrollment?.completedUnits ?? [],
            courseId: data?.enrollment?.courseId ?? "",
            userId: data?.enrollment?.userId ?? ""
        },
        resumableUnitId: data?.unitId ?? ""
    };
}
