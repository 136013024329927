export const RevisionIconSidebar = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <div className={`h-[56px] w-[56px] flex justify-center items-center rounded-[50%] ${isSelected ? "bg-bluePrimary" : "bg-whitePrimary"}`}>
      {
        isSelected ?
          <svg
            width="54"
            height="54"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31.5" cy="31.5" r="31.5" fill="#7573FF" />
            <path
              d="M43.5231 43.074H25.8863C24.9732 43.074 24.2329 42.3337 24.2329 41.4206V17.17C24.2329 16.2568 24.9732 15.5166 25.8863 15.5166H43.5231C44.4363 15.5166 45.1765 16.2568 45.1765 17.17V41.4206C45.1765 42.3337 44.4363 43.074 43.5231 43.074Z"
              fill="#C7CFE2"
            />
            <path
              d="M45.1765 32.051H24.2329V17.17C24.2329 16.2568 24.9732 15.5166 25.8863 15.5166H43.5231C44.4363 15.5166 45.1765 16.2568 45.1765 17.17V32.051Z"
              fill="white"
            />
            <path
              d="M41.3184 45.2786H23.6817C22.7686 45.2786 22.0283 44.5383 22.0283 43.6252V19.3746C22.0283 18.4614 22.7686 17.7212 23.6817 17.7212H41.3185C42.2317 17.7212 42.9719 18.4614 42.9719 19.3746V43.6252C42.9719 44.5383 42.2317 45.2786 41.3184 45.2786Z"
              fill="#D5DCED"
            />
            <path
              d="M42.972 34.2556H22.0283V19.3746C22.0283 18.4614 22.7686 17.7212 23.6817 17.7212H41.3185C42.2317 17.7212 42.9719 18.4614 42.9719 19.3746L42.972 34.2556Z"
              fill="white"
            />
            <path
              d="M39.1139 47.4832H21.4771C20.564 47.4832 19.8237 46.7429 19.8237 45.8298V21.5792C19.8237 20.666 20.564 19.9258 21.4771 19.9258H39.1139C40.0271 19.9258 40.7673 20.666 40.7673 21.5792V45.8298C40.7673 46.7429 40.0271 47.4832 39.1139 47.4832Z"
              fill="#E4EAF8"
            />
            <path
              d="M40.7673 36.4602H19.8237V21.5792C19.8237 20.666 20.564 19.9258 21.4771 19.9258H39.1139C40.0271 19.9258 40.7673 20.666 40.7673 21.5792V36.4602Z"
              fill="white"
            />
            <path
              d="M43.5231 15H25.8864C24.6897 15 23.7162 15.9736 23.7162 17.1701V17.2046H23.6818C22.4852 17.2046 21.5117 18.1781 21.5117 19.3747V19.4091H21.4773C20.2806 19.4091 19.3071 20.3827 19.3071 21.5792V45.8299C19.3071 47.0265 20.2807 48 21.4773 48H39.114C40.3107 48 41.2842 47.0264 41.2842 45.8299V45.7954H41.3186C42.5152 45.7954 43.4887 44.8219 43.4887 43.6253V43.5909H43.5231C44.7198 43.5909 45.6933 42.6173 45.6933 41.4208V17.1701C45.6933 15.9736 44.7198 15 43.5231 15ZM24.7496 17.1701C24.7496 16.5433 25.2596 16.0334 25.8864 16.0334H43.5231C44.1499 16.0334 44.6599 16.5433 44.6599 17.1701V31.5344H43.4887V19.3748C43.4887 18.1781 42.5152 17.2046 41.3186 17.2046H24.7496V17.1701ZM22.5451 19.3748C22.5451 18.7479 23.055 18.238 23.6818 18.238H41.3186C41.9454 18.238 42.4553 18.7479 42.4553 19.3748V33.739H41.2842V21.5793C41.2842 20.3827 40.3106 19.4092 39.114 19.4092H22.5451V19.3748ZM21.4772 20.4426H39.114C39.7408 20.4426 40.2507 20.9526 40.2507 21.5794V35.9437H20.3404V21.5793C20.3404 20.9526 20.8504 20.4426 21.4772 20.4426ZM40.2507 45.8299C40.2507 46.4567 39.7408 46.9666 39.114 46.9666H21.4772C20.8504 46.9666 20.3404 46.4567 20.3404 45.8299V36.977H40.2507L40.2507 45.8299ZM42.4553 43.6252C42.4553 44.2521 41.9453 44.762 41.3185 44.762H41.2841V34.7724H42.4553V43.6252ZM43.5231 42.5574H43.4887V32.5678H44.6599V41.4206C44.6599 42.0474 44.1499 42.5574 43.5231 42.5574Z"
              fill="#7573FF"
            />
            <path
              d="M22.4411 33.6516C22.7161 33.7279 23.0009 33.5669 23.0773 33.292L23.7376 30.9146H29.6883L30.3487 33.2919C30.4122 33.5207 30.6199 33.6705 30.8463 33.6705C30.8921 33.6705 30.9386 33.6644 30.9849 33.6516C31.2598 33.5752 31.4208 33.2904 31.3445 33.0154L28.813 23.9021C28.6191 23.2039 27.9777 22.7163 27.2531 22.7163H26.1731C25.4485 22.7163 24.8071 23.2039 24.6131 23.902L22.0815 33.0154C22.0051 33.2904 22.1661 33.5752 22.4411 33.6516ZM25.6088 24.1786C25.6789 23.9261 25.9109 23.7497 26.173 23.7497H27.253C27.5151 23.7497 27.7471 23.926 27.8172 24.1786L29.4013 29.8812H24.0246L25.6088 24.1786Z"
              fill="#7573FF"
            />
            <path
              d="M34.9803 33.6699C35.7511 33.6699 36.4546 33.3772 36.9862 32.8974C37.1148 33.3428 37.5253 33.6699 38.0116 33.6699C38.2969 33.6699 38.5283 33.4386 38.5283 33.1532C38.5283 32.8678 38.297 32.6365 38.0116 32.6365C37.9926 32.6365 37.9772 32.621 37.9772 32.602V30.673V30.1219V27.6417C37.9772 27.3564 37.7458 27.125 37.4604 27.125C37.1751 27.125 36.9437 27.3563 36.9437 27.6417V27.8607C36.4174 27.4031 35.7308 27.125 34.9803 27.125C33.3278 27.125 31.9834 28.4694 31.9834 30.1219V30.673C31.9834 32.3255 33.3278 33.6699 34.9803 33.6699ZM33.0168 30.1219C33.0168 29.0392 33.8977 28.1584 34.9803 28.1584C36.063 28.1584 36.9437 29.0393 36.9437 30.1219V30.673C36.9437 31.7557 36.0629 32.6365 34.9803 32.6365C33.8976 32.6365 33.0168 31.7556 33.0168 30.673V30.1219Z"
              fill="#7573FF"
            />
            <path
              d="M37.4604 39.2505H23.1305C22.8452 39.2505 22.6138 39.4818 22.6138 39.7672C22.6138 40.0525 22.8451 40.2839 23.1305 40.2839H37.4604C37.7457 40.2839 37.9771 40.0526 37.9771 39.7672C37.9771 39.4818 37.7458 39.2505 37.4604 39.2505Z"
              fill="#7573FF"
            />
            <path
              d="M31.9489 43.6597H23.1305C22.8452 43.6597 22.6138 43.891 22.6138 44.1764C22.6138 44.4617 22.8451 44.6931 23.1305 44.6931H31.9488C32.2342 44.6931 32.4656 44.4618 32.4656 44.1764C32.4656 43.891 32.2342 43.6597 31.9489 43.6597Z"
              fill="#7573FF"
            />
            <path
              d="M23.1305 42.4885H26.4372C26.7225 42.4885 26.9539 42.2572 26.9539 41.9718C26.9539 41.6865 26.7226 41.4551 26.4372 41.4551H23.1305C22.8452 41.4551 22.6138 41.6864 22.6138 41.9718C22.6138 42.2571 22.8452 42.4885 23.1305 42.4885Z"
              fill="#7573FF"
            />
            <path
              d="M37.4601 41.4551H28.6417C28.3564 41.4551 28.125 41.6864 28.125 41.9718C28.125 42.2571 28.3563 42.4885 28.6417 42.4885H37.4601C37.7454 42.4885 37.9768 42.2572 37.9768 41.9718C37.9768 41.6864 37.7455 41.4551 37.4601 41.4551Z"
              fill="#7573FF"
            />
          </svg>
          :
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.523 0H9.88619C8.68955 0 7.71605 0.973564 7.71605 2.17014V2.20455H7.68164C6.485 2.20455 5.5115 3.17812 5.5115 4.37469V4.40911H5.47708C4.28045 4.40911 3.30695 5.38267 3.30695 6.57925V30.8297C3.30695 32.0264 4.28051 32.9999 5.47708 32.9999H23.1138C24.3105 32.9999 25.284 32.0263 25.284 30.8297V30.7953H25.3184C26.515 30.7953 27.4885 29.8218 27.4885 28.6252V28.5908H27.523C28.7196 28.5908 29.6931 27.6172 29.6931 26.4206V2.17014C29.6931 0.973564 28.7196 0 27.523 0ZM24.2505 30.8299C24.2505 31.4567 23.7406 31.9666 23.1138 31.9666H5.47702C4.85021 31.9666 4.34026 31.4567 4.34026 30.8299V21.977H24.2505L24.2505 30.8299ZM24.2505 20.9437H4.34026V6.57931C4.34026 5.9525 4.85021 5.44255 5.47702 5.44255H23.1138C23.7406 5.44255 24.2505 5.9525 24.2505 6.57931V20.9437ZM26.4551 28.6252C26.4551 29.2521 25.9451 29.762 25.3183 29.762H25.2839V19.7724H26.4551V28.6252ZM26.4551 18.739H25.2839V6.57931C25.2839 5.38267 24.3104 4.40917 23.1138 4.40917H6.54488V4.37476C6.54488 3.74795 7.05483 3.238 7.68164 3.238H25.3184C25.9452 3.238 26.4552 3.74795 26.4552 4.37476L26.4551 18.739ZM28.6597 26.4207C28.6597 27.0475 28.1498 27.5575 27.523 27.5575H27.4885V17.5679H28.6597V26.4207ZM28.6597 16.5344H27.4885V4.37476C27.4885 3.17812 26.515 2.20462 25.3184 2.20462H8.74943V2.17014C8.74943 1.54333 9.25938 1.03338 9.88619 1.03338H27.523C28.1498 1.03338 28.6597 1.54333 28.6597 2.17014V16.5344Z" fill="#7573FF" />
            <path d="M15.3443 18.0152L12.8129 8.90181C12.619 8.20365 11.9776 7.71606 11.253 7.71606H10.1729C9.44836 7.71606 8.80693 8.20365 8.61299 8.90174L6.0814 18.0152C6.00508 18.2901 6.16602 18.5749 6.44104 18.6513C6.71607 18.7276 7.00082 18.5666 7.0772 18.2917L7.73752 15.9144H13.6883L14.3486 18.2917C14.4122 18.5204 14.6199 18.6703 14.8462 18.6703C14.892 18.6703 14.9385 18.6641 14.9848 18.6513C15.2598 18.5749 15.4208 18.2901 15.3443 18.0152ZM8.02459 14.881L9.60872 9.17831C9.67885 8.92585 9.91088 8.74944 10.1729 8.74944H11.253C11.5151 8.74944 11.7471 8.92578 11.8171 9.17831L13.4012 14.881H8.02459Z" fill="#7573FF" />
            <path d="M22.0115 17.6367C21.9924 17.6367 21.977 17.6213 21.977 17.6023V15.6733V15.1221V12.642C21.977 12.3566 21.7457 12.1252 21.4603 12.1252C21.1749 12.1252 20.9436 12.3566 20.9436 12.642V12.861C20.4173 12.4033 19.7307 12.1252 18.9801 12.1252C17.3276 12.1252 15.9832 13.4696 15.9832 15.1221V15.6733C15.9832 17.3257 17.3276 18.6701 18.9801 18.6701C19.7509 18.6701 20.4545 18.3775 20.986 17.8976C21.1146 18.343 21.5252 18.6701 22.0114 18.6701C22.2967 18.6701 22.5281 18.4388 22.5281 18.1534C22.5281 17.868 22.2968 17.6367 22.0115 17.6367ZM20.9437 15.6732C20.9437 16.7559 20.0628 17.6366 18.9802 17.6366C17.8975 17.6366 17.0167 16.7558 17.0167 15.6732V15.1221C17.0167 14.0394 17.8975 13.1586 18.9802 13.1586C20.0628 13.1586 20.9437 14.0394 20.9437 15.1221V15.6732Z" fill="#7573FF" />
            <path d="M21.4603 24.2505H7.13046C6.84513 24.2505 6.61374 24.4818 6.61374 24.7672C6.61374 25.0525 6.84506 25.2839 7.13046 25.2839H21.4603C21.7457 25.2839 21.977 25.0526 21.977 24.7672C21.977 24.4818 21.7457 24.2505 21.4603 24.2505Z" fill="#7573FF" />
            <path d="M15.9489 28.6597H7.13046C6.84513 28.6597 6.61374 28.891 6.61374 29.1764C6.61374 29.4617 6.84506 29.6931 7.13046 29.6931H15.9488C16.2341 29.6931 16.4655 29.4618 16.4655 29.1764C16.4656 28.891 16.2342 28.6597 15.9489 28.6597Z" fill="#7573FF" />
            <path d="M10.4372 26.4551H7.13046C6.84513 26.4551 6.61374 26.6864 6.61374 26.9718C6.61374 27.2571 6.84506 27.4885 7.13046 27.4885H10.4372C10.7225 27.4885 10.9539 27.2572 10.9539 26.9718C10.9539 26.6864 10.7226 26.4551 10.4372 26.4551Z" fill="#7573FF" />
            <path d="M21.4603 26.4551H12.642C12.3566 26.4551 12.1252 26.6864 12.1252 26.9718C12.1252 27.2571 12.3566 27.4885 12.642 27.4885H21.4603C21.7456 27.4885 21.977 27.2572 21.977 26.9718C21.977 26.6864 21.7457 26.4551 21.4603 26.4551Z" fill="#7573FF" />
          </svg>

      }
    </div>

  );
};
