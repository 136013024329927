import { useEffect, useReducer, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FrontCard } from './FrontCard';
import { BackCard } from './BackCard';
import { GradeSelector } from './GradeSelector';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useAppSelector } from '../../../core/store/store';
import { getToken } from '../../../core/utils/functions';
import bundleReducer, { initialBundleReducerState } from '../../admin/bundle/presentation/reducer/bundleReducer';
import { updateFlash } from '../../admin/bundle/data/repository/updateFlash';
import { convertToFlashFormModel } from '../../admin/bundle/data/models/flashFormModel';

const CardPreview = ({
      card,
      getCards,
      updateIndex,
      setShowCardPreview,
}: {
      card: any;
      getCards: any;
      updateIndex: any;
      setShowCardPreview: any;
}) => {
      const [isFlipped, setIsFlipped] = useState<boolean>(false);
      const { data } = useAppSelector((state) => state.user);
      const [state, dispatch] = useReducer(bundleReducer, initialBundleReducerState);

      useEffect(() => {
            if (state.bundleCUD) {
                  card.isStarred = !card.isStarred;
                  setShowCardPreview(true);
                  toast.success(
                        !card.isStarred ? 'Card has been removed from starred' : 'Card has been added to starred'
                  );
            }
      }, [state.bundleCUD]);

      // const handleUpdateStarred = async (card: any) => {
      //   try {
      //     console.log(card._id)
      //     const token = getToken()
      //     await axios.post(
      //       `${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/star-card`,
      //       {
      //         cardId: card._id,
      //         userId: data?.userId,
      //         isStarred: !card.isStarred,
      //       },
      //       {
      //         headers: {
      //           Authorization: "Bearer " + token
      //         }
      //       }
      //     );

      //     card.isStarred = !card.isStarred;
      //     setShowCardPreview(true);
      //     toast.success(
      //       !card.isStarred
      //         ? "Card has been removed from starred"
      //         : "Card has been added to starred"
      //     );
      //   } catch {
      //     console.log("error");
      //   }
      // };

      return (
            <div className='bg-white w-full h-full flex gap-10 rounded-3xl items-center justify-center shadow-primary'>
                  {/* <div className="flex flex-col gap-6 items-center"> */}
                  <ReactCardFlip
                        isFlipped={isFlipped}
                        flipDirection='horizontal'
                  >
                        <FrontCard
                              key='front'
                              card={card}
                              setIsFlipped={setIsFlipped}
                              setShowCardPreview={setShowCardPreview}
                              handleUpdateStarred={handleUpdateStarred}
                        />
                        <BackCard
                              key='back'
                              card={card}
                              setIsFlipped={setIsFlipped}
                              setShowCardPreview={setShowCardPreview}
                              handleUpdateStarred={handleUpdateStarred}
                              updateIndex={updateIndex}
                        />
                  </ReactCardFlip>
                  {/* {isFlipped && <GradeSelector handleGrade={handleGrade} card={card} />} */}
                  <ToastContainer />
                  {/* </div> */}
            </div>
      );

      function handleUpdateStarred() {
            updateFlash({
                  flashId: card._id,
                  formData: convertToFlashFormModel(card),
                  userId: data?.userId,
                  dispatch,
                  isStarred: !card.isStarred,
            });
      }
};

export default CardPreview;
