const AssignmentIcon = ({ className }: { className?: string }) => {
      return (
            <div className={`${className ?? 'h-[20px] w-[20px]'}`}>
                  <svg
                        viewBox='0 0 21 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                  >
                        <rect
                              width='21'
                              height='21'
                              fill='url(#pattern0_218_4044)'
                        />
                        <defs>
                              <pattern
                                    id='pattern0_218_4044'
                                    patternContentUnits='objectBoundingBox'
                                    width='1'
                                    height='1'
                              >
                                    <use
                                          href='#image0_218_4044'
                                          transform='scale(0.0111111)'
                                    />
                              </pattern>
                              <image
                                    id='image0_218_4044'
                                    width='90'
                                    height='90'
                                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAELklEQVR4nO3dy4scVRQG8BtM1KAL0YAGRYKIikuN7lwYwZUuOyAMdKDu99VMjxNpUKKgNCgoJrgR3Ag+yCL/QBZBQhYJeYj4wEVwoQvFJxIF4yMmYloO6dZLc7utZG7dR3E+uDDTt7r6zI/L6ZmqrhpjNBqNRqPRaIoJyU0kVwAcB/AzyXHCcY7kMQAPmC6F5FaSHybGHc8Bf9R0Ib1e72qSH2WAOu40NsnVDDDHnccm+X4GkOPOY5M8mwHi2Bm/dxI7A9ixO6y1DwH4dd48gD+ttY+Z0pIaljNDauokdo7QncTOFbpz2DlDdwo7d+jOYJcA3QnsUqCLxy4Jumjs0qCLxS4RukjsUqGLw3aKkoJfJnnfYDC4XoZ8TfIVkr/lCC2x1u4o4kDUpJjPq6q6c942Mkfyixyhi8GWlbwIeRpr7V0xVvaV/hxNsOu6ftCkirSGptsC2BthVW9tEfuESZXl5eW7m25L8p4IZ8ifWc/Pswhb3hxNKRkMBreQfK8taMEA8HRVVTe3gW0y+mzHqwC+J/klyRdHo9HG2e1Go9FGku9GaCPBh8khJPd5ivP28F6vdxXJg6nhSoX+2lPcd/O2X11dvUnmU+MVA722tnYNyT0kL3qKuwhg2O/3r/U9t67rKjVeEdCCDOBogyKPDIfDzXP69bepAbOHJvn8ZRS6x7cPAK+lBswKWlYkgKdIHpZVuOiAjG8AOO3brxy4SQ2YDXRd19tIfrbOQs/79k3y9tSAWUBP+vDptgodDoebUwNmAT1pF+4LypvX48aYDaEKZQaIyaFJfjV9IenLKysrd1wpkFFof+SYwQzWW+tZiUahFx60d7FeUOg4K3qfO68rOlDkLzcAfzug+915hQ4Ykj86oMdm5rRHh4qcwnFAf5iZU+hQAfC2C9rv92+Yzil0wJB8dgZ0uzOnKzpUrLU7XVD5fjqn0AFjrb3fBQXw3HROoQNGevIM6BvTOYUOnMnHoqYr+oDzuPbokHFPogI45Dyu0CFD8lMH9bDzuEKHjNz0ZHKW+4L+1tFyrLW3yUe63Md0RRcWZnD2JPkZlhhhBogKzfTAuqKp0No6cgwzaAtZt45QhTIDRIVmemBd0VRobR0hoz06UhQ6UhQ6UhQ6UhQ6UhQ6UhQ6UhQ6UhQ6UhQ6UhQ6UgD8st6jX7x0n49xAeNsLFcf0uXcc+Mn3z7qur41A8Qm41R84f+Q7gXwV5NCAbzk24dcHJoBYpP6nzQpA+D1BkV+Izcd9D2f5DupERvU/7H8cx+TMlVV3QjgzP8Uu7SgbSy6BVoO4xOp0+QQAIMFq+GkXDc+53kHMoD01fwHyQ9I7k6+kj13kjnlK1j6uNGEC8kt0sscZLkQ9GE1biFyUb6zoi+08RqaS9BvOtDnFKWl1HX9hO8SDE0LIfmItXbX0tLSdQps/s0/ewDT4pc/pgoAAAAASUVORK5CYII='
                              />
                        </defs>
                  </svg>
            </div>
      );
};

export default AssignmentIcon;
