export const SearchIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.8893 18.2646C5.33866 18.2646 1.64969 14.64 1.64969 10.1617C1.64969 5.6834 5.33866 2.0516 9.8893 2.0516C14.4399 2.0516 18.1297 5.6834 18.1297 10.1617C18.1297 14.64 14.4399 18.2646 9.8893 18.2646ZM23.0768 22.4607L17.1016 16.5789C18.6657 14.8787 19.6273 12.636 19.6273 10.1617C19.6273 4.86588 15.2676 0.575684 9.8893 0.575684C4.51101 0.575684 0.151367 4.86588 0.151367 10.1617C0.151367 15.4503 4.51101 19.7405 9.8893 19.7405C12.2131 19.7405 14.3445 18.9374 16.0186 17.599L22.0176 23.5025C22.3106 23.7919 22.7845 23.7919 23.0768 23.5025C23.3698 23.2203 23.3698 22.7501 23.0768 22.4607Z"
        fill="#7573FF"
      />
    </svg>
  );
};
