import { useState } from 'react';
import { Text14 } from '../../../../components/textTitles';
import { CourseSectionUnitModel } from '../../data/models/courseSectionUnitModel';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const CourseUnitTypeContent = ({ content }: { content: CourseSectionUnitModel }) => {
      const [currentPage, setCurrentPage] = useState(0);

      return (
            <section className='flex h-full mt-2'>
                  <section className='h-full w-9/12 relative'>
                        <SunEditor
                              key={currentPage}
                              setContents={content.unitDetails[currentPage].text}
                              height='90vh'
                              width='100%'
                              readOnly={true}
                              hideToolbar={true}
                              disable
                              placeholder='Start typing..'
                        />
                        <div className='border-2 border-whitePrimary h-[100vh] w-full absolute top-0'></div>
                  </section>
                  <section className='w-[25%] overflow-y-scroll no-scrollbar h-full flex flex-col items-end gap-3'>
                        <section className='w-full h-full flex flex-col items-end'>
                              {content.unitDetails.map((_, index) => (
                                    <button
                                          key={index}
                                          onClick={() => {
                                                setCurrentPage(index);
                                          }}
                                          className={`relative xl:w-[70%] lg:w-[80%] aspect-[4/3]
                                rounded-md bg-blueSecondary flex justify-center items-center mb-3 
                                ${index === currentPage ? 'bg-opacity-100' : 'bg-opacity-50 '}`}
                                    >
                                          <Text14 title={`Page ${index + 1}`} />
                                    </button>
                              ))}
                        </section>
                  </section>
            </section>
      );
};

export default CourseUnitTypeContent;
