import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export class DToast {

    customStyles: { [key: string]: ToastOptions } = {
        success: {
            style: {},
        },
        error: {
            style: {},
        },
    };

    // Success toast
    showSuccessToast = (message: string) => {
        toast.success(message, {
            ...this.customStyles.success,
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    // Error toast
    showErrorToast = (message: string) => {
        toast.error(message, {
            ...this.customStyles.error,
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    // General toast
    showToast = (message: string, type: "default" | "info" | "success" | "warning" | "error" = "default") => {
        toast(message, {
            type,
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };
}
