import { FilterIcon } from "../../svgs/manager/svgManager";

export const adminCardsTableHeaderItems = [
    {
        name: "English",
        icon: <FilterIcon />
    },
    {
        name: "English Text",
        icon: <FilterIcon />
    },
    {
        name: "Norwegian",
        icon: <FilterIcon />
    },
    {
        name: "Norwegian Text",
        icon: <FilterIcon />
    },
    {
        name: "Audio",
    },
    {
        name: "Notes",
    }
]

export const userCardsTableHeaderItems = [
    {
        name: "Norwegian",
    },
    {
        name: "English",
        icon: <FilterIcon />
    },
    {
        name: "Tags",
        icon: <FilterIcon />,
        type: "dropdown"
    },
    {
        name: "Date Modified",
        icon: <FilterIcon />
    },
    {
        name: "Notes",
    }
]

export const userSubBundleLibHeaderItems = [
    {
        name: "Name of sub-bundle",
        icon: <FilterIcon />
    },
    {
        name: "Description",
    },
    {
        name: "Status",
        icon: <FilterIcon />
    },
    {
        name: "Action",
        icon: <FilterIcon />
    }
]