import { useMutation } from '@tanstack/react-query';
import { DAxios } from '../../../../core/api/axiosConfig';
import { endpoints } from '../../../../core/api/apisPath';
import { DToast } from '../../../../core/utils/toasts';
export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      await DAxios.post({
        endpoint: endpoints.updatePassword,
        body: {
          oldPassword: currentPassword,
          newPassword: newPassword,
        },
      });
    },
    onSuccess: () => {
      new DToast().showSuccessToast('Password reset successful');
    },
    onError: (error) => {
      new DToast().showErrorToast(error.message);
    },
  });
};
