import { BeatLoader } from 'react-spinners';

interface PrimaryButtonProps {
      text: String;
      loading?: boolean;
      disabled?: boolean;
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ text, loading, disabled, onClick }) => {
      return (
            <button
                  onClick={loading !== true && !disabled ? onClick : undefined}
                  className={`px-5 h-12 font-bold text-white bg-bluePrimary rounded-xl 
                    transition duration-200 hover:bg-blueSecondary hover:text-whitePrimary ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                  {loading === true ? <BeatLoader color='white' /> : text}
            </button>
      );
};

export default PrimaryButton;
