import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { store } from './core/store/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
      defaultOptions: {
            queries: {
                  retry: false,
                  refetchOnWindowFocus: false,
            },
      },
});

root.render(
      <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                  <Provider store={store}>
                        <App />
                  </Provider>
            </QueryClientProvider>
      </BrowserRouter>
);
