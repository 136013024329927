import { Navigate, Outlet } from 'react-router-dom';
import useAuthGuard from '../core/guard/authGuard';
import { DRoutes } from '../core/routes';
import { BeatLoader } from 'react-spinners';
import AdminSidebar from '../components/adminSidebar/admin.sidebar';
import { UserRoleEnum } from '../core/constants/enums';

const PrivateRoute = () => {
      const { isPending, isAuthenticated } = useAuthGuard();

      if (isPending) {
            return <BeatLoader color='bg-bluePrimary' />;
      }

      if (!isAuthenticated) {
            return <Navigate to={DRoutes.login} />;
      }

      return (
            <section className='w-screen h-screen flex pl-10 md:pt-8 lg:pt-6 pt-3'>
                  <AdminSidebar userRole={UserRoleEnum.STUDENT} />
                  <div className='w-full h-full overflow-y-auto pl-8'>
                        <div className='pr-6 w-full h-full mb-4'>
                              <Outlet />
                        </div>
                  </div>
            </section>
      );
};

export { PrivateRoute };
