const PresentationIcon = ({ className }: { className?: string }) => {
      return (
            <div className={`${className ?? 'h-[20px] w-[20px]'}`}>
                  <svg
                        width='18'
                        height='21'
                        viewBox='0 0 18 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                  >
                        <rect
                              width='18'
                              height='21'
                              fill='url(#pattern0_218_4042)'
                        />
                        <defs>
                              <pattern
                                    id='pattern0_218_4042'
                                    patternContentUnits='objectBoundingBox'
                                    width='1'
                                    height='1'
                              >
                                    <use
                                          href='#image0_218_4042'
                                          transform='matrix(0.0111111 0 0 0.00952381 0 0.0714286)'
                                    />
                              </pattern>
                              <image
                                    id='image0_218_4042'
                                    width='90'
                                    height='90'
                                    href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMklEQVR4nO2czYoTQRRGewT/nmF8rayzyT2NYoQ8QV5AGGTwkXR0pU/hz0IZEZdS0rhRmE6nepLcTuYcqG3THD6+qrqLahoREREREZFjYLVaPY6IC+ATUFyMcfAFeNW57BUNvFYuuwrY5Y2S1+v1vYj4oWh2IjoivjdNc3aT6zPgWtHsKtHf+kR31XGpaHaV6IvBzTAiviqcsYI/Ay/n8/mj4eOHiIiIyMGIiN8e8ag+3nU3bEWz/0GZotm/ZEVzGMmKRtHl1DZcOxpFl+wUmmjyxVkd5Eu1o1F0yU6ciSZfktVBvkA7mmktLywoumSn0ESTL87qIF+qHY2iS3biTDT5kqwO8gXa0UxreWFB0SU7hSaafHFWB/lS7WgUXbITZ6LJl2R1kC/QjmZaywsLii7ZKTTR5IuzOsiXakej6JKdOBNNviSrg3yBdjTTWl5YUHTJTqGJ5m5Uh0+zUSc5In5Wv24AXGWngyNbEfG+WnREPM/+cY5vLatFL5fLhxHxcQI/X45kfZjNZg+aMSwWiyfKZivJbduej5L8b7KBF13/AL8mkJwykdW5eNfVxegkH9PTQ+va45RsRtEHIkz0/gHu94nu9pAD/MLp07bt+abLU7dJ33rHv+vwN8lvh04A3RF045v6shng6bbHrYh4NvA56QN4U3Guver9kGymeye/QvT1wOdkF6PaUSNKqR/VjhpRyqhRbf2IUqpHteNHlLL1qPb2I0rpHdWe5ohSRKT5jz/LWsBCm72pOQAAAABJRU5ErkJggg=='
                              />
                        </defs>
                  </svg>
            </div>
      );
};

export default PresentationIcon;
