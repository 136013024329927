import { useEffect, useReducer, useState } from 'react';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/buttons/primaryButton';
import { FlashFormModel, initialFlashFormState } from '../admin/bundle/data/models/flashFormModel';
import FrontSideForm from './components/frontSideForm';
import BackSideForm from './components/backSideForm';
import bundleReducer, {
      BundleActionTypes,
      initialBundleReducerState,
} from '../admin/bundle/presentation/reducer/bundleReducer';
import { createFlash } from '../admin/bundle/data/repository/createFlash';
import { ErrorModal } from '../../components/ErrorModal';
import { SuccessModal } from '../../components/SuccessModal';
import { useAppDispatch, useAppSelector } from '../../core/store/store';
import { setUser } from '../../core/store/slice/auth.slice';

const Create = () => {
      const { t } = useTranslation();
      const [formData, setFormData] = useState<FlashFormModel>(initialFlashFormState);
      const [state, dispatch] = useReducer(bundleReducer, initialBundleReducerState);
      const { data } = useAppSelector((state) => state.user);
      const storeDispatch = useAppDispatch();

      useEffect(() => {
            if (state.bundleCUD === true) {
                  storeDispatch(
                        setUser({
                              ...data!,
                              tags: Array.from(new Set([...(data?.tags ?? []), ...(formData.tags ?? [])])),
                        })
                  );
                  setFormData(initialFlashFormState);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state.bundleCUD]);

      return (
            <div className='bg-white w-full h-full flex flex-col items-center gap-10'>
                  <Header
                        titleOne={t('Create a')}
                        titleTwo={t('Flash Card')}
                  />
                  <div className='w-full h-[75%] flex flex-row gap-8 pl-2'>
                        <FrontSideForm
                              formData={formData}
                              setFormData={setFormData}
                        ></FrontSideForm>
                        <BackSideForm
                              formData={formData}
                              setFormData={setFormData}
                        ></BackSideForm>
                  </div>
                  <div>
                        <PrimaryButton
                              text={state.loading ? t('Please wait..') : t('Create')}
                              onClick={() => {
                                    handleCreateFlash();
                              }}
                        ></PrimaryButton>
                  </div>
                  {state.error && (
                        <ErrorModal
                              message={state.error}
                              handleClose={() => {
                                    dispatch({ type: BundleActionTypes.CLEAR_ERROR });
                              }}
                        />
                  )}
                  {state.bundleCUD === true && (
                        <SuccessModal
                              message='Card created successfully'
                              handleClose={() => {
                                    dispatch({ type: BundleActionTypes.CLEAR_BUNDLE_CUD });
                              }}
                        />
                  )}
            </div>
      );

      function handleCreateFlash() {
            createFlash({ formData: formData, dispatch: dispatch });
      }
};

export default Create;
