import { AssignmentModel } from "./assignmentModel";

export interface ICourseSectionUnitDetails {
    text: string;
    media: string;
}

export interface ICourseSectionUnitModel {
    _id?: string;
    courseId?: string;
    sectionId?: string;
    name?: string;
    desc?: string;
    type: string;
    isPublished?: boolean;
    unitDetails?: ICourseSectionUnitDetails[];
    assignments?: AssignmentModel[];
    completionAssignment?: AssignmentModel[];
    isCompleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    nextUnitId?: string;
}

export interface ICourseSectionUnitResponseModel {
    unit?: CourseSectionUnitModel[];
    totalCount: number;
}

export class CourseSectionUnitResponseModel
    implements ICourseSectionUnitResponseModel {
    unit: CourseSectionUnitModel[];
    totalCount: number;

    constructor(data: Partial<ICourseSectionUnitResponseModel> = {}) {
        this.unit =
            data.unit?.map((unit) => new CourseSectionUnitModel(unit)) || [];
        this.totalCount = data.totalCount ?? 0;
    }

    static convertToCourseSectionUnitResponseModel(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj: any
    ): CourseSectionUnitResponseModel {
        return new CourseSectionUnitResponseModel({
            unit:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                obj?.unit?.map((unit: any) =>
                    CourseSectionUnitModel.convertToCourseSectionUnitModel(unit)
                ) ?? [],
            totalCount: obj.totalCount ?? 0,
        });
    }
}

export class CourseSectionUnitModel implements ICourseSectionUnitModel {
    _id?: string;
    courseId?: string;
    sectionId?: string;
    name?: string;
    desc?: string;
    type: string;
    isPublished: boolean;
    unitDetails: ICourseSectionUnitDetails[];
    assignments: AssignmentModel[];
    completionAssignment: AssignmentModel[];
    isCompleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    nextUnitId?: string;

    constructor(data: Partial<ICourseSectionUnitModel> = {}) {
        this._id = data._id;
        this.courseId = data.courseId;
        this.sectionId = data.sectionId;
        this.name = data.name;
        this.desc = data.desc;
        this.type = data.type ?? 'CONTENT';
        this.isPublished = data.isPublished ?? false;
        this.unitDetails = data.unitDetails || [{ text: '', media: '' }];
        this.assignments = data.assignments || [];
        this.completionAssignment = data.completionAssignment || [];
        this.isCompleted = data.isCompleted;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.nextUnitId = data.nextUnitId;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static convertToCourseSectionUnitModel(obj: any): CourseSectionUnitModel {
        return new CourseSectionUnitModel({
            _id: obj.response._id,
            courseId: obj.response.courseId,
            sectionId: obj.response.sectionId,
            name: obj.response.name,
            desc: obj.response.desc,
            type: obj.response.type,
            isPublished: obj.response.isPublished,
            unitDetails: obj.response.unitDetails,
            assignments:
                obj.response.assignments !== undefined
                    ? (obj.response.assignments as []).map((e) => AssignmentModel.fromJson(e))
                    : undefined,
            completionAssignment:
                obj.response.completionAssignment !== undefined
                    ? (obj.response.completionAssignment as []).map((e) => AssignmentModel.fromJson(e))
                    : undefined,
            isCompleted: obj.response.isCompleted,
            createdAt: obj.response.createdAt,
            updatedAt: obj.response.updatedAt,
            nextUnitId: obj?.nextUnitId
        });
    }
    static fromJson(obj: any): CourseSectionUnitModel {
        return new CourseSectionUnitModel({
            _id: obj._id,
            courseId: obj.courseId,
            sectionId: obj.sectionId,
            name: obj.name,
            desc: obj.desc,
            type: obj.type,
            isPublished: obj.isPublished,
            unitDetails: obj.unitDetails,
            assignments:
                obj.assignments !== undefined
                    ? (obj.assignments as []).map((e) => AssignmentModel.fromJson(e))
                    : undefined,
            completionAssignment:
                obj.completionAssignment !== undefined
                    ? (obj.completionAssignment as []).map((e) => AssignmentModel.fromJson(e))
                    : undefined,
            isCompleted: obj.isCompleted,
            createdAt: obj.createdAt,
            updatedAt: obj.updatedAt,
        });
    }
}

export const courseSectionUnitInitialState = new CourseSectionUnitModel({
    _id: '',
    courseId: '',
    sectionId: '',
    name: '',
    desc: '',
    type: '',
    unitDetails: [
        {
            text: '',
            media: '',
        },
    ],
    assignments: [],
    completionAssignment: [],
    nextUnitId: undefined
});
