import React from 'react';
import { BeatLoader } from 'react-spinners';
import EmptyStateIcon from '../../svgs/EmptyStateIcon';
import { PrimaryText } from '../../components/textTitles';

const LoadingEmptyContainer = ({
      loading,
      empty,
      error,
      children,
      className,
}: {
      loading: boolean;
      empty?: boolean;
      error?: Error | null;
      children: React.ReactNode;
      className?: string;
}) => {
      return (
            <div className={`w-full h-full ${className}`}>
                  {loading && (
                        <div className='flex justify-center items-center w-full h-full'>
                              <BeatLoader color='black' />
                        </div>
                  )}
                  {empty && (
                        <div className='flex flex-col justify-center items-center w-full h-full opacity-75'>
                              <EmptyStateIcon />
                              <PrimaryText title='No data available!' />
                        </div>
                  )}
                  {error && (
                        <div className='flex flex-col justify-center items-center w-full h-full'>
                              <h1>{error.message}</h1>
                        </div>
                  )}
                  {!loading && !empty && !error && children}
            </div>
      );
};

export default LoadingEmptyContainer;
