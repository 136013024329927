import { useState } from 'react';
import { GoogleIcon, EyeIcon, EyeCloseIcon, EmailIcon, KeyIcon } from '../../../../svgs/manager/svgManager';
import { useNavigate } from 'react-router-dom';
import CustomInputField from '../../../../components/customInputField';
import LoginFormModel, { initialLoginFormState } from '../../data/models/login.form.model';
import { PrimaryText, SecondaryText } from '../../../../components/textTitles';
import PrimaryButton from '../../../../components/buttons/primaryButton';
import ForgotPassword from '../components/forgotPassword';
import useLogin from '../../data/service/login.service';

const Login = () => {
      const [formData, setFormData] = useState<LoginFormModel>(initialLoginFormState);
      const [isObscure, setIsObscure] = useState(true);
      const navigate = useNavigate();
      const { mutate: submitLogin, isPending } = useLogin();

      const handleGoogleAuth = async () => {
            window.location.href = `${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/auth/google`;
      };

      return (
            <div className='w-screen h-screen bg-whitePrimary flex justify-center items-center'>
                  <div className='h-full sm:h-auto xl:w-[30%] lg:w-[40%] sm:w-[50%] w-max-[550px] flex flex-col justify-center items-center gap-8 md:py-12 md:px-14 md:rounded-[50px] md:shadow-primary'>
                        <section className='flex flex-col items-center'>
                              <SecondaryText title='WELCOME TO'></SecondaryText>
                              <PrimaryText
                                    title='LEARNING APP'
                                    color='text-bluePrimary'
                              />
                        </section>
                        <form
                              className='w-full flex flex-col items-center gap-10'
                              onSubmit={(e) => {
                                    e.preventDefault();
                                    handleLogin();
                              }}
                        >
                              <section className='w-full flex flex-col gap-3'>
                                    <CustomInputField
                                          required
                                          prefixIcon={<EmailIcon />}
                                          helperText={'Email Address'}
                                          onChanged={(event) => {
                                                setFormData((prev) => ({ ...prev, email: event.target.value }));
                                          }}
                                    />
                                    <CustomInputField
                                          required
                                          prefixIcon={<KeyIcon />}
                                          helperText={'Password'}
                                          type={isObscure ? 'password' : 'text'}
                                          suffixIcon={
                                                <button
                                                      type='button'
                                                      onClick={(e) => {
                                                            setIsObscure((prev) => !prev);
                                                      }}
                                                >
                                                      {isObscure ? <EyeCloseIcon /> : <EyeIcon />}
                                                </button>
                                          }
                                          onChanged={(event) => {
                                                setFormData((prev) => ({ ...prev, password: event.target.value }));
                                          }}
                                    />
                                    <ForgotPassword></ForgotPassword>
                              </section>
                              <PrimaryButton
                                    text='Login'
                                    loading={isPending}
                              />
                        </form>
                        <section className='mt-5'>
                              <section className='flex flex-col items-center gap-1 mt-[-10px]'>
                                    <p className='text-blackSecondary text-[10px]'>or login using</p>
                                    <button onClick={handleGoogleAuth}>
                                          <GoogleIcon />
                                    </button>
                              </section>
                              <p className='text-blackSecondary text-xs mt-3'>
                                    Do not have an account?{' '}
                                    <button
                                          onClick={() => navigate('/signup')}
                                          className='text-bluePrimary font-medium hover:text-[#8b89ff]'
                                    >
                                          Click here
                                    </button>
                              </p>
                        </section>
                  </div>
            </div>
      );

      function handleLogin() {
            submitLogin(formData);
      }
};

export default Login;
