import React, { useRef } from 'react';
import { CourseSectionUnitModel } from '../../data/models/courseSectionUnitModel';

const CourseUnitTypeVideo: React.FC<{ content: CourseSectionUnitModel }> = ({ content }) => {
      const videoRef = useRef<HTMLVideoElement>(null);

      const setPlaybackRate = (rate: number) => {
            if (videoRef.current) {
                  videoRef.current.playbackRate = rate;
            }
      };

      return (
            <>
                  <video
                        ref={videoRef}
                        controls
                        onCanPlay={() => setPlaybackRate(1)}
                        style={{ display: 'block', width: '90%' }}
                  >
                        <source src={content.unitDetails[0].media} />
                  </video>
                  {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <button onClick={() => setPlaybackRate(0.5)}>0.5x</button>
                        <button onClick={() => setPlaybackRate(1)}>1x</button>
                        <button onClick={() => setPlaybackRate(1.5)}>1.5x</button>
                        <button onClick={() => setPlaybackRate(2)}>2x</button>
                  </div> */}
            </>
      );
};

export default CourseUnitTypeVideo;
