import { useEffect, useState } from 'react';
import CardPreview from './components/CardPreview';
import { RevisionDashboard } from './components/RevisionDashboard';
import { RevisionGraph } from './components/RevisionGraph';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { Header } from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { SuccessModal } from '../../components/SuccessModal';
import { getToken } from '../../core/utils/functions';
import { useAppSelector } from '../../core/store/store';

const Revision = () => {
      const { t } = useTranslation();
      const [showCardPreview, setShowCardPreview] = useState<boolean>(false);
      const [cards, setCards] = useState<any[]>([]);
      const [isLoading, setIsLoading] = useState<boolean>(true);
      const [index, setIndex] = useState<number>(0);
      const [tags, setTags] = useState<string[]>([]);
      const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [successMessage, setSuccessMessage] = useState<string>('');
      const { data } = useAppSelector((state) => state.user);

      const getCards = async () => {
            try {
                  setIsLoading(true);
                  const token = getToken();
                  const res = await axios.get(
                        `${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/get-cards-for-revision`,
                        {
                              headers: {
                                    Authorization: 'Bearer ' + token,
                              },
                              params: {
                                    userId: data?.userId,
                              },
                        }
                  );
                  setCards(res.data.cards);
                  setIsLoading(false);
            } catch (error) {
                  setIsLoading(false);
                  console.error(error);
            }
      };

      const getTags = async () => {
            try {
                  const res = await axios.get(
                        `${process.env.REACT_APP_NODE_SERVER_BASE_URL}/api/v1/get-tags/${data?.userId}`
                  );
                  setTags(res.data.tags);
            } catch (error) {
                  console.error(error);
            }
      };

      const sortById = (cards: any[], id: string) => {
            const index = cards.findIndex((card) => card._id === id);
            const card = cards[index];
            cards.splice(index, 1);
            cards.unshift(card);
            return cards;
      };

      useEffect(() => {
            // check if filter is present in the URL
            const urlParams = new URLSearchParams(window.location.search);
            const filter = urlParams.get('filter');
            const cardId = urlParams.get('cardId');

            if (cardId) {
                  setShowCardPreview(true);
                  const sortedCards = sortById(cards, cardId);
                  setCards(sortedCards);
                  window.history.pushState({}, document.title, window.location.pathname);
            }

            if (filter) {
                  setShowCardPreview(true);
                  switch (filter) {
                        case 'today':
                              const today = new Date().toISOString().split('T')[0];
                              const filteredCards = cards.filter((card) => card.dueDate === today);
                              setCards(filteredCards);
                              break;
                        case 'last-week':
                              const lastWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
                              const filteredCardsLastWeek = cards.filter(
                                    (card) => new Date(card.dueDate).getTime() > new Date(lastWeek).getTime()
                              );
                              setCards(filteredCardsLastWeek);
                              break;
                        case 'last-15-days':
                              const last15Days = new Date(
                                    new Date().getTime() - 15 * 24 * 60 * 60 * 1000
                              ).toISOString();
                              const filteredCardsLast15Days = cards.filter(
                                    (card) => new Date(card.dueDate).getTime() > new Date(last15Days).getTime()
                              );
                              setCards(filteredCardsLast15Days);
                              break;
                        case 'last-30-days':
                              const last30Days = new Date(
                                    new Date().getTime() - 30 * 24 * 60 * 60 * 1000
                              ).toISOString();
                              const filteredCardsLast30Days = cards.filter(
                                    (card) => new Date(card.dueDate).getTime() > new Date(last30Days).getTime()
                              );
                              setCards(filteredCardsLast30Days);
                              break;
                        default:
                              break;
                  }
            }

            getCards();
            getTags();
            sortCards(cards);
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const sortCards = (cards: any[]) => {
            return cards.sort((a, b) => {
                  return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime();
            });
      };

      const filterTags = async (tag: string) => {
            // Filter out cards with the selected tag
            const filteredCards = cards.filter((card) => card.tags.includes(tag));
            setCards(filteredCards);
            setShowCardPreview(true);
      };

      return (
            <div className='bg-white w-full h-full flex flex-col gap-6 pl-2 mb-4'>
                  {/* <div className="flex flex-col gap-6 w-full h-full"> */}
                  <Header
                        titleOne={t('Revision')}
                        titleTwo=''
                  />
                  {isLoading ? (
                        <div className='flex items-center justify-center h-full w-full'>
                              <BeatLoader color='#7573FF' />
                        </div>
                  ) : showCardPreview ? (
                        // <div className="flex flex-col items-center justify-center h-full">
                        <div className='h-full flex justify-center items-center'>
                              {cards && cards.length > 0 && index !== cards.length ? (
                                    <CardPreview
                                          card={cards[index]}
                                          getCards={getCards}
                                          updateIndex={setIndex}
                                          setShowCardPreview={setShowCardPreview}
                                    />
                              ) : (
                                    <h1 className='xl:text-2xl text-xl font-semibold text-bluePrimary'>
                                          {t('No cards to revise')}
                                    </h1>
                              )}
                        </div>
                  ) : (
                        <div className='flex flex-col gap-10 w-full'>
                              <div style={{ flex: '1 1 auto' }}>
                                    <RevisionDashboard
                                          setShowCardPreview={setShowCardPreview}
                                          tags={tags}
                                          filterTags={filterTags}
                                    />
                              </div>
                              <div>
                                    <RevisionGraph />
                              </div>
                        </div>
                  )}
                  {/* </div> */}
                  {showSuccessModal && (
                        <SuccessModal
                              handleClose={() => setShowSuccessModal(false)}
                              message={successMessage}
                        />
                  )}
            </div>
      );
};

export default Revision;
