import { TertiaryText, Text16 } from "../../../components/textTitles";

const GetStartedCard = ({
  title,
  icon,
}: {
  title: string;
  icon: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-4 rounded-xl shadow-[rgba(0,0,0,_0.19)_0px_9px_20px] hover:shadow-md duration-300 transition-all xl:p-4 p-2.5">
      <div className="flex items-center xl:gap-3 gap-2.5">
        {icon}
        <Text16 title={title} fontWeight="font-semibold" />
      </div>
    </div>
  );
};

export default GetStartedCard;

