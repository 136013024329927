export const NoteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8549 0H9.28707C8.16296 0 7.24846 0.91456 7.24846 2.03861V2.07095H7.21613C6.09201 2.07095 5.17751 2.98551 5.17751 4.10956V4.14189H5.14518C4.02107 4.14189 3.10657 5.05645 3.10657 6.1805V28.9613C3.10657 30.0854 4.02113 30.9999 5.14518 30.9999H21.713C22.8372 30.9999 23.7517 30.0853 23.7517 28.9613V28.9289H23.784C24.9081 28.9289 25.8226 28.0144 25.8226 26.8903V26.858H25.8549C26.979 26.858 27.8935 25.9434 27.8935 24.8194V2.03861C27.8935 0.91456 26.979 0 25.8549 0ZM22.7808 28.9614C22.7808 29.5502 22.3018 30.0293 21.713 30.0293H5.14512C4.5563 30.0293 4.07725 29.5502 4.07725 28.9614V20.6451H22.7808L22.7808 28.9614ZM22.7808 19.6743H4.07725V6.18056C4.07725 5.59175 4.5563 5.1127 5.14512 5.1127H21.713C22.3018 5.1127 22.7808 5.59175 22.7808 6.18056V19.6743ZM24.8518 26.8904C24.8518 27.4792 24.3727 27.9582 23.7839 27.9582H23.7516V18.5741H24.8518V26.8904ZM24.8518 17.6033H23.7516V6.18056C23.7516 5.05645 22.837 4.14195 21.713 4.14195H6.14826V4.10962C6.14826 3.5208 6.62731 3.04175 7.21613 3.04175H23.784C24.3728 3.04175 24.8519 3.5208 24.8519 4.10962L24.8518 17.6033ZM26.9228 24.8194C26.9228 25.4083 26.4438 25.8873 25.8549 25.8873H25.8226V16.5031H26.9228V24.8194ZM26.9228 15.5323H25.8226V4.10962C25.8226 2.98551 24.908 2.07101 23.784 2.07101H8.21921V2.03861C8.21921 1.44979 8.69825 0.970748 9.28707 0.970748H25.8549C26.4438 0.970748 26.9228 1.44979 26.9228 2.03861V15.5323Z"
        fill="#7573FF"
      />
      <path
        d="M14.4144 16.9234L12.0364 8.36242C11.8543 7.70657 11.2517 7.24854 10.571 7.24854H9.55645C8.87579 7.24854 8.27322 7.70657 8.09104 8.36236L5.71288 16.9234C5.64119 17.1817 5.79237 17.4493 6.05073 17.521C6.30908 17.5927 6.57658 17.4415 6.64833 17.1832L7.26863 14.95H12.8587L13.479 17.1832C13.5387 17.3981 13.7339 17.5388 13.9465 17.5388C13.9895 17.5388 14.0332 17.5331 14.0767 17.521C14.335 17.4493 14.4862 17.1817 14.4144 16.9234ZM7.5383 13.9792L9.02642 8.62216C9.0923 8.385 9.31027 8.21928 9.55645 8.21928H10.571C10.8172 8.21928 11.0352 8.38494 11.101 8.62216L12.5891 13.9792H7.5383Z"
        fill="#7573FF"
      />
      <path
        d="M20.6774 16.568C20.6596 16.568 20.6451 16.5536 20.6451 16.5357V14.7236V14.2059V11.876C20.6451 11.608 20.4278 11.3906 20.1597 11.3906C19.8916 11.3906 19.6743 11.6079 19.6743 11.876V12.0818C19.1798 11.6518 18.5349 11.3906 17.8298 11.3906C16.2775 11.3906 15.0145 12.6535 15.0145 14.2059V14.7236C15.0145 16.2759 16.2774 17.5389 17.8298 17.5389C18.5539 17.5389 19.2148 17.2639 19.7141 16.8131C19.8349 17.2316 20.2206 17.5389 20.6774 17.5389C20.9454 17.5389 21.1628 17.3216 21.1628 17.0535C21.1628 16.7854 20.9455 16.568 20.6774 16.568ZM19.6743 14.7235C19.6743 15.7406 18.8469 16.568 17.8298 16.568C16.8128 16.568 15.9854 15.7406 15.9854 14.7235V14.2058C15.9854 13.1887 16.8128 12.3614 17.8298 12.3614C18.8468 12.3614 19.6743 13.1888 19.6743 14.2058V14.7235Z"
        fill="#7573FF"
      />
      <path
        d="M20.1597 22.7808H6.69829C6.43025 22.7808 6.21289 22.9981 6.21289 23.2662C6.21289 23.5342 6.43019 23.7516 6.69829 23.7516H20.1597C20.4277 23.7516 20.6451 23.5343 20.6451 23.2662C20.6451 22.9981 20.4278 22.7808 20.1597 22.7808Z"
        fill="#7573FF"
      />
      <path
        d="M14.9823 26.9229H6.69829C6.43025 26.9229 6.21289 27.1402 6.21289 27.4083C6.21289 27.6763 6.43019 27.8937 6.69829 27.8937H14.9822C15.2502 27.8937 15.4676 27.6764 15.4676 27.4083C15.4677 27.1402 15.2503 26.9229 14.9823 26.9229Z"
        fill="#7573FF"
      />
      <path
        d="M9.80459 24.8516H6.69829C6.43025 24.8516 6.21289 25.0689 6.21289 25.337C6.21289 25.605 6.43019 25.8224 6.69829 25.8224H9.80459C10.0726 25.8224 10.29 25.6051 10.29 25.337C10.29 25.0689 10.0727 24.8516 9.80459 24.8516Z"
        fill="#7573FF"
      />
      <path
        d="M20.1597 24.8516H11.8758C11.6077 24.8516 11.3904 25.0689 11.3904 25.337C11.3904 25.605 11.6077 25.8224 11.8758 25.8224H20.1597C20.4277 25.8224 20.6451 25.6051 20.6451 25.337C20.6451 25.0689 20.4278 24.8516 20.1597 24.8516Z"
        fill="#7573FF"
      />
    </svg>
  );
};
