import React, { SetStateAction, useReducer, useState } from 'react'
import CustomImage from '../../../../../components/customImage'
import CustomTextArea from '../../../../../components/customTextArea'
import { SecondaryText, Text12, Text14 } from '../../../../../components/textTitles'
import { FlashDetailsModel } from '../../../../admin/bundle/data/models/flashDetailsModel'
import { updateUserFlashGrade } from '../../../data/repository/updateUserFlashGrade'
import revisionReducer, { initialRevisionReducerState } from '../../reducer/revisionReducer'
import { BeatLoader } from 'react-spinners'
import { updateRevisionStatus } from '../../../data/repository/updateRevisionStatus'
import { DotsIcon } from '../../../../../svgs/manager/svgManager'
import AddToLibraryBox from '../addToLibraryBox'
import GradesSection from './gradesSection'

const RevisionFlashBack = ({ setCardFlip, cardData, cards, cardCount, setCardCount, setCard, setShowCompletionDialog }:
    {
        setCardFlip: React.Dispatch<SetStateAction<boolean>>,
        setShowCompletionDialog: React.Dispatch<SetStateAction<boolean>>,
        cardData: FlashDetailsModel,
        setCard: React.Dispatch<SetStateAction<FlashDetailsModel | undefined>>,
        cards: FlashDetailsModel[] | null,
        cardCount: number,
        setCardCount: React.Dispatch<SetStateAction<number>>
    }) => {

    const [showBox, setShowBox] = useState(false)
    const [state, dispatch] = useReducer(revisionReducer, initialRevisionReducerState)



    return (
        <div
            className="h-full xl:w-[600px] w-[520px] rounded-3xl p-6 shadow-primary flex flex-col justify-center gap-5 items-center"
            onClick={() => { setCardFlip(false) }}>
            {/* <CustomImage
                image={cardData.image ?? ""}
                alt="image"
                height="h-[150px]"
                width="w-[300px]"
                borderRadius='rounded-3xl'
            /> */}
            <img src={cardData.image ?? ""} alt={"image"} className='rounded-3xl object-cover h-[150px] w-[400px]' />
            <section className="w-full h-fit flex justify-around">
                <section className="text-center w-1/2 py-2">
                    <SecondaryText title={cardData.front.text} color="text-bluePrimary"></SecondaryText>
                    <Text14 title={cardData.front.example} color="text-blackPrimary" fontWeight='font-semibold'></Text14>
                </section>
                <div className="w-[2px] h-full bg-black"></div>
                <section className="text-center w-1/2 py-2">
                    <SecondaryText title={cardData.back.text} color="text-bluePrimary"></SecondaryText>
                    <Text14 title={cardData.back.example} color="text-blackPrimary" fontWeight='font-semibold'></Text14>
                </section>
            </section>
            <div className="w-full line-clamp-5 overflow-visible">
                <CustomTextArea value={cardData.note} placeholder="" disabled rows={5} onChange={() => { }} ></CustomTextArea>
            </div>
            {cardData.audio && <audio src={cardData.audio} controls={true}></audio>}
            <GradesSection
                onGradeChanged={(grade) => handleGradeClick(grade)}
            />
            <div className="absolute bottom-4 right-4">
                <Text12 title="Tap to flip the card" fontWeight="font-semibold"></Text12>
            </div>
            {/* <button className="absolute top-4 right-3">
                <DotsIcon />
            </button> */}
            {
                state.loading && <div className='w-full h-full absolute bg-slate-50 bg-opacity-50  top-0 left-0 flex items-center justify-center'>
                    <BeatLoader />
                </div>
            }
            {/* {
                showBox &&
                <AddToLibraryBox cardData={cardData} handleClose={setShowBox} />
            } */}
        </div>
    )

    function handleGradeClick(grade: number) {
        updateUserFlashGrade({ flashId: cardData._id, grade: grade, dispatch: dispatch }).then((val) => {
            if (val === true) {
                updateRevisionStatus({ flashId: cardData._id, dispatch: dispatch }).then((val) => {
                    if (val === true) {
                        if (cards && cardCount < cards.length - 1) {
                            setCardCount(prev => prev + 1);
                            setCard(cards[cardCount + 1]);
                            setCardFlip(false)
                        } else if (cards && cardCount === cards.length - 1) {
                            setShowCompletionDialog(true);
                        } else {
                        }
                    }
                })
            }
        })
    }

}

export default RevisionFlashBack