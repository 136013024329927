export const LanguageIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9538 20.6734L18.9659 9.69313C18.6858 8.97075 18.0589 8.52418 17.3387 8.52418C16.6185 8.52418 16.005 8.97075 15.6982 9.7194L11.7236 20.6734C11.5369 21.1857 11.8037 21.7504 12.3238 21.9343C12.844 22.1182 13.4175 21.8555 13.6042 21.3433L14.4311 19.071H20.233L21.0599 21.3433C21.2066 21.7504 21.5934 22 22.0068 22C22.1135 22 22.2336 21.9869 22.3403 21.9475C22.8604 21.7636 23.1272 21.1988 22.9405 20.6866L22.9538 20.6734ZM15.1647 17.0878L17.3521 11.0591L19.5394 17.0878H15.1647ZM11.7637 5.54269C8.74937 10.2316 6.02854 13.0687 2.72085 14.96C2.5608 15.0519 2.38741 15.0913 2.22736 15.0913C1.88058 15.0913 1.54715 14.9206 1.36042 14.6054C1.08034 14.1325 1.24039 13.5284 1.72053 13.2657C4.52139 11.6633 6.81545 9.36478 9.38958 5.55582H1.00031C0.453474 5.55582 0 5.10925 0 4.57075C0 4.03224 0.453474 3.58567 1.00031 3.58567H6.00186V0.985075C6.00186 0.446567 6.45534 0 7.00217 0C7.549 0 8.00249 0.446567 8.00249 0.985075V3.58567H13.004C13.5509 3.58567 14.0043 4.03224 14.0043 4.57075C14.0043 5.10925 13.5509 5.55582 13.004 5.55582H11.7503L11.7637 5.54269ZM11.817 15.0913C11.6436 15.0913 11.4702 15.0519 11.3102 14.96C10.4432 14.4609 9.60298 13.9093 8.81606 13.3313C8.37592 13.003 8.28257 12.3857 8.61601 11.9522C8.94945 11.5188 9.57631 11.4269 10.0164 11.7552C10.7367 12.2937 11.5236 12.7928 12.3238 13.2525C12.804 13.5284 12.964 14.1325 12.6839 14.6054C12.4972 14.9206 12.1638 15.0913 11.817 15.0913Z"
        fill="#7573FF"
      />
    </svg>
  );
};
