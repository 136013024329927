import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../../core/api/apisPath";
import { DAxios } from "../../../../core/api/axiosConfig";
import { UserRoleEnum } from "../../../../core/constants/enums";
import { DRoutes } from "../../../../core/routes";
import { setUser } from "../../../../core/store/slice/auth.slice";
import { useAppDispatch } from "../../../../core/store/store";
import { Prefs } from "../../../../core/utils/prefs";
import { DToast } from "../../../../core/utils/toasts";
import { convertToUserModel } from "../models/admin.user.model";
import SignUpFormModel from "../models/signup.form.model";

export const useSignup = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return useMutation({
        mutationFn: async (formData?: SignUpFormModel) => {
            if (!formData) {
                throw Error('Please fill out the fields')
            }
            if (!formData?.email?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                throw Error('Invalid email')
            }
            const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            if (!passwordRegex.test(formData?.password!)) {
                throw Error('Invalid password')
            }
            if (formData.password !== formData.cPassword) {
                throw Error('Passwords donot match')
            }
            return await DAxios.post({
                endpoint: endpoints.signup,
                body: {
                    "email": formData.email,
                    "password": formData.password,
                    "name": formData.name,
                    "role": UserRoleEnum.STUDENT
                }
            });
        },

        onSuccess: (data) => {
            const response = convertToUserModel(data);
            Prefs.setToken({ token: data.user.token.toString() });
            dispatch(setUser(response));
            navigate(DRoutes.register);
        },

        onError(error: Error) {
            new DToast().showErrorToast(error.message);
        },
    })
}