export const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5292 0H3.47085C1.55405 0 0 1.55405 0 3.47085V14.8959C0 16.8126 1.55405 18.3667 3.47085 18.3667H21.5292C23.4459 18.3667 25 16.8126 25 14.8959V3.47085C25 1.55405 23.4459 0 21.5292 0ZM8.69951 12.3923L3.83916 16.5388C3.49634 16.8314 2.98096 16.7907 2.68843 16.4476C2.39585 16.1047 2.43652 15.5894 2.77974 15.2968L7.64009 11.1503C7.98286 10.8578 8.49819 10.8984 8.79077 11.2417C9.08335 11.5844 9.04268 12.0998 8.69951 12.3923ZM12.5 10.816C11.8499 10.8144 11.2197 10.5983 10.7271 10.1723L10.7274 10.1727L10.7259 10.1715C10.7263 10.1719 10.7267 10.1719 10.7271 10.1723L3.26963 3.69966C2.92881 3.40386 2.89253 2.88853 3.18789 2.54814C3.48364 2.20732 3.99902 2.17104 4.33936 2.46641L11.7989 8.94063C11.9583 9.08252 12.221 9.18496 12.5 9.18335C12.7786 9.18418 13.0369 9.08452 13.2051 8.93784L13.2087 8.93467L20.6606 2.46646C21.001 2.17109 21.5164 2.20737 21.8121 2.54819C22.1074 2.88853 22.0712 3.40391 21.7304 3.69971L14.2709 10.1743C13.7798 10.5959 13.1505 10.8151 12.5 10.816ZM22.3119 16.4476C22.0193 16.7907 21.504 16.8314 21.1612 16.5388L16.3009 12.3923C15.9577 12.0998 15.917 11.5844 16.2096 11.2417C16.5021 10.8984 17.0175 10.8578 17.3603 11.1503L22.2207 15.2968C22.5638 15.5894 22.6045 16.1047 22.3119 16.4476Z"
        fill="#7573FF"
      />
    </svg>
  );
};
