import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import { convertToUserModel } from '../../features/auth/data/models/admin.user.model';
import { endpoints } from '../api/apisPath';
import { setUser } from '../store/slice/auth.slice';
import { DAxios } from '../api/axiosConfig';

const useAuthGuard = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.user);

    useEffect(() => {
        const checkAuthentication = async () => {
            setIsPending(true);
            if (data) {
                setIsAuthenticated(true);
                setIsPending(false);
            } else {
                try {
                    const data = await DAxios.get({ endpoint: endpoints.getUser });
                    if (data) {
                        setIsAuthenticated(true);
                        dispatch(setUser(convertToUserModel(data)));
                    } else {
                        setIsAuthenticated(false);
                    }
                } catch (error) {
                    setIsAuthenticated(false);
                }
                setIsPending(false);
            }
        };

        checkAuthentication();
    }, [data, dispatch]);

    return { isAuthenticated, isPending };
};

export default useAuthGuard;
