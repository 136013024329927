
interface RegisterButtonProps {
      label: string;
      onClick: () => void;
}

const RegisterButton = ({ label, onClick }: RegisterButtonProps) => {
      return (
            <button
                  className='xl:mt-8 mt-6 xl:w-96 w-80 xl:py-3 py-1 rounded-[3rem] xl:text-lg lg:text-base text-sm font-bold uppercase text-bluePrimary hover:text-white bg-white hover:bg-bluePrimary hover:border-gray-200 border border-transparent transition-colors duration-300 shadow-[1px_4px_22.3px_0px_rgba(0,_0,_0,_0.25)]'
                  onClick={onClick}
            >
                  {label}
            </button>
      );
};

export default RegisterButton;
