import React, { useState, useEffect } from 'react';
import { Text12 } from '../../../../../components/textTitles';
import { MatchFollowingQuestionBody } from '../../../data/models/assignmentModel';
import TertiaryButton from '../../../../../components/buttons/tertiaryButton';
import QuestionBody from './QuestionBody';

const MatchFollowing = ({
      question,
      savedAnswer,
      onSaveAnswer,
}: {
      question: MatchFollowingQuestionBody;
      savedAnswer: { [key: string]: { match: string; correct: boolean } };
      onSaveAnswer: (answer: { [key: string]: { match: string; correct: boolean } }) => void;
}) => {
      const [matches, setMatches] = useState<{ [key: string]: { match: string; correct: boolean } }>({});
      const [draggedItem, setDraggedItem] = useState<string | null>(null);
      const [showHint, setShowHint] = useState<boolean>(false);

      useEffect(() => {
            if (savedAnswer) {
                  setMatches(savedAnswer);
                  checkIfHintShouldBeShown(savedAnswer);
            }
      }, [savedAnswer]);

      const checkIfHintShouldBeShown = (answers: { [key: string]: { match: string; correct: boolean } }) => {
            const incorrectAnswerExists = Object.values(answers).some((answer) => !answer.correct);
            setShowHint(incorrectAnswerExists);
      };

      const handleDragStart = (event: React.DragEvent<HTMLDivElement>, key: string) => {
            setDraggedItem(key);
      };

      const handleDrop = (event: React.DragEvent<HTMLDivElement>, key: string) => {
            if (!draggedItem) return;

            const correct = question.matches.some((pair) => pair[0] === draggedItem && pair[1] === key);
            const newMatches = { ...matches, [draggedItem]: { match: key, correct } };
            setMatches(newMatches);
            onSaveAnswer(newMatches);
            checkIfHintShouldBeShown(newMatches);
            setDraggedItem(null);
      };

      const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
      };

      const resetMatches = () => {
            setMatches({});
            onSaveAnswer({});
            setShowHint(false);
      };

      return (
            <div className='flex flex-col w-full gap-4'>
                  <QuestionBody question={question.question_body} />
                  <div className=' flex justify-between items-center'>
                        <Text12 title='Note: Drag the question to the answers' />
                        <TertiaryButton
                              text='Reset Answers'
                              onClick={resetMatches}
                        />
                  </div>
                  <div className='flex gap-20 w-full mt-6 justify-center'>
                        <div className='flex flex-col gap-4'>
                              {question.left_option.map((option, index) => (
                                    <div
                                          key={index}
                                          className='border-2 p-2 rounded-md bg-bluePrimary bg-opacity-5 cursor-pointer min-w-52 max-w-56'
                                          draggable
                                          onDragStart={(event) => handleDragStart(event, option.key)}
                                    >
                                          <Text12
                                                title={option.body}
                                                fontWeight='font-medium'
                                          />
                                    </div>
                              ))}
                        </div>
                        <div className='flex flex-col gap-4'>
                              {question.right_option.map((option, index) => {
                                    const matchedKey = Object.keys(matches).find(
                                          (key) => matches[key].match === option.key
                                    );
                                    const match = matchedKey ? matches[matchedKey] : null;
                                    const isCorrect = match?.correct;
                                    const bgColor = match
                                          ? isCorrect
                                                ? 'bg-green-200'
                                                : 'bg-red-200'
                                          : 'bg-bluePrimary bg-opacity-5';

                                    return (
                                          <div
                                                key={index}
                                                className={`border-2 p-2 rounded-md min-w-52 max-w-56 ${bgColor}`}
                                                onDrop={(event) => handleDrop(event, option.key)}
                                                onDragOver={handleDragOver}
                                          >
                                                <Text12
                                                      title={option.body}
                                                      fontWeight='font-medium'
                                                />
                                          </div>
                                    );
                              })}
                        </div>
                  </div>
                  {showHint && question.hints && question.hints.length > 0 && (
                        <div className='mt-4 p-2 bg-yellow-100 border border-yellow-300 rounded-md'>
                              <Text12
                                    title={`Hint: ${question.hints[0]}`}
                                    fontWeight='font-medium'
                              />
                        </div>
                  )}
            </div>
      );
};

export default MatchFollowing;
