import CustomImage from '../../../../components/customImage';
import { Text12, Text16 } from '../../../../components/textTitles';
import { CourseModel } from '../../data/models/courseModel';

const CourseCard = ({ onClick, course }: { onClick: () => void; course: CourseModel }) => {
      return (
            <button
                  className='xl:h-[300px] xl:w-[255px] h-[270px] w-[225px] shadow-primary flex flex-col gap-2 rounded-2xl overflow-hidden'
                  onClick={onClick}
            >
                  <CustomImage
                        image={course.image ?? ''}
                        alt={course.image ?? ''}
                        className='h-1/2 w-full'
                  />
                  <section className='p-2 flex flex-col gap-1 text-left'>
                        <Text16
                              title={course.name ?? ''}
                              fontWeight='font-semibold'
                        />
                        <section className='xl:line-clamp-5 line-clamp-4'>
                              <Text12 title={course.desc ?? ''} />
                        </section>
                  </section>
            </button>
      );
};

export default CourseCard;
