import { Text16, Text14 } from '../../../../components/textTitles';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../../../components/Header';
import { BundleModel } from '../../../admin/bundle/data/models/bundleModel';
import { userSubBundleLibHeaderItems } from '../../../../core/constants/tableHeadersList';
import { useEffect, useReducer, useState } from 'react';
import { fetchSubBundles } from '../../../admin/bundle/data/repository/fetchSubBundles';
import bundleReducer, { initialBundleReducerState } from '../../../admin/bundle/presentation/reducer/bundleReducer';
import { enrollSubBundle } from '../../data/repository/enrollSubBundle';
import { SubBundleModel } from '../../../admin/bundle/data/models/subBundleModel';
import NoteCard from '../../../admin/bundle/presentation/components/subBundle/subBundleFlashCards/noteCard';

const SubBundle = () => {
      const { t } = useTranslation();
      const location = useLocation();
      const navigate = useNavigate();
      const bundleData: BundleModel = location.state;
      const [subBundleData, setSubBundleData] = useState<SubBundleModel>();
      const [state, dispatch] = useReducer(bundleReducer, initialBundleReducerState);
      const [showDescription, setShowDescription] = useState(false);

      useEffect(() => {
            if (bundleData.id) {
                  fetchSubBundles(bundleData.id, dispatch);
            } else {
                  navigate('/bundle');
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
            if (state.bundleCUD === true) {
                  navigate('revise', { state: subBundleData });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state.bundleCUD]);

      return (
            <div className='w-full flex flex-col gap-7'>
                  <Header
                        titleOne={t('Sub-Bundle of')}
                        titleTwo={bundleData.name}
                  ></Header>
                  <section className='w-full h-full overflow-y-scroll pl-6 mx-2'>
                        <table className='w-full text-left'>
                              <thead>
                                    {userSubBundleLibHeaderItems.map((element, index) => (
                                          <th key={index}>
                                                <div className='flex items-center justify-left gap-1 h-16'>
                                                      {/* {element.icon} */}
                                                      <Text16
                                                            title={t(element.name)}
                                                            fontWeight='font-bold'
                                                      />
                                                </div>
                                          </th>
                                    ))}
                              </thead>
                              <tbody>
                                    {state.subBundleData?.map((element) => (
                                          <tr
                                                key={element.id}
                                                className='h-12'
                                          >
                                                <td>
                                                      <Text14
                                                            title={element.name}
                                                            fontWeight='font-semibold'
                                                      />
                                                </td>
                                                <td>
                                                      <div
                                                            className='hover:cursor-pointer'
                                                            onClick={() => setShowDescription(true)}
                                                      >
                                                            <Text14
                                                                  title='View description'
                                                                  fontWeight='font-bold'
                                                                  color='text-bluePrimary'
                                                            />
                                                      </div>
                                                </td>
                                                <td>
                                                      <div className='w-fit rounded-3xl py-2 px-4 bg-red-500'>
                                                            <Text14
                                                                  title={handleStatusKeyword(element.status)}
                                                                  color='text-whitePrimary'
                                                                  fontWeight='font-semibold'
                                                            ></Text14>
                                                      </div>
                                                </td>
                                                <td>
                                                      <div
                                                            onClick={() => {
                                                                  handleRevise(element);
                                                            }}
                                                            className='w-fit rounded-3xl py-2 px-4 bg-black bg-opacity-75 hover:cursor-pointer'
                                                      >
                                                            <Text14
                                                                  title={handleActionKeyword(element.status)}
                                                                  color='text-whitePrimary'
                                                                  fontWeight='font-semibold'
                                                            ></Text14>
                                                      </div>
                                                </td>
                                                {showDescription && (
                                                      <NoteCard
                                                            title='Description'
                                                            noteData={element.desc}
                                                            setOpenNote={setShowDescription}
                                                      />
                                                )}
                                          </tr>
                                    ))}
                              </tbody>
                        </table>
                  </section>
            </div>
      );

      function handleRevise(subBundleData: SubBundleModel) {
            setSubBundleData(subBundleData);
            enrollSubBundle(dispatch, subBundleData.id);
      }

      function handleStatusKeyword(status?: string): string {
            if (status) {
                  if (status === 'NOT_APPLICABLE') return 'Pending';
                  else if (status === 'IN_PROGRESS') return 'In progress';
                  else if (status === 'COMPLETED') return 'Completed';
            }
            return '';
      }

      function handleActionKeyword(status?: string) {
            if (status) {
                  if (status === 'NOT_APPLICABLE') return 'Practice';
                  else if (status === 'IN_PROGRESS') return 'Continue';
                  else if (status === 'COMPLETED') return 'Revise again';
            }
            return '';
      }
};

export default SubBundle;
