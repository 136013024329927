interface CustomTextAreaProps {
    placeholder: string,
    value?: string | number,
    rows?: number,
    disabled?: boolean
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>
}

const CustomTextArea = ({ value, placeholder, rows, disabled, onChange }: CustomTextAreaProps) => {
    return (
        <div className='w-full shadow-[0px_3px_27.6px_0px_rgba(0,_0,_0,_0.25)]  rounded-2xl px-6 py-3'>
            <textarea className='w-full outline-none resize-none text-sm font-medium' disabled={disabled ?? false} rows={rows ?? 7} value={value} name="comment" form="usrform" placeholder={placeholder} onChange={onChange}>
            </textarea>
        </div>
    )
}

export default CustomTextArea