import { BeatLoader } from 'react-spinners';
import { Text14 } from '../textTitles';

interface TertiarButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
      text: string;
      loading?: boolean;
      disabled?: boolean;
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TertiaryButton = ({ text, loading, onClick, disabled }: TertiarButtonProps) => {
      return (
            <button
                  className={`bg-bluePrimary rounded-xl px-6 py-2 ${disabled ? 'cursor-not-allowed bg-gray-400' : 'hover:bg-blueSecondary'}`}
                  onClick={loading !== true && !disabled ? onClick : undefined}
            >
                  {loading === true ? (
                        <BeatLoader
                              color='white'
                              size={8}
                        />
                  ) : (
                        <Text14
                              title={text}
                              color='text-whitePrimary'
                              fontWeight='font-bold'
                        />
                  )}
            </button>
      );
};

export default TertiaryButton;
