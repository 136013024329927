/* eslint-disable @typescript-eslint/no-explicit-any */
// Base Question Interface
export interface IBaseQuestion {
    question_body?: string;
    hints?: string[];
}

export interface Sides {
    text: string;
    example: string;
}

export class FlashCardModel implements IBaseQuestion {
    question_body?: string;
    image: string;
    audio: string;
    front: Sides;
    back: Sides;
    note: string;

    constructor({
        image,
        audio,
        front,
        back,
        note,
    }: {
        image: string;
        audio: string;
        front: Sides;
        back: Sides;
        note: string;
    }) {
        this.image = image;
        this.audio = audio;
        this.front = front;
        this.back = back;
        this.note = note;
    }

    static fromJson(json: any): FlashCardModel {
        return new FlashCardModel({
            image: json.image,
            audio: json.audio,
            front: json.front,
            back: json.back,
            note: json.note,
        });
    }

    static toJson(flashCard: FlashCardModel): any {
        return {
            image: flashCard.image,
            audio: flashCard.audio,
            front: flashCard.front,
            back: flashCard.back,
            note: flashCard.note,
        };
    }
}

export class Comprehension implements IBaseQuestion {
    question_body?: string | undefined;
    mediaType?: 'AUDIO' | 'VIDEO';
    questionMedia?: string;
    subparts: AssignmentModel[]; // Updated to use AssignmentModel

    constructor({
        question_body,
        mediaType,
        questionMedia,
        subparts,
    }: {
        question_body?: string;
        mediaType?: 'AUDIO' | 'VIDEO';
        questionMedia?: string;
        subparts: AssignmentModel[]; // Updated type
    }) {
        this.question_body = question_body;
        this.mediaType = mediaType;
        this.questionMedia = questionMedia;
        this.subparts = subparts;
    }

    static fromJson(json: any): Comprehension {
        const subparts = json.subparts.map((subpart: any) => {
            return AssignmentModel.fromJson(subpart);
        });

        return new Comprehension({
            question_body: json.question_body,
            mediaType: json.mediaType,
            questionMedia: json.questionMedia,
            subparts,
        });
    }

    static toJson(comprehension: Comprehension): any {
        const subparts = comprehension.subparts.map((subpart) =>
            AssignmentModel.toJson(subpart)
        );
        return {
            question_body: comprehension.question_body,
            mediaType: comprehension.mediaType,
            questionMedia: comprehension.questionMedia,
            subparts,
        };
    }
}

export class WritingTypeQuestionBody implements IBaseQuestion {
    question_body?: string;
    mediaType?: string;
    questionMedia?: string;

    constructor({
        question_body,
        mediaType,
        questionMedia,
    }: {
        question_body?: string;
        mediaType?: string;
        questionMedia?: string;
    }) {
        this.question_body = question_body;
        this.mediaType = mediaType;
        this.questionMedia = questionMedia;
    }

    static toJson(writingTypeQuestionBody: WritingTypeQuestionBody): any {
        const json: any = {
            question_body: writingTypeQuestionBody.question_body,
            mediaType: writingTypeQuestionBody.mediaType,
            questionMedia: writingTypeQuestionBody.questionMedia,
        };
        return json;
    }
}

export class ListeningQuestionBody implements IBaseQuestion {
    question_body?: string;
    mediaType?: string;
    questionMedia?: string;

    constructor({
        question_body,
        mediaType,
        questionMedia,
    }: {
        question_body?: string;
        mediaType?: string;
        questionMedia?: string;
    }) {
        this.question_body = question_body;
        this.mediaType = mediaType;
        this.questionMedia = questionMedia;
    }

    static toJson(writingTypeQuestionBody: WritingTypeQuestionBody): any {
        const json: any = {
            question_body: writingTypeQuestionBody.question_body,
            mediaType: writingTypeQuestionBody.mediaType,
            questionMedia: writingTypeQuestionBody.questionMedia,
        };
        return json;
    }
}

// MCQ Type Question Body Model
export class MCQTypeQuestionBody implements IBaseQuestion {
    question_body?: string;
    hints?: string[];
    options?: string[];
    answer?: {
        option_index: string[];
    };

    constructor({
        question_body,
        hints,
        options,
        answer,
    }: {
        question_body?: string;
        hints?: string[];
        options?: string[];
        answer?: {
            option_index: string[];
        };
    }) {
        this.question_body = question_body;
        this.hints = hints;
        this.options = options;
        this.answer = answer;
    }

    static toJson(mcqTypeQuestionBody: MCQTypeQuestionBody): any {
        const json: any = {
            question_body: mcqTypeQuestionBody.question_body,
            hints: mcqTypeQuestionBody.hints,
            options: mcqTypeQuestionBody.options,
            answer: mcqTypeQuestionBody.answer,
        };

        return json;
    }
}

// Fill in the Blanks Type Question Body Model
export class FillBlanksTypeQuestionBody implements IBaseQuestion {
    question_body?: string;
    hints?: string[];
    answer?: string[];

    constructor({
        question_body,
        hints,
        answer,
    }: {
        question_body?: string;
        hints?: string[];
        answer?: string[];
    }) {
        this.question_body = question_body;
        this.hints = hints;
        this.answer = answer;
    }

    static toJson(fillBlanksTypeQuestionBody: FillBlanksTypeQuestionBody): any {
        const json: any = {
            question_body: fillBlanksTypeQuestionBody.question_body,
            hints: fillBlanksTypeQuestionBody.hints,
            answer: fillBlanksTypeQuestionBody.answer,
        };

        return json;
    }
}

// Match the Following Type Question Body Model
export class MatchFollowingQuestionBody implements IBaseQuestion {
    question_body?: string;
    hints?: string[];
    right_option: {
        body: string;
        key: string;
    }[];
    left_option: {
        body: string;
        key: string;
    }[];
    matches: [string, string][];

    constructor({
        question_body,
        hints,
        right_option,
        left_option,
        matches,
    }: {
        question_body: string;
        hints?: string[];
        right_option: {
            body: string;
            key: string;
        }[];
        left_option: {
            body: string;
            key: string;
        }[];
        matches: [string, string][];
    }) {
        this.question_body = question_body;
        this.hints = hints;
        this.right_option = right_option;
        this.left_option = left_option;
        this.matches = matches;
    }

    static toJson(matchFollowingQuestionBody: MatchFollowingQuestionBody): any {
        const json: any = {
            question_body: matchFollowingQuestionBody.question_body,
            hints: matchFollowingQuestionBody.hints,
            right_option: matchFollowingQuestionBody.right_option,
            left_option: matchFollowingQuestionBody.left_option,
            matches: matchFollowingQuestionBody.matches,
        };

        return json;
    }
}

// Assignment Model
export class AssignmentModel {
    assignmentType?: string;
    mcqTypeQuestionBody?: MCQTypeQuestionBody;
    fillBlanksTypeQuestionBody?: FillBlanksTypeQuestionBody;
    matchFollowingQuestionBody?: MatchFollowingQuestionBody;
    writingTypeQuestionBody?: WritingTypeQuestionBody;
    listeningTypeQuestionBody?: ListeningQuestionBody;
    comprehension?: Comprehension;
    flashCard?: FlashCardModel;

    constructor({
        assignmentType,
        mcqTypeQuestionBody,
        fillBlanksTypeQuestionBody,
        matchFollowingQuestionBody,
        writingTypeQuestionBody,
        listeningTypeQuestionBody,
        comprehension,
        flashCard,
    }: {
        assignmentType?: string;
        mcqTypeQuestionBody?: MCQTypeQuestionBody;
        fillBlanksTypeQuestionBody?: FillBlanksTypeQuestionBody;
        matchFollowingQuestionBody?: MatchFollowingQuestionBody;
        writingTypeQuestionBody?: WritingTypeQuestionBody;
        listeningTypeQuestionBody?: ListeningQuestionBody;
        comprehension?: Comprehension;
        flashCard?: FlashCardModel;
    }) {
        this.assignmentType = assignmentType;
        this.mcqTypeQuestionBody = mcqTypeQuestionBody;
        this.fillBlanksTypeQuestionBody = fillBlanksTypeQuestionBody;
        this.matchFollowingQuestionBody = matchFollowingQuestionBody;
        this.writingTypeQuestionBody = writingTypeQuestionBody;
        this.listeningTypeQuestionBody = listeningTypeQuestionBody;
        this.comprehension = comprehension;
        this.flashCard = flashCard;
    }

    static fromJson(json: any): AssignmentModel {
        let mcqTypeQuestionBody: MCQTypeQuestionBody | undefined;
        let fillBlanksTypeQuestionBody: FillBlanksTypeQuestionBody | undefined;
        let matchFollowingQuestionBody: MatchFollowingQuestionBody | undefined;
        let writingTypeQuestionBody: WritingTypeQuestionBody | undefined;
        let listeningTypeQuestionBody: ListeningQuestionBody | undefined;
        let comprehension: Comprehension | undefined;
        let flashCard: FlashCardModel | undefined;

        switch (json.assignmentType) {
            case 'MCQ':
                mcqTypeQuestionBody = new MCQTypeQuestionBody({
                    question_body: json.question.question_body,
                    hints: json.question.hints,
                    options: json.question.options,
                    answer: json.question.answer,
                });
                break;
            case 'FILL_IN_BLANKS':
                fillBlanksTypeQuestionBody = new FillBlanksTypeQuestionBody({
                    question_body: json.question.question_body,
                    hints: json.question.hints,
                    answer: json.question.answer,
                });
                break;
            case 'MATCH_THE_FOLLOWING':
                matchFollowingQuestionBody = new MatchFollowingQuestionBody({
                    question_body: json.question.question_body,
                    hints: json.question.hints,
                    right_option: json.question.right_option,
                    left_option: json.question.left_option,
                    matches: json.question.matches,
                });
                break;
            case 'WRITING_QUESTION':
                writingTypeQuestionBody = new WritingTypeQuestionBody({
                    question_body: json.question.question_body,
                    mediaType: json.question.mediaType,
                    questionMedia: json.question.questionMedia,
                });
                break;
            case 'LISTENING_QUESTION':
                listeningTypeQuestionBody = new ListeningQuestionBody({
                    question_body: json.question.question_body,
                    mediaType: json.question.mediaType,
                    questionMedia: json.question.questionMedia,
                });
                break;
            case 'COMPREHENSION':
                comprehension = Comprehension.fromJson(json.question);
                break;
            case 'FLASH_CARD':
                flashCard = FlashCardModel.fromJson(json.question);
                break;
        }

        return new AssignmentModel({
            assignmentType: json.assignmentType,
            mcqTypeQuestionBody,
            fillBlanksTypeQuestionBody,
            matchFollowingQuestionBody,
            writingTypeQuestionBody,
            listeningTypeQuestionBody,
            comprehension,
            flashCard,
        });
    }

    static toJson(assignmentModel: AssignmentModel): any {
        let question;
        switch (assignmentModel.assignmentType) {
            case 'MCQ':
                question = MCQTypeQuestionBody.toJson(
                    assignmentModel.mcqTypeQuestionBody as MCQTypeQuestionBody
                );
                break;
            case 'FILL_IN_BLANKS':
                question = FillBlanksTypeQuestionBody.toJson(
                    assignmentModel.fillBlanksTypeQuestionBody as FillBlanksTypeQuestionBody
                );
                break;
            case 'MATCH_THE_FOLLOWING':
                question = MatchFollowingQuestionBody.toJson(
                    assignmentModel.matchFollowingQuestionBody as MatchFollowingQuestionBody
                );
                break;
            case 'WRITING_QUESTION':
                question = WritingTypeQuestionBody.toJson(
                    assignmentModel.writingTypeQuestionBody as WritingTypeQuestionBody
                );
                break;
            case 'LISTENING_QUESTION':
                question = ListeningQuestionBody.toJson(
                    assignmentModel.listeningTypeQuestionBody as ListeningQuestionBody
                );
                break;
            case 'COMPREHENSION':
                question = Comprehension.toJson(
                    assignmentModel.comprehension as Comprehension
                );
                break;
            case 'FLASH_CARD':
                question = FlashCardModel.toJson(
                    assignmentModel.flashCard as FlashCardModel
                );
                break;
        }

        return {
            assignmentType: assignmentModel.assignmentType,
            question,
        };
    }
}
