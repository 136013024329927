export interface SubBundleModel {
    id: string;
    userId: string;
    bundleId: string;
    name: string;
    status?: string;
    desc: string;
    createdAt: Date;
    isPublished: boolean;
}


export function convertToSubBundleModel(response: any): SubBundleModel {
    return {
        id: response._id,
        userId: response.userId,
        bundleId: response.bundleId,
        name: response.name,
        status: response.status,
        desc: response.desc,
        createdAt: response.createdAt,
        isPublished: response.isPublished
    };
}