interface UserModel {
      userId: string;
      name?: string;
      email?: string;
      profilePicture?: string;
      tags?: string[];
      currentNorwegianSkill?: string;
      role?: string;
      phoneNumber?: string;
      about?: string;
}

export function convertToUserModel(response: any): UserModel {
      const user = {
            userId: response?.user?._id ?? '',
            name: response?.user?.name,
            email: response?.user?.email,
            profilePicture: response?.user?.profilePicture,
            tags: response?.user?.tags,
            role: response?.user?.role,
            phoneNumber: response?.user?.phoneNumber,
            about: response?.user?.about,
            currentNorwegianSkill: response?.user?.currentNorwegianSkill,
      };
      return user;
}

export default UserModel;
