import React, { useState, useEffect } from 'react';
import { Text12 } from '../../../../../components/textTitles';
import { FillBlanksTypeQuestionBody } from '../../../data/models/assignmentModel';
import InfoIcon from '../../../../../svgs/InfoIcon';
import { InfoModal } from '../../../../../components/InfoModal';
import QuestionBody from './QuestionBody';

const FillBlanks = ({
      question,
      savedAnswer,
      onSaveAnswer,
}: {
      question: FillBlanksTypeQuestionBody;
      savedAnswer: string[];
      onSaveAnswer: (answer: string[]) => void;
}) => {
      const [userAnswers, setUserAnswers] = useState<string[]>(Array(question.answer?.length).fill(''));
      const [showInfoModal, setShowInfoModal] = useState(false);
      const [hintIndexToShow, setHintIndexToShow] = useState<number | null>(null);

      useEffect(() => {
            if (savedAnswer) {
                  setUserAnswers(savedAnswer);
            }
      }, [savedAnswer]);

      const handleInputChange = (val: React.ChangeEvent<HTMLInputElement>, index: number) => {
            const newUserAnswers = [...userAnswers];
            newUserAnswers[index] = val.target.value.toLowerCase();
            setUserAnswers(newUserAnswers);
            onSaveAnswer(newUserAnswers);
      };

      const handleShowHint = (index: number) => {
            if (question.hints && question.hints[index]) {
                  setHintIndexToShow(index);
                  setShowInfoModal(true);
            }
      };

      const handleCloseModal = () => {
            setShowInfoModal(false);
            setHintIndexToShow(null);
      };

      return (
            <div className='flex flex-col w-full gap-2'>
                  <QuestionBody question={question.question_body} />
                  <Text12 title='Note : Fill up the input fields sequentially according to the gaps. If your answer matches the answer the input field will turn green' />
                  <div className='flex flex-col gap-3 w-full mt-6'>
                        {question.answer?.map((answer, index) => {
                              const isCorrect = userAnswers[index] === answer;
                              const borderColor = isCorrect ? 'border-green-500' : 'border-red-500';
                              return (
                                    <div key={index}>
                                          <Text12 title={`Blank ${index + 1}`} />
                                          <div className='mt-1 flex gap-2'>
                                                <input
                                                      type='text'
                                                      className={`
                                                            xl:text-xs text-[11px]
                                                            p-1
                                                            border-2 ${
                                                                  userAnswers[index] ? borderColor : 'border-gray-300'
                                                            } focus:outline-none`}
                                                      value={userAnswers[index] || ''}
                                                      onChange={(val) => handleInputChange(val, index)}
                                                />
                                                {question.hints && question.hints[index] && (
                                                      <button onClick={() => handleShowHint(index)}>
                                                            <InfoIcon />
                                                      </button>
                                                )}
                                          </div>
                                    </div>
                              );
                        })}
                  </div>
                  {showInfoModal && hintIndexToShow !== null && (
                        <div className='z-50 bg-black bg-opacity-25 fixed top-0 left-0 w-full h-full flex justify-center items-center'>
                              <InfoModal
                                    message={question.hints![hintIndexToShow]}
                                    handleClose={handleCloseModal}
                              />
                        </div>
                  )}
            </div>
      );
};

export default FillBlanks;
