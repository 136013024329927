export const LastWeekIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5001 7.9165V4.74984C28.5001 4.32991 28.3333 3.92718 28.0363 3.63025C27.7394 3.33332 27.3367 3.1665 26.9167 3.1665C26.4968 3.1665 26.0941 3.33332 25.7972 3.63025C25.5002 3.92718 25.3334 4.32991 25.3334 4.74984V7.9165H12.6667V4.74984C12.6667 4.32991 12.4999 3.92718 12.203 3.63025C11.9061 3.33332 11.5033 3.1665 11.0834 3.1665C10.6635 3.1665 10.2608 3.33332 9.96383 3.63025C9.6669 3.92718 9.50008 4.32991 9.50008 4.74984V7.9165H3.16675V33.2498H34.8334V7.9165H28.5001ZM31.6667 30.0832H6.33341V11.0832H31.6667V30.0832ZM14.2501 15.8332H11.0834V18.9998H14.2501V15.8332ZM20.5834 15.8332H17.4167V18.9998H20.5834V15.8332ZM26.9167 15.8332H23.7501V18.9998H26.9167V15.8332ZM14.2501 22.1665H11.0834V25.3332H14.2501V22.1665ZM20.5834 22.1665H17.4167V25.3332H20.5834V22.1665ZM26.9167 22.1665H23.7501V25.3332H26.9167V22.1665Z"
        fill="#7573FF"
      />
    </svg>
  );
};
