import { useLocation, useNavigate } from 'react-router-dom';
import LoadingEmptyContainer from '../../../../core/components/LoadingEmptyContainer';
import CustomImage from '../../../../components/customImage';
import { PrimaryText, TertiaryText, Text16, Text14, Text12 } from '../../../../components/textTitles';
import { DRoutes } from '../../../../core/routes';
import { ContentIcon, DollarIcon } from '../../../../svgs/manager/svgManager';
import { useGetCourse } from '../../data/services/getCourse.service';
import TertiaryButton from '../../../../components/buttons/tertiaryButton';
import { useCheckCourseEnrollment } from '../../data/services/checkCourseEnrolled.service';

const CourseDetail = () => {
      const location = useLocation();
      const navigate = useNavigate();
      const isEnrolled = location.pathname.split('/')[1] === DRoutes.enrolledCourse.split('/')[1];
      const courseId = location.pathname.split('/')[2];
      const { data, isPending } = useGetCourse(courseId);
      const { data: enrolledCourseMeta } = useCheckCourseEnrollment({ courseId });

      return (
            <LoadingEmptyContainer
                  loading={isPending}
                  empty={data?.courses.length === 0}
                  className='flex flex-col gap-8 overflow-scroll no-scrollbar pr-16'
            >
                  <section className='w-full flex justify-between'>
                        <PrimaryText title={data?.courses[0].name ?? ''} />
                        {!isEnrolled && (
                              <TertiaryButton
                                    text={enrolledCourseMeta ? 'Enrolled' : 'Enroll'}
                                    disabled={enrolledCourseMeta !== undefined}
                                    onClick={() => navigate(DRoutes.payment + `?courseId=${courseId}`)}
                              />
                        )}
                  </section>
                  <section className='w-full flex'>
                        <section className='w-[75%]'>
                              <section className='w-full flex flex-col gap-3'>
                                    <CustomImage
                                          image={data?.courses[0].image ?? ''}
                                          alt='alphabets'
                                          className='xl:h-[350px] lg:h-[300px] h-[260px] w-11/12 object-cover rounded-2xl'
                                    />
                                    <div className='flex items-center gap-4'>
                                          <DollarIcon />
                                          <TertiaryText
                                                title={`USD ${data?.courses[0].price}`}
                                                fontWeight='font-bold'
                                                className='mt-2'
                                          />
                                    </div>
                                    <section className='mt-2 flex flex-col gap-3'>
                                          <Text16
                                                title='About Course'
                                                fontWeight='font-semibold'
                                          />
                                          <Text14 title={data?.courses[0].desc ?? ''} />
                                    </section>
                              </section>
                        </section>
                        <section className='w-[35%] h-full flex flex-col items-end'>
                              <div
                                    onClick={() => {
                                          navigate(DRoutes.courseContent + `?courseId=${courseId}`);
                                    }}
                                    className='flex gap-3 items-center hover:cursor-pointer'
                              >
                                    <div className='text-right'>
                                          <Text14
                                                title='Content'
                                                fontWeight='font-semibold'
                                          />
                                          <div className='h-1'></div>
                                          <Text12 title='Course files' />
                                    </div>
                                    <ContentIcon />
                              </div>
                        </section>
                  </section>
            </LoadingEmptyContainer>
      );
};

export default CourseDetail;
